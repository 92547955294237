import * as React from 'react'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Checkbox from '@mui/material/Checkbox'
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'
import Chip from '@mui/material/Chip'
import { DataGrid, GridToolbar, gridClasses } from '@mui/x-data-grid'
import dayjs from 'dayjs'
import { alpha, styled } from '@mui/material/styles'

import {
  DraftsOutlined,
  DraftsTwoTone,
  Mail,
  MailOutlineTwoTone,
  Payment,
  Send,
  SwitchAccessShortcutAdd,
} from '@mui/icons-material'
import { useLocation, useNavigate } from 'react-router-dom'

import { Badge, IconButton, Modal, Tooltip } from '@mui/material'

import {
  getOverduePayment,
  getPaymentPending,
  getTicketlist,
  getUserTicketlist,
  unReadandReadApi,
} from '../../../../services/configService'
import { SnackbarContext } from '../../../../context/Snackbar'

const ODD_OPACITY = 0.2

const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
  [`& .${gridClasses.row}.even`]: {
    backgroundColor: '#E3EFF7',
  },
  [`& .${gridClasses.row}.void`]: {
    backgroundColor: '#f123',
  },
  [`& .${gridClasses.row}.cancel`]: {
    backgroundColor: '#f123',
  },
  [`& .${gridClasses.row}.green`]: {
    animation: 'ripple 3s ease-in-out',
  },
  '@keyframes ripple': {
    '0%': {
      backgroundColor: 'lightgreen',
    },
    '100%': {
      backgroundColor: 'white',
    },
  },
  [`& .${gridClasses.row}`]: {
    '&:hover, &.Mui-hovered': {
      backgroundColor: alpha('#FFFF00', ODD_OPACITY),
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
  },
  [`& .${gridClasses.columnHeader}`]: {
    color: 'black',
    width: '100%',
    backgroundColor: '#D3D3D3',
  },
}))

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: '#61699f',
  // border: '2px solid #000',
  boxShadow: 24,
  p: 1,
  // color: 'skyblue',
}

export default function PaymentPendingList({
  archived,
  setArchived,
  filterData,
  clearFilter,
  setStateData,
}) {
  const navigate = useNavigate()
  const { state } = useLocation()
  const [data, setData] = React.useState([])

  const { setOn, setSeverity, setMes } = React.useContext(SnackbarContext)

  const [loader, setLoader] = React.useState(false)
  const [pagination, setPagination] = React.useState(0)

  const [rowCounts, setRowCounts] = React.useState(0)

  // const handleOpen = () => setOpen(true);

  async function getData() {
    try {
      const res = await getPaymentPending(pagination, filterData)
      if (res.status !== 204) {
        setRowCounts(res.countOfDuePayment?.[0]?.count)
        setData(res.data)
        setLoader(false)
      }
    } catch (error) {
      console.log(error)
      setLoader(false)
    }
  }
  const columns = [
    // {
    //   field: 'actions',
    //   type: 'actions',
    //   headerName: '',
    //   width: 75,

    //   cellClassName: 'actions',
    //   renderCell: (params) => {
    //     return (
    //       <Checkbox
    //         // checked={isChecked[params.row.p_id]}
    //         onChange={(event) => handleCheckboxChange(event, params.row)}
    //       />
    //     )
    //   },
    // },
    {
      field: 'query_id',
      headerName: 'Query No.',
      width: 100,
      renderCell: (params) => {
        return params.row?.query_id ? (
          <p style={{ fontWeight: 600 }}>{params.row.query_id}</p>
        ) : (
          <p>N.A</p>
        )
      },
    },
    {
      field: 'query_code',
      headerName: 'Query Code',
      flex: 1,
      minWidth: 180,
      renderCell: (params) => (
        <p
          style={{
            color: '#184E81',
            fontWeight: 500,
            textDecoration: 'underline',
          }}
        >
          {`#${params.row.query_code}`}
        </p>
      ),
    },
    {
      field: 'agent_name',
      headerName: 'Agent Name',
      flex: 1,
      minWidth: 200,
      renderCell: (params) => {
        return params.row?.agent_name ? (
          <p style={{ fontWeight: 600 }}>{params.row.agent_name}</p>
        ) : (
          <p>{params.row.agent_name}</p>
        )
      },
    },
    {
      field: 'receive_amount',
      headerName: 'Amount',
      flex: 1,
      minWidth: 100,
      renderCell: (params) => {
        return (
          <p style={{ color: '#184E81', fontWeight: 500 }}>
            ₹{params.row.receive_amount}
          </p>
        )
      },
    },
    {
      field: 'payment_type',
      headerName: 'Payment Stage',
      flex: 1,
      minWidth: 170,
      renderCell: (params) => {
        return (
          <strong style={{ color: '#184E81' }}>
            {params.row.payment_type}
          </strong>
        )
      },
    },
    {
      field: 'due_date',
      headerName: 'Due Date',
      flex: 1,
      minWidth: 100,
      renderCell: (params) => {
        return (
          <p style={{ fontWeight: 500 }}>
            {params.row.due_date
              ? dayjs(params.row.due_date).format('DD-MM-YYYY')
              : 'N.A'}
          </p>
        )
      },
    },
    {
      field: 'origin',
      headerName: 'Origin',
      flex: 1,
      minWidth: 100,
      renderCell: (params) => {
        return <p style={{ fontWeight: 500 }}>{params.row.origin}</p>
      },
    },
    {
      field: 'destination',
      headerName: 'Destination',
      flex: 1,
      minWidth: 100,
      renderCell: (params) => {
        return <p style={{ fontWeight: 500 }}>{params.row.destination}</p>
      },
    },
    {
      field: 'sector',
      headerName: 'Sector',
      flex: 1,
      minWidth: 100,
      renderCell: (params) => {
        return <p style={{ fontWeight: 500 }}>{params.row.origin}</p>
      },
    },
  ]

  React.useEffect(() => {
    if (clearFilter) {
      setLoader(true)
      getData()
    }
  }, [clearFilter])

  React.useEffect(() => {
    setLoader(true)
    getData() // Call the async function
  }, [pagination])
  React.useEffect(() => {
    setLoader(true)
    getData() // Call the async function
  }, [filterData])

  React.useEffect(() => {
    if (state?.length > 0) {
      setStateData(state)
    }
  }, [state])

  console.log('state', pagination)

  return (
    <Box sx={{ height: '80vh', width: '100%' }}>
      <Modal
        open={loader}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <div className='loader-container'>
          <div class='dot-spinner'>
            <div class='dot-spinner__dot'></div>
            <div class='dot-spinner__dot'></div>
            <div class='dot-spinner__dot'></div>
            <div class='dot-spinner__dot'></div>
            <div class='dot-spinner__dot'></div>
            <div class='dot-spinner__dot'></div>
            <div class='dot-spinner__dot'></div>
            <div class='dot-spinner__dot'></div>
          </div>
          <p
            style={{
              marginLeft: '10px',
              fontWeight: '500',
              marginTop: '10px',
            }}
          >
            Please wait!!
          </p>
        </div>
      </Modal>

      <StripedDataGrid
        rows={data?.slice(0, 10)?.map((item, index) => ({
          ...item,
          s_no: index + 1,
          payment_type:
            item.amount_type == 1
              ? 'Token Amount'
              : item.amount_type == 2
              ? 'Advance Amount'
              : 'Final Amount',
          sector: item.origin,
        }))}
        getRowId={(row) => row?.s_no}
        columns={columns}
        initialState={{
          pagination: { paginationModel: { pageSize: 30, page: 0 } },
        }}
        rowCount={rowCounts} // Define the total number of rows
        paginationMode='server' // Enables controlled pagination
        onPageChange={(page) => {
          console.log('ttpage', page)

          setPagination(page)
          // setFliterDataPagination(page)
        }}
        onPaginationModelChange={(params) => {
          console.log('pppage', params)
          setPagination(params.page)
          // setFliterDataPagination(params.page)
        }}
        pageSizeOptions={[10, 20, 50]} // Set the available page size options
        disableColumnFilter
        disableColumnSelector
        disableDensitySelector
        slots={{ toolbar: GridToolbar }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
          },
        }}
        getRowClassName={(params) =>
          params.row.is_mapped == 1
            ? 'void'
            : params.indexRelativeToCurrentPage % 2 === 0
            ? 'even'
            : 'odd'
        }
        disableRowSelectionOnClick
      />
      {/* <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={style}>
          <Makepaymentsuccess paymentdetails={paymentdetails} />
        </Box>
      </Modal> */}
    </Box>
  )
}
