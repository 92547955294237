import {
  Cloud,
  ContentCopy,
  ContentCut,
  ContentPaste,
  Delete,
  DeleteOutlineTwoTone,
  DeleteSweepTwoTone,
  Edit,
  EditTwoTone,
  FlightLand,
  FlightTakeoff,
  Save,
} from '@mui/icons-material'
import {
  Button,
  Card,
  CardContent,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Paper,
  Select,
  TextField,
  Typography,
  Checkbox,
} from '@mui/material'
import IconButton from '@mui/material/IconButton'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import React, { useContext, useEffect, useState } from 'react'
import { handleChangeGlobal, setSessionStorage } from '../../utils/common'
import dayjs from 'dayjs'
import { SnackbarContext } from '../../context/Snackbar'
import { set } from 'lodash'
import { to } from 'react-spring'
import "./style.css";


const date = new Date()
date.toLocaleDateString()

const Passenger_info = ({
  setPassengers,
  passengers,
  adultPassenger,
  childPassenger,
  infantPassenger,
  setBillingClear,

}) => {
  const { setOn, setSeverity, setMes } = useContext(SnackbarContext)
  const [editMode, setEditMode] = useState(false)
  const [billingAddressSet, setBillingAddressSet] = useState(false)
  const [saveid, setSaveid] = useState('')
  const [passenger_id, setPassenger_id] = useState(1)
  const [details, setDetails] = useState({
    passenger_type: '',
    gender: '',
    first_name: '',
    middle_name: '',
    last_name: '',
    dob: '',
    email_id: '',
    mobile_no: '',
    passport_no: '',
    pan_no: '',
    passport_expiry: null,
    is_billing_address: false,
  })

  const [passengerCounts, setPassengerCounts] = useState({
    adult: 0,
    child: 0,
    infant: 0,
  })

  const totalpassenger = adultPassenger + childPassenger + infantPassenger
  // console.log('totalpassenger', passengerCounts)

  const handleDateChange = (date) => {
    setDetails((prevDetails) => ({
      ...prevDetails,
      passport_expiry: date.format('YYYY/MM/DD'),
    }))
  }
  const handleBirthDateChange = (date) => {
    setDetails((prevDetails) => ({
      ...prevDetails,
      dob: date.format('YYYY/MM/DD'),
    }))
  }

  const handlePassengertype = (e) => {
    const value = e.target.value
    setDetails((prevDetails) => ({
      ...prevDetails,
      passenger_type: value,
    }))

    setPassengerCounts((prevCounts) => {
      const newCounts = { ...prevCounts }

      // Decrease the count of the previous type
      if (details.passenger_type === 1) {
        newCounts.adult -= 1
      } else if (details.passenger_type === 2) {
        newCounts.child -= 1
      } else if (details.passenger_type === 3) {
        newCounts.infant -= 1
      }

      // Increase the count of the new type
      if (value === 1) {
        newCounts.adult += 1
      } else if (value === 2) {
        newCounts.child += 1
      } else if (value === 3) {
        newCounts.infant += 1
      }

      return newCounts
    })
  }

  const handleSave = () => {
    const requiredFields = [
      'first_name',
      'last_name',
      'dob',
      'gender',
      'mobile_no',
      'passenger_type',
      'email_id',
      'pan_no',
      'passport_no',
      'passport_expiry',
    ]

    if (requiredFields?.some((field) => !details[field])) {
      setOn(true)
      setSeverity('error')
      setMes(
        'Please ensure all mandatory fields are completed before clicking on the Save button.'
      )
      return
    }

    const newPassenger = {
      id: passenger_id,
      ...details,
    }
    setPassenger_id(passenger_id + 1);
    if(newPassenger.is_billing_address){
      setBillingAddressSet(true)
    }
    // y([...passengers, newPassenger])
    setPassengers((prev) => [...prev, newPassenger])
    
    // Reset details after saving
    setDetails({
      passenger_type: '',
      gender: '',
      first_name: '',
      middle_name: '',
      last_name: '',
      dob: '',
      email_id: '',
      mobile_no: '',
      passport_no: '',
      pan_no: '',
      passport_expiry: null,
      is_billing_address: false,
    })
  }

  const today = new Date()

  const pastInfantDays = new Date(today.getTime() - 730 * 24 * 60 * 60 * 1000)
  const pastChildDays = new Date(today.getTime() - 4380 * 24 * 60 * 60 * 1000)
  const maxDate = new Date(today)
  maxDate.setDate(today.getDate() - 4380)

  // const datesInRange = [];
  // for (let date = new Date(pastInfantDays); date <= today; date.setDate(date.getDate() + 1)) {
  //     datesInRange.push(new Date(date));
  // }

  // datesInRange.forEach(date => console.log(date.toLocaleDateString()));
  const handleEdit = (id) => {
    // Find the passenger by ID and populate the form with their details for editing
    setSaveid(id)
    const passengerToEdit = passengers.find((passenger) => passenger.id === id)
    if (passengerToEdit) {
      if(passengerToEdit.is_billing_address){
        setBillingAddressSet(true)
      }
      setDetails({ ...passengerToEdit })
      
      setEditMode(true)
    }
  }
 
  const updatedPassengers = passengers.map(
    (passenger) => passenger.passenger_type
  )
  // console.log("usdsdasdsdsadsds",updatedPassengers[0]);
  const handleDelete = (id) => {
    //check billling address then mark setBillingAddressSet(false)
    const billingAddress = passengers.filter(
      (passenger) => passenger.id == id
    )[0]?.is_billing_address
    if (billingAddress) {
      setBillingAddressSet(false)
      setBillingClear(true);
    }
    const updatedPassengers = passengers.filter(
      (passenger) => passenger.id !== id
    )
    //setPassenger_id(passenger_id - 1);
    setPassengers(updatedPassengers)
    setEditMode(false) 
    const Passengers = passengers.filter(
      (passenger) => passenger.id == id
    )[0]?.passenger_type
    
    if (Passengers === 1) {
      passengerCounts.adult -= 1
    } else if (Passengers === 2) {
      passengerCounts.child -= 1
    } else if (Passengers === 3) {
      passengerCounts.infant -= 1
    }
    setOn(true)
    setSeverity('success')
    setMes('Deleted Successfully')
  }
  const handlePassengerSave = () => {
    // Find the index of the passenger being edited
    const index = passengers.findIndex(
      (passenger) => passenger.id === details.id
    )

    if (index !== -1) {
      // Create a copy of the passengers array
      const updatedPassengers = [...passengers]
      if(passengers[index].is_billing_address && !details.is_billing_address){
        setBillingAddressSet(false)
        setBillingClear(true);
      }
      if(details.is_billing_address){
        setBillingAddressSet(true)
      }
      // Update the passenger with the modified details
      updatedPassengers[index] = { ...details }
      // Update the passengers state with the modified array
      setPassengers(updatedPassengers)
    }
    setDetails({
      passenger_type: '',
      gender: '',
      first_name: '',
      middle_name: '',
      last_name: '',
      dob: '',
      email_id: '',
      mobile_no: '',
      passport_no: '',
      pan_no: '',
      passport_expiry: null,
      is_billing_address: false,
    })

    setEditMode(false) // Exit edit mode
    setOn(true)
    setSeverity('success')
    setMes('Updated Successfully')
  }

  // console.log('helooooooo', saveid)

  useEffect(()=>{
    setSessionStorage(passengerCounts, 'passengerCounts')
  })

  return (
    <Card
      sx={{
        padding: '16px',
        boxShadow: ' rgba(0, 0, 0, 0.35) 0px 5px 15px',
      }}
    >
      <Grid container spacing={2}>
        <Grid container item md={6} spacing={2}>
          <Grid item md={4} xs={4}>
            <FormControl size='small' fullWidth>
              <InputLabel id='demo-simple-select-label'>
                Passenger Type
              </InputLabel>
              <Select
                labelId='demo-simple-select-label'
                id='demo-simple-select'
                name='passenger_type'
                value={details.passenger_type}
                label='Passenger Type'
                onChange={handlePassengertype}
                disabled={!editMode && totalpassenger === passengers?.length}
                sx={{
                  "&.Mui-disabled": {
                    pointerEvents: "none",
                    backgroundColor: "#ececec !important",
                    cursor: "not-allowed !important",
                  },
                  "& .MuiInputLabel-root.Mui-disabled": {
                    color: "rgba(0, 0, 0, 0.7) !important",
                  },
                }}
              >
                <MenuItem
                  value={1}
                  disabled={adultPassenger === passengerCounts?.adult}
                >
                  Adult
                </MenuItem>
                <MenuItem value={2}  disabled={childPassenger === passengerCounts?.child}>Child</MenuItem>
                <MenuItem value={3}  disabled={infantPassenger === passengerCounts?.infant}>Infant</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item md={3} xs={4}>
            <FormControl size='small' fullWidth>
              <InputLabel id='demo-simple-select-label'>Gender</InputLabel>
              <Select
                labelId='demo-simple-select-label'
                id='demo-simple-select'
                name='gender'
                value={details.gender}
                label='Gender'
                onChange={(e) =>
                  handleChangeGlobal(
                    e,
                    setDetails,

                    'gender'
                  )
                }
                disabled ={!editMode && totalpassenger === passengers?.length}
                sx={{
                  "&.Mui-disabled": {
                    pointerEvents: "none",
                    backgroundColor: "#ececec !important",
                    cursor: "not-allowed !important",
                  },
                  "& .MuiInputLabel-root.Mui-disabled": {
                    color: "rgba(0, 0, 0, 0.7) !important",
                  },
                }}
              >
                <MenuItem value={1}>Male</MenuItem>
                <MenuItem value={2}>Female</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          { details.passenger_type === 1 &&
            <Grid item md={1} xs={4}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={details.is_billing_address}
                  onChange={(e) =>{
                    setDetails((prevDetails) => ({
                      ...prevDetails,
                      is_billing_address: e.target.checked,
                    }))
                  }
                }
                  name="is_billing_address"
                  disabled = {billingAddressSet && !details.is_billing_address}
                  color="primary"
                />
              }
              label={<>
                Same as billing <br /> details
              </>}
              sx={{
                '& .MuiFormControlLabel-label': {
                  fontSize: '12px',
                  whiteSpace: 'nowrap',
                },
              }}
            />
          </Grid>}
          <Grid item md={4} xs={4}>
            <Button
              variant='contained'
              startIcon={<Save />}
              onClick={handleSave}
              sx={{ background: '#01357E', float: 'right' }}
              disabled={totalpassenger === passengers?.length || editMode}
            >
              Save
            </Button>
          </Grid>
          <Grid item md={4} xs={6}>
            <TextField
              id='outlined-basic'
              label='First name'
              variant='outlined'
              size='small'
              name='first_name'
              value={details.first_name}
              onChange={(e) => handleChangeGlobal(e, setDetails, 'first_name')}
              fullWidth
              required
              disabled={!editMode && totalpassenger === passengers?.length}
              sx={{
                "& .MuiInputBase-root.Mui-disabled": {
                  pointerEvents: "none",
                  backgroundColor: "#ececec !important",
                  cursor: "not-allowed !important",
                },
                "& .MuiInputLabel-root.Mui-disabled": {
                  color: "rgba(0, 0, 0, 0.7) !important",
                },
              }}
            />
          </Grid>
          <Grid item md={4} xs={6}>
            <TextField
              id='outlined-basic'
              label='Middle'
              variant='outlined'
              size='small'
              name='middle_name'
              value={details.middle_name}
              onChange={(e) => handleChangeGlobal(e, setDetails, 'middle_name')}
              fullWidth
              disabled={!editMode && totalpassenger === passengers?.length}
              sx={{
                "& .MuiInputBase-root.Mui-disabled": {
                  pointerEvents: "none",
                  backgroundColor: "#ececec !important",
                  cursor: "not-allowed !important",
                },
                "& .MuiInputLabel-root.Mui-disabled": {
                  color: "rgba(0, 0, 0, 0.7) !important",
                },
              }}
            />
          </Grid>
          <Grid item md={4} xs={6}>
            <TextField
              id='outlined-basic'
              label='Last name'
              variant='outlined'
              size='small'
              name='last_name'
              value={details.last_name}
              onChange={(e) => handleChangeGlobal(e, setDetails, 'last_name')}
              fullWidth
              required
              disabled={!editMode && totalpassenger === passengers?.length}
              sx={{
                "& .MuiInputBase-root.Mui-disabled": {
                  pointerEvents: "none",
                  backgroundColor: "#ececec !important",
                  cursor: "not-allowed !important",
                },
                "& .MuiInputLabel-root.Mui-disabled": {
                  color: "rgba(0, 0, 0, 0.7) !important",
                },
              }}
            />
          </Grid>
          <Grid item md={4} xs={6}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                slotProps={{
                  textField: { size: 'small', fullWidth: 'true' },
                }}
                format='DD-MM-YYYY'
                label='Date of birth'
                value={details.dob ? dayjs(details.dob) : null}
                disabled={!editMode && totalpassenger === passengers?.length}
                sx={{
                  "& .MuiInputBase-root.Mui-disabled": {
                    pointerEvents: "none",
                    backgroundColor: "#ececec !important",
                    cursor: "not-allowed !important",
                  },
                  "& .MuiInputLabel-root.Mui-disabled": {
                    color: "rgba(0, 0, 0, 0.7) !important",
                  },
                }}
                onChange={handleBirthDateChange}
                minDate={
                  details.passenger_type === 3
                    ? dayjs(pastInfantDays)
                    : details.passenger_type === 2
                    ? dayjs(pastChildDays)
                    : ''
                }
                maxDate={
                  details.passenger_type === 3
                    ? dayjs(today)
                    : details.passenger_type === 2
                    ? dayjs(today)
                    : dayjs(maxDate)
                }
                // Add this prop if you want to disable dates not in the range
                // shouldDisableDate={(date) => !datesInRange.some(d => d.getTime() === date.getTime())}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item md={4} xs={12}>
            <TextField
              id='outlined-basic'
              label='Email address'
              variant='outlined'
              size='small'
              name='email_id'
              value={details.email_id}
              onChange={(e) => handleChangeGlobal(e, setDetails, 'email_id')}
              fullWidth
              required
              disabled={!editMode && totalpassenger === passengers?.length}
              sx={{
                "& .MuiInputBase-root.Mui-disabled": {
                  pointerEvents: "none",
                  backgroundColor: "#ececec !important",
                  cursor: "not-allowed !important",
                },
                "& .MuiInputLabel-root.Mui-disabled": {
                  color: "rgba(0, 0, 0, 0.7) !important",
                },
              }}
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <TextField
              id='outlined-basic'
              type='number'
              label='Phone number'
              variant='outlined'
              size='small'
              name='mobile_no'
              value={details.mobile_no}
              onChange={(e) => {
                e.target.value.length < 11 &&
                  handleChangeGlobal(e, setDetails, 'mobile_no')
              }}
              fullWidth
              required
              disabled={!editMode && totalpassenger === passengers?.length}
              sx={{
                "& .MuiInputBase-root.Mui-disabled": {
                  pointerEvents: "none",
                  backgroundColor: "#ececec !important",
                  cursor: "not-allowed !important",
                },
                "& .MuiInputLabel-root.Mui-disabled": {
                  color: "rgba(0, 0, 0, 0.7) !important",
                },
              }}
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <TextField
              id='outlined-basic'
              label='Passport No.'
              variant='outlined'
              size='small'
              name='passport_no'
              value={details.passport_no}
              onChange={(e) =>
                handleChangeGlobal(e, setDetails, 'passport_no')
              }
              fullWidth
              required
              disabled={!editMode && totalpassenger === passengers?.length}
              sx={{
                "& .MuiInputBase-root.Mui-disabled": {
                  pointerEvents: "none",
                  backgroundColor: "#ececec !important",
                  cursor: "not-allowed !important",
                },
                "& .MuiInputLabel-root.Mui-disabled": {
                  color: "rgba(0, 0, 0, 0.7) !important",
                },
              }}
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <TextField
              id='outlined-basic'
              label='Pan No.'
              variant='outlined'
              size='small'
              name='pan_no'
              value={details.pan_no}
              onChange={(e) => handleChangeGlobal(e, setDetails, 'pan_no')}
              fullWidth
              required
              disabled={!editMode && totalpassenger === passengers?.length}
              sx={{
                "& .MuiInputBase-root.Mui-disabled": {
                  pointerEvents: "none",
                  backgroundColor: "#ececec !important",
                  cursor: "not-allowed !important",
                },
                "& .MuiInputLabel-root.Mui-disabled": {
                  color: "rgba(0, 0, 0, 0.7) !important",
                },
              }}
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                slotProps={{
                  textField: { size: 'small', fullWidth: 'true' },
                }}
                format='DD-MM-YYYY'
                label='Passport expiry'
                value={
                  details.passport_expiry
                    ? dayjs(details.passport_expiry)
                    : null
                }
                onChange={handleDateChange}
                disablePast
                disabled={!editMode && totalpassenger === passengers?.length}
                sx={{
                  "& .MuiInputBase-root.Mui-disabled": {
                    pointerEvents: "none",
                    backgroundColor: "#ececec !important",
                    cursor: "not-allowed !important",
                  },
                  "& .MuiInputLabel-root.Mui-disabled": {
                    color: "rgba(0, 0, 0, 0.7) !important",
                  },
                }}
              />
            </LocalizationProvider>
          </Grid>
        </Grid>
        <Grid container item md={6}>
          <CardContent
            sx={{
              background: '#edf1f4',
              width: '100%',
              maxHeight: '300px',
              overflowY: 'auto',
              boxShadow: 'rgba(0, 0, 0, 0.35) 0px 2px 5px',
            }}
          >
            {passengers.map((passenger,index) => (
              <Paper
                // elevation={0}
                key={passenger.id}
                sx={{
                  width: '100%',
                  marginBottom: 2,
                  display: 'flex',
                  boxShadow: 'rgba(0, 0, 0, 0.35) 0px 2px 5px',
                }}
              >
                <Grid container spacing={0}>
                  <Grid item md={3} xs={12}>
                    <Typography
                      sx={{
                        p: 2,
                        fontSize: '15px',
                        fontWeight: 500,
                        color: '#01357E',
                      }}
                    >
                      Passenger {index + 1}
                    </Typography>
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <Typography sx={{ p: 2, fontWeight: 500 }}>
                      {passenger.first_name} {passenger.middle_name}{' '}
                      {passenger.last_name}
                    </Typography>
                  </Grid>
                  <Grid item md={2} xs={4}>
                    <Typography sx={{ p: 2, fontWeight: 500 }}>
                      {passenger.passenger_type === 1
                        ? 'Adult'
                        : passenger.passenger_type === 2
                        ? 'Child'
                        : 'Infant'}
                    </Typography>
                  </Grid>
                  <Grid item md={2} xs={4}>
                    <Typography sx={{ p: 2, fontWeight: 500 }}>
                      {passenger.gender === 1 ? 'Male' : 'Female'}
                    </Typography>
                  </Grid>
                  <Grid item md={2} xs={4}>
                    <div style={{ display: 'flex' }}>
                      {editMode && (passenger.id === saveid) ? (
                        <IconButton
                          aria-label='edit'
                          sx={{ paddingTop: 2 }}
                          color='info'
                          size='large'
                          onClick={handlePassengerSave}
                        >
                          <Save />
                        </IconButton>
                      ) : (
                        <IconButton
                          aria-label='edit'
                          sx={{ paddingTop: 2 }}
                          color='info'
                          size='large'
                          onClick={() => handleEdit(passenger.id)}
                        >
                          <EditTwoTone />
                        </IconButton>
                      )}
                      <IconButton
                        aria-label='delete'
                        sx={{ paddingTop: 2 }}
                        color='error'
                        size='large'
                        onClick={() => handleDelete(passenger.id)}
                      >
                        <DeleteSweepTwoTone />
                      </IconButton>
                    </div>
                  </Grid>
                </Grid>

                {/* Display other details as needed */}
              </Paper>
            ))}
          </CardContent>
        </Grid>
      </Grid>
    </Card>
  )
}

export default Passenger_info
