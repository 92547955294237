import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Box, Button, Card, CardContent, Modal, Paper, Tooltip, Typography } from '@mui/material'
import { DataGrid, gridClasses } from '@mui/x-data-grid'
import { alpha, styled } from '@mui/material/styles'
import { Global, ThemeProvider as Theme2 } from '@emotion/react'
import dayjs from 'dayjs'

import { globalStyle, theme as theme2 } from '../../../../components/utils/theme.config'
import { Cancel, Description } from '@mui/icons-material'
import { a } from 'react-spring'
import { set } from 'lodash'
import {
  getEmailTemplateEditLogs
} from '../../../../services/configService'
import { render } from '@testing-library/react'

const ODD_OPACITY = 0.2

const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
  border: 'none',
  '& .MuiDataGrid-cell': {
    borderBottom: '1px solid #ddd', // Light row separators
  },
  [`& .${gridClasses.row}.even`]: {
    backgroundColor: '#E3EFF7',
  },
  [`& .${gridClasses.row}.odd`]: {
    backgroundColor: '#F9F9F9',
  },
  [`& .${gridClasses.row}`]: {
    '&:hover, &.Mui-hovered': {
      backgroundColor: alpha('#FFFF00', ODD_OPACITY),
    },
  },
  [`& .${gridClasses.columnHeader}`]: {
    color: 'black',
    fontWeight: 'bold',
    backgroundColor: '#D3D3D3',
  },
}))

export default function TemplateLogs() {
  const location = useLocation()
  const navigate = useNavigate()
  const template = location.state?.template
  const [data, setData] = useState([])
  const [loader, setLoader] = useState(false)
  const [pastContent, setPastContent] = useState('')
  const [viewPastContent, setViewPastContent] = useState(false);

  useEffect(() => {
    async function getData() {
      try {
        setLoader(true)
        if (!template) {
          return
        }
        const res = await getEmailTemplateEditLogs(template?.id)
        
        if (res !== 406 && res !== 204) {
          console.log('res.data logs', res.data.data)
          if(res?.data?.data && res?.data?.data.length > 0) {
            setData(res?.data?.data)
          }
        } else {
          setData([])
        }
      } catch (error) {
        console.error(error)
      } finally {
        setLoader(false)
      }
    }
    getData()
  }, [])
  const handleOpenPastContentModal = (content) => {
    setPastContent(content)
    setViewPastContent(true)
  }

  const handleClosePastContentModal = () => {
    setViewPastContent(false)
    setPastContent('')
  }
  const columns = [
    {
        field: 's_no',
        headerName: 'S.No',
        width: 60,
    },
    {
      field: 'name',
      headerName: 'Edited By',
      width: 180,

      renderCell: (params) => {
        const name = params.row.edited_by_name ? params.row.edited_by_name : 'Unknown';
        return (
          <Tooltip title={name} arrow placement="top">
            <span style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
              {name}
            </span>
          </Tooltip>
        );
      },
    },
    {
      field: 'ip_address',
      headerName: 'IP Address',
      width: 160,
    },
    {
      field: 'edited_date',
      headerName: 'Edited Date & Time',
      width: 200,
      renderCell: (params) => dayjs(params.value).format('YYYY-MM-DD HH:mm:ss'),
    },
    {
      field: 'template_version',
      headerName: 'Last Version',
      width: 120,
      renderCell: (params) => {
        return params.row.template_version ? 'v' + params.row.template_version : 'Unknown'
      },
    },
    {
      field: 'previous_template',
      headerName: 'Previous Template Content',
      width: 200,
      flex: 1,
      renderCell: (params) => {
        return (
          <Button
            variant='text'
            onClick={() => handleOpenPastContentModal(params.row.previous_temp_content)}
            style={{ textDecoration: 'underline', color: '#007BFF' }}
            startIcon={<Description />}
          >
            View Old Template
          </Button>
        )
      },
    },
  ]

  if (!template) {
    return (
      <div className="flex flex-col items-center justify-center p-6 bg-gray-50 rounded-md shadow-md">
        <h2 className="text-xl font-semibold text-gray-700 mb-4">No Template Data Found</h2>
        <p className="text-gray-500 mb-6 px-4">Couldn't find any template data to display at the moment.</p>
      </div>

    )
  }

  return (
    <Theme2 theme={theme2}>
      <Global styles={globalStyle} />
      <Box sx={{ display: 'flex', justifyContent: 'center', padding: 2, }}>
        <Paper
          elevation={3}
          sx={{
            padding: 2,
            width: '100%',
            maxWidth: '1200px',
            borderRadius: 2,
            overflow: 'hidden',
            minHeight: '80vh', 
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Typography variant="h6" sx={{ mb: 2, fontWeight: 'bold' }}>
            Template Edit Logs
          </Typography>
          <StripedDataGrid
            rows={data?.map((item, index) => ({
                ...item,
                s_no: index + 1,
              }))}
            getRowId={(row) => row.id}
            columns={columns}
            disableColumnFilter
            disableColumnSelector
            disableDensitySelector
            getRowClassName={(params) =>
              params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
            }
            disableRowSelectionOnClick
            autoHeight
            sx={{
                flexGrow: 1, 
                height: '100%',
              }}
          />
        </Paper>
        <Modal open={viewPastContent} onClose={handleClosePastContentModal}>
          <Card
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 750,
              backgroundColor: 'white',
              padding: '16px',
              boxShadow: 24,
              borderRadius: '4px',
              maxHeight: '68vh', // Set maximum height for the card
              overflowY: 'auto',
            }}
          >
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <h2 style={{ textDecoration: 'underline' }}>Email Template</h2>
              <Button
                onClick={handleClosePastContentModal}
                variant='text'
                color='inherit'
                startIcon={<Cancel />}
              ></Button>
            </div>
            <CardContent sx={{ background: '#e8e8e8', marginTop: 1 }}>
              <p
                dangerouslySetInnerHTML={{
                  __html: pastContent,
                }}
              />
            </CardContent>
          </Card>
        </Modal>
      </Box>
    </Theme2>
  )
}
