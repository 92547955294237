import * as React from 'react'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Checkbox from '@mui/material/Checkbox'
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'
import Chip from '@mui/material/Chip'
import { DataGrid, GridActionsCellItem, GridToolbar, gridClasses } from '@mui/x-data-grid'
import dayjs from 'dayjs'
import { alpha, styled } from '@mui/material/styles'

import {
  ArrowRightAlt,
  CancelOutlined,
  CloudUpload,
  DraftsOutlined,
  DraftsTwoTone,
  Edit,
  Mail,
  MailOutlineTwoTone,
  Payment,
  Send,
  SwitchAccessShortcutAdd,
  SyncAlt,
  Upload,
  UploadOutlined,
} from '@mui/icons-material'
import { useLocation, useNavigate } from 'react-router-dom'

import { Badge, IconButton, Modal, Tooltip } from '@mui/material'

import {
  bulkTicketUpload,
  getOverduePayment,
  getPassengerListApi,
  getPaymentPending,
  getReadyForTicketing,
  getTicketlist,
  getUserTicketlist,
  unReadandReadApi,
} from '../../../../services/configService'
import { SnackbarContext } from '../../../../context/Snackbar'
import TableMenu from '../../../../components/table_modal/TableMenu'
import TableModal from '../../../../components/table_modal/TableModal'
import UploadPnr from '../../../agent/dashboard/my_booking/UploadPnr'
import { currencyFormatter } from '../../../../utils/common'

const ODD_OPACITY = 0.2

const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
  [`& .${gridClasses.row}.even`]: {
    backgroundColor: '#E3EFF7',
  },
  [`& .${gridClasses.row}.void`]: {
    backgroundColor: '#f123',
  },
  [`& .${gridClasses.row}.cancel`]: {
    backgroundColor: '#f123',
  },
  [`& .${gridClasses.row}.green`]: {
    animation: 'ripple 3s ease-in-out',
  },
  '@keyframes ripple': {
    '0%': {
      backgroundColor: 'lightgreen',
    },
    '100%': {
      backgroundColor: 'white',
    },
  },
  [`& .${gridClasses.row}`]: {
    '&:hover, &.Mui-hovered': {
      backgroundColor: alpha('#FFFF00', ODD_OPACITY),
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
  },
  [`& .${gridClasses.columnHeader}`]: {
    color: 'black',
    width: '100%',
    backgroundColor: '#D3D3D3',
  },
}))

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: '#61699f',
  // border: '2px solid #000',
  boxShadow: 24,
  p: 1,
  // color: 'skyblue',
}

export default function ReadyForTicketingList({
  archived,
  setArchived,
  filterData,
  clearFilter,
  setStateData,
}) {
  const navigate = useNavigate()
  const { state } = useLocation()
  const [data, setData] = React.useState([])

  const { setOn, setSeverity, setMes } = React.useContext(SnackbarContext)

  const [loader, setLoader] = React.useState(false)
  const [pagination, setPagination] = React.useState(0)

  const [rowCounts, setRowCounts] = React.useState(0)
  const [isModalOpen, setModalOpen] = React.useState(false)
  const [uploadfileQueryId, setUploadfileQueryId] = React.useState(null)
  const [uploadPnrPassengerList, setUploadPnrPassengerList] = React.useState([])
  const [showticketPassengerModal, setShowticketPassengerModal] = React.useState(false)
  const [ticketuplQueryId, setticketuplQueryId] = React.useState('')

  async function getData() {
    try {
      const res = await getReadyForTicketing({
        page: pagination,
        ...filterData,
      })
      if (res.status !== 204) {
        setRowCounts(res.data.length)
        setData(res.data)
        setLoader(false)
      }else{
        setRowCounts(0)
        setData([])
        setLoader(false)
      }
    } catch (error) {
      console.log(error)
      setRowCounts(0)
      setData([])
      setLoader(false)
    }
  }
  const columns = [
    // {
    //   field: 'query_id',
    //   headerName: 'Query No.',
    //   width: 100,
    //   renderCell: (params) => {
    //     return params.row?.query_id ? (
    //       <p style={{ fontWeight: 600 }}>{params.row.query_id}</p>
    //     ) : (
    //       <p>N.A</p>
    //     )
    //   },
    // },
    {
      field: 'query_code',
      headerName: 'Query Code',
      flex: 1,
      minWidth: 180,

      renderCell: (params) => (
        <p
          style={{
            color: '#184E81',
            fontWeight: 500,
            textDecoration: 'underline',
          }}
        >
          {`#${params.row.query_code}`}
        </p>
      ),
    },
    {
        field: 'from_to',
        headerName: 'From -> To',
        flex: 1,
        minWidth: 100,
        renderCell: (params) => {
          return (
            <div>
            <Tooltip
              title={
                <div style={{ fontSize: '13px' }}>
                  <div>
                    {/* {params?.row?.is_return_trip === 1
                      ? 'Round trip'
                      : params?.row?.is_return_trip === 2
                      ? 'One-way trip'
                      : 'Multi-City'} */}
                      {
                        params?.row?.return_destination
                          ? 'Round trip'
                          : 'One-way trip'
                      }
                  </div>
                  <div>{params?.row?.origin}</div>
                  <div>{params?.row?.destination}</div>
                </div>
              }
            >
              <div
                style={{
                  display: 'flex',
                  gap: '2px',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <div style={{ fontWeight: '600' }}>
                  {params?.row?.origin}
                </div>
                {/* {params?.row?.is_return_trip == 1 ? (
                  <SyncAlt />
                ) : (
                  <ArrowRightAlt />
                )} */}
                {params?.row?.return_destination ? (
                  <SyncAlt />
                ) : (
                  <ArrowRightAlt />
                )}
                <div style={{ fontWeight: '600' }}>
                  {params?.row?.destination}
                </div>
                {/* {params?.row?.is_return_trip == 3 && <ArrowRightAlt />}
                <div style={{ fontWeight: '600' }}>
                  {params?.row?.return_destination}
                </div> */}
              </div>
            </Tooltip>
          </div>
          )
        },
      },
    {
      field: 'booking_date',
      headerName: 'BKG DT.',
      flex: 1,
      minWidth: 100,
      renderCell: (params) => {
        return (
          <p style={{ fontWeight: 500 }}>
            {params?.row?.booking_date
              ? dayjs(params.row.booking_date,'DD-MM-YYYY').format('DD-MM-YYYY')
              : 'N.A'
              }
          </p>
        )
      },
    },
    {
        field: 'depart_date',
        headerName: 'DPT DT.',
        flex: 1,
        minWidth: 100,
        renderCell: (params) => {
          return (
            <p style={{ fontWeight: 500 }}>
              {params?.row?.depart_date
                ? dayjs(params.row.depart_date, 'DD-MM-YYYY').format('DD-MM-YYYY')
                : 'N.A'}
            </p>
          )
        },
    },
    {
      field: 'return_depart_date',
      headerName: 'RTN DT.',
      flex: 1,
      minWidth: 100,
      renderCell: (params) => {
        return (
          <p style={{ fontWeight: 500 }}>
            {params?.row?.return_depart_date
              ? dayjs(params.row.return_depart_date, 'DD-MM-YYYY').format('DD-MM-YYYY')
              : 'N.A'}
          </p>
        )
      },    
    },
    {
        field: 'agent_name',
        headerName: 'Customer Name',
        flex: 1,
        minWidth: 200,
        renderCell: (params) => {
          return params?.row?.agent_name ? (
            <p style={{ fontWeight: 600 }}>{params.row.agent_name}</p>
          ) : (
            <p>{params.row.agent_name}</p>
          )
        },
      },
    {
        field: 'e_avail_seat',
        headerName: 'PAX',
        flex: 1,
        minWidth: 100,
        renderCell: (params) => {
          return (
            <Tooltip title={
              <div style={{ fontSize: '13px' }}>
                <div>Adults & Child : {params?.row?.e_avail_seat ? params?.row?.e_avail_seat : 0}</div>
                <div>Infants : {params?.row?.i_avail_seat ? params?.row?.i_avail_seat : 0}</div>
              </div>
            }>
              <p style={{ fontWeight: 500 }}>{params?.row?.e_avail_seat + (params?.row?.i_avail_seat ? " (INF:"+params?.row?.i_avail_seat +")": '')}</p>
            </Tooltip>
          )
        }
    },
    {
        field: 'rate_per_pax',
        headerName: 'Rate/Pax',
        flex: 1,
        minWidth: 100,
        renderCell: (params) => {
          return (
            <p style={{ fontWeight: 500 }}>
              {params?.row?.total_fare ? currencyFormatter.format(params?.row?.total_fare/((Number(params?.row?.e_avail_seat) || 1))) : 'N.A'}
            </p>
          )
        }
    },
    // {
    //     field: 'status',
    //     headerName: 'Status',
    //     flex: 1,
    //     minWidth: 150,
    //     renderCell: (params) => {
    //       return (
    //         <p style={{ fontWeight: 500, color: 'green', }}>
    //           {"Ready for Ticketing"}
    //         </p>
    //       )
    //     }
    // },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 75,

      cellClassName: 'actions',
      getActions: ({ row }) => {
        return [
              <TableMenu
                data={[
                  {
                    id: 36,
                    title: 'Ticket Bulk Upload',
                    icon: <CloudUpload fontSize='small' color='disabled' />,
                    // disable: row?.is_cancelled == 1 ? true : false,
                    fn: () => handleBulkUploadmodal(row?.query_id),
                  },
                  {
                    id: 3,
                    title: 'Upload Ticket',
                    icon: <Upload fontSize='small' color='info' />,
                    // disable: row?.is_cancelled == 1 ? true : false,
                    fn: () => handleUploadticketOpenModal(row?.query_id),
                  },
                ]}
              />,
            ]
      },
    }
  ]

  React.useEffect(() => {
    if (clearFilter) {
      setLoader(true)
      getData()
    }
  }, [clearFilter])

  React.useEffect(() => {
    setLoader(true)
    getData() // Call the async function
  }, [pagination])
  React.useEffect(() => {
    setLoader(true)
    getData() // Call the async function
  }, [filterData])

  // React.useEffect(() => {
  //   if (state?.length > 0) {
  //     setStateData(state)
  //   }
  // }, [state])

  console.log('state data', pagination)
  async function getPassengerListData(id) {
    try {
      const res = await getPassengerListApi(id)
      if (res === 204) {
        setSeverity('warning')
        setMes('Passenger details not uploaded yet')
        setOn(true)
        return false
      }
      if (res !== 204 && res !== 401) {
        return res.data
      }
    } catch (error) {
      console.log(error)
    }
  }
  const handleBulkUploadmodal = async (queryId) => {
    setModalOpen(true)
    setUploadfileQueryId(queryId)
  }
  const handleCloseUploadModel = () => {
    setModalOpen(false)
  }
  const handleUploadticketOpenModal = async (id) => {
    const passengerList = await getPassengerListData(id)
    if (!passengerList) return
    setUploadPnrPassengerList(
      passengerList?.filter((item) => item.is_cancelled == 0) ?? []
    )
    setShowticketPassengerModal(true)
    setticketuplQueryId(id)
  }
  const handleCloseUploadPassengerModal = () => {
    setShowticketPassengerModal(false)
  }
  const handleBulkUpload = async () => {
    const passengerList = await getPassengerListData(uploadfileQueryId)
    if (!passengerList) return
    const input = document.createElement('input')
    input.type = 'file'
    input.accept = '.zip, .rar' // Restrict to ZIP and RAR files

    input.onchange = async (event) => {
      const file = event.target.files[0]
      if (file) {
        const fileType = file.name.split('.').pop().toLowerCase()

        if (fileType !== 'zip' && fileType !== 'rar') {
          setSeverity('error')
          setMes('Only ZIP and RAR files are allowed!')
          setOn(true)
          return
        }

        // Convert file to ArrayBuffer
        const buffer = await file.arrayBuffer()

        // Create FormData and append the buffer
        const formData = new FormData()
        formData.append(
          'file',
          new Blob([buffer], { type: file.type }),
          file.name
        )
        formData.append('query_id', uploadfileQueryId)

        setLoader(true)

        // Send FormData to API
        try {
          const res = await bulkTicketUpload(formData)
          console.log('res', res)

          if (res != 204 && res != 406) {
            setSeverity('success')
            setMes('File uploaded successfully')
            setOn(true)
            setLoader(false)
            handleUploadticketOpenModal(uploadfileQueryId)
            setModalOpen(false)
          } else if (res === 406 || res === 400) {
            setSeverity('error')
            setMes('File not supported')
            setOn(true)
            setLoader(false)
            setModalOpen(false)
          }
        } catch (error) {
          setSeverity('error')
          setMes('File not supported')
          setOn(true)
          setLoader(false)
          setModalOpen(false)
        }
      }
    }

    input.click() // Trigger the file input dialog
  }

  return (
    <Box sx={{ height: '80vh', width: '100%' }}>
      <Modal
        open={loader}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <div className='loader-container'>
          <div class='dot-spinner'>
            <div class='dot-spinner__dot'></div>
            <div class='dot-spinner__dot'></div>
            <div class='dot-spinner__dot'></div>
            <div class='dot-spinner__dot'></div>
            <div class='dot-spinner__dot'></div>
            <div class='dot-spinner__dot'></div>
            <div class='dot-spinner__dot'></div>
            <div class='dot-spinner__dot'></div>
          </div>
          <p
            style={{
              marginLeft: '10px',
              fontWeight: '500',
              marginTop: '10px',
            }}
          >
            Please wait!!
          </p>
        </div>
      </Modal>

      <StripedDataGrid
        rows={data?.slice(0, 10)?.map((item, index) => ({
          ...item,
          s_no: index + 1,
          payment_type:
            item.amount_type == 1
              ? 'Token Amount'
              : item.amount_type == 2
              ? 'Advance Amount'
              : 'Final Amount',
          sector: item.origin,
        }))}
        getRowId={(row) => row?.s_no}
        columns={columns}
        initialState={{
          pagination: { paginationModel: { pageSize: 30, page: 0 } },
        }}
        rowCount={rowCounts} // Define the total number of rows
        paginationMode='server' // Enables controlled pagination
        onPageChange={(page) => {
          console.log('ttpage', page)

          setPagination(page)
          // setFliterDataPagination(page)
        }}
        onPaginationModelChange={(params) => {
          console.log('pppage', params)
          setPagination(params.page)
          // setFliterDataPagination(params.page)
        }}
        pageSizeOptions={[10, 20, 50]} // Set the available page size options
        disableColumnFilter
        disableColumnSelector
        disableDensitySelector
        slots={{ toolbar: GridToolbar }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
          },
        }}
        getRowClassName={(params) =>
          params.row.is_mapped == 1
            ? 'void'
            : params.indexRelativeToCurrentPage % 2 === 0
            ? 'even'
            : 'odd'
        }
        disableRowSelectionOnClick
      />
      <TableModal
          handleShowModal={showticketPassengerModal}
          handleCloseModal={handleCloseUploadPassengerModal}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100vh',
              width: '100vw',
            }}
          >
            <UploadPnr
              booking_id={ticketuplQueryId}
              getBookingList={uploadPnrPassengerList}
              handleClosePassengerModal={handleCloseUploadPassengerModal}
            />
          </div>
        </TableModal>
      <Modal open={isModalOpen} onClose={handleCloseUploadModel}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'white',
            boxShadow: 24,
            p: 3,
            borderRadius: 2,
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginBottom: '12px',
            }}
          >
            <h2 style={{ color: '#184E81', textDecoration: 'underline' }}>
              Upload File
            </h2>
            <IconButton onClick={handleCloseUploadModel}>
              <CancelOutlined />
            </IconButton>
          </div>
          <Typography variant='body2' color='textSecondary'>
            Please select your file. Only ZIP or RAR files are allowed.
          </Typography>

          <Box mt={2} display='flex' justifyContent='flex-end'>
            <Button
              style={{
                marginBottom: '16px',
                padding: '10px',
                border: '2px dashed #184E81',
                borderRadius: '8px',
                transition: 'background-color 0.3s',
                borderColor: '#184E81',
                backgroundColor: '#ffffff',
                color: '#184E81',
              }}
              variant='outlined'
              disabled={loader}
              startIcon={<UploadOutlined />}
              onClick={handleBulkUpload}
              fullWidth
            >
              Upload File
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  )
}
