import * as React from 'react'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Checkbox from '@mui/material/Checkbox'
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'
import Chip from '@mui/material/Chip'
import { DataGrid, GridToolbar, gridClasses } from '@mui/x-data-grid'
import dayjs from 'dayjs'
import { alpha, styled } from '@mui/material/styles'

import {
  Download,
  DraftsOutlined,
  DraftsTwoTone,
  Mail,
  MailOutlineTwoTone,
  Payment,
  Send,
  SwitchAccessShortcutAdd,
} from '@mui/icons-material'
import { useLocation, useNavigate } from 'react-router-dom'

import { Badge, IconButton, Modal, Tooltip } from '@mui/material'
import TableMenu from '../../components/table_modal/TableMenu'
import { downloadAttachment } from '../../utils/common'
import { getAddOnServicesList } from '../../services/configService'

const ODD_OPACITY = 0.2

const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
  [`& .${gridClasses.row}.even`]: {
    backgroundColor: '#E3EFF7',
  },
  [`& .${gridClasses.row}.void`]: {
    backgroundColor: '#f123',
  },
  [`& .${gridClasses.row}.cancel`]: {
    backgroundColor: '#f123',
  },
  [`& .${gridClasses.row}.green`]: {
    animation: 'ripple 3s ease-in-out',
  },
  '@keyframes ripple': {
    '0%': {
      backgroundColor: 'lightgreen',
    },
    '100%': {
      backgroundColor: 'white',
    },
  },
  [`& .${gridClasses.row}`]: {
    '&:hover, &.Mui-hovered': {
      backgroundColor: alpha('#FFFF00', ODD_OPACITY),
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
  },
  [`& .${gridClasses.columnHeader}`]: {
    color: 'black',
    width: '100%',
    backgroundColor: '#D3D3D3',
  },
}))

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: '#61699f',
  // border: '2px solid #000',
  boxShadow: 24,
  p: 1,
  // color: 'skyblue',
}

export default function PrePassengerlisting({ passengers }) {
  const navigate = useNavigate()
  const { state } = useLocation()
  const [data, setData] = React.useState([])

  const [loader, setLoader] = React.useState(false)
  const [pagination, setPagination] = React.useState(0)
  const [servicesList, setServicesList] = React.useState([])
  const [rowCounts, setRowCounts] = React.useState(0)

  // const handleOpen = () => setOpen(true);
  const getAllServices = async () => {
      setLoader(true)
      try {
        const res = await getAddOnServicesList()
          if (res !== 204 && res !== 401) {
              // console.log('services', res);
              setServicesList(res?.data)
          }
      } catch (error) {
          console.log(error)
      } finally {
          setLoader(false)
      }
  }
  React.useEffect(() => {
    getAllServices();
  }, [])

  const columns = [
    // {
    //   field: 'actions',
    //   type: 'actions',
    //   headerName: '',
    //   width: 75,

    //   cellClassName: 'actions',
    //   renderCell: (params) => {
    //     return (
    //       <Checkbox
    //         // checked={isChecked[params.row.p_id]}
    //         onChange={(event) => handleCheckboxChange(event, params.row)}
    //       />
    //     )
    //   },
    // },
    {
      field: 'name',
      headerName: 'Name',
      width: 130,
      renderCell: (params) => {
        return params.row?.name ? (
          <p style={{ fontWeight: 500 }}>{params.row.name}</p>
        ) : (
          <p>N.A</p>
        )
      },
    },

    {
      field: 'email_id',
      headerName: 'Email ID',
      flex: 1,
      minWidth: 200,
      renderCell: (params) => (
        <p
          style={{
            color: '#184E81',
            fontWeight: 500,
            textDecoration: 'underline',
          }}
        >
          {`${params.row.email_id}`}
        </p>
      ),
    },
    {
      field: 'pan_no',
      headerName: 'PAN No.',
      minWidth: 120,
      renderCell: (params) => {
        return params.row?.pan_no ? (
          <p style={{ fontWeight: 500 }}>{params.row.pan_no}</p>
        ) : (
          <p>N.A</p>
        )
      },
    },
    {
      field: 'passport_no',
      headerName: 'Passport No.',
      minWidth: 110,
      renderCell: (params) => {
        return params.row?.passport_no ? (
          <p style={{ fontWeight: 500 }}>{params.row.passport_no}</p>
        ) : (
          <p>N.A</p>
        )
      },
    },
    {
      field: 'mobile_no',
      headerName: 'Mobile No.',
      minWidth: 100,
      renderCell: (params) => {
        return (
          <p style={{ color: '#184E81', fontWeight: 500 }}>
            {params.row.mobile_no}
          </p>
        )
      },
    },
    {
      field: "additional_service",
      headerName: "Additional Services",
      minWidth: 250,
      flex: 1,
      renderCell: (params) => {
        const { row } = params;
        return (
          <Tooltip title={row?.additional_service ? row.additional_service.split(',').map((service_id) => servicesList.find(service => service.id == service_id)?.charge_name).join(', ') : "N.A"}>
            <p style={{
              color: '#184E81',
              fontWeight: 500,
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              maxWidth: '200px', // Adjust width as needed
              cursor: 'pointer'
            }}>
              {row?.additional_service 
                ? row.additional_service.split(',').map((service_id) => servicesList.find(service => service.id == service_id)?.charge_name).join(', ') 
                : "N.A"}
            </p>
          </Tooltip>
        );
      },
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 75,
      cellClassName: 'actions',
      getActions: ({ row }) => {
        return [
          <>
            <TableMenu
              data={[
                {
                  id: 1,
                  title: 'Download Ticket',
                  icon: <Download fontSize='small' color='warning' />,
                    disable: !row?.ticket_link ? true : false,
                    fn: () => downloadAttachment(passengers?.ticket_link, 'Ticket'),
                },

                
              ]}
            />
          </>,
        ]
      },
    },
  ]

  return (
    <Box sx={{ height: '39vh', width: '100%' }}>
      <Modal
        open={loader}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <div className='loader-container'>
          <div class='dot-spinner'>
            <div class='dot-spinner__dot'></div>
            <div class='dot-spinner__dot'></div>
            <div class='dot-spinner__dot'></div>
            <div class='dot-spinner__dot'></div>
            <div class='dot-spinner__dot'></div>
            <div class='dot-spinner__dot'></div>
            <div class='dot-spinner__dot'></div>
            <div class='dot-spinner__dot'></div>
          </div>
          <p
            style={{
              marginLeft: '10px',
              fontWeight: '500',
              marginTop: '10px',
            }}
          >
            Please wait!!
          </p>
        </div>
      </Modal>

      <StripedDataGrid
        rows={passengers?.map((item, index) => ({
          ...item,
          s_no: index + 1,
          name:
            item?.first_name + ' ' + item?.middle_name + ' ' + item?.last_name,
        }))}
        getRowId={(row) => row?.id}
        columns={columns}
        initialState={{
          pagination: { paginationModel: { pageSize: 30, page: 0 } },
        }}
        rowCount={passengers?.length} // Define the total number of rows
        paginationMode='server' // Enables controlled pagination
        onPageChange={(page) => {
          setPagination(page)
          // setFliterDataPagination(page)
        }}
        onPaginationModelChange={(params) => {
          setPagination(params.page)
          // setFliterDataPagination(params.page)
        }}
        pageSizeOptions={[10, 20, 50]} // Set the available page size options
        disableColumnFilter
        disableColumnSelector
        disableDensitySelector
        slots={{ toolbar: GridToolbar }}
        // slotProps={{
        //   toolbar: {
        //     showQuickFilter: true,
        //   },
        // }}
        getRowClassName={(params) =>
          params.row.is_mapped == 1
            ? 'void'
            : params.indexRelativeToCurrentPage % 2 === 0
            ? 'even'
            : 'odd'
        }
        disableRowSelectionOnClick
        density='compact' // Makes it more compact
        rowHeight={50} // Manually set row height for more control
        sx={{
          '& .MuiDataGrid-cell': {
            padding: '4px', // Reduce padding inside cells
          },
          '& .MuiDataGrid-columnHeaders': {
            minHeight: '32px', // Reduce column header height
          },
          '& .MuiDataGrid-columnHeader': {
            padding: '5px', // Reduce header padding
          },
          '& .MuiDataGrid-row': {
            minHeight: '50px', // Reduce row height
          },
        }}
      />
      {/* <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={style}>
          <Makepaymentsuccess paymentdetails={paymentdetails} />
        </Box>
      </Modal> */}
    </Box>
  )
}
