import * as React from 'react'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Checkbox from '@mui/material/Checkbox'
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'
import Chip from '@mui/material/Chip'
import { DataGrid, GridToolbar, gridClasses } from '@mui/x-data-grid'
import dayjs from 'dayjs'
import { alpha, styled } from '@mui/material/styles'

import {
  AccountCircleRounded,
  AttachMoney,
  CalendarMonth,
  CalendarToday,
  DraftsOutlined,
  DraftsTwoTone,
  Event,
  FiberManualRecord,
  FlightLand,
  FlightTakeoff,
  LocationOn,
  Mail,
  MailOutlineTwoTone,
  Payment,
  Person,
  Schedule,
  Send,
  SwitchAccessShortcutAdd,
  WarningAmber,
} from '@mui/icons-material'
import { useLocation, useNavigate } from 'react-router-dom'

import {
  Avatar,
  Badge,
  Card,
  CardContent,
  CardHeader,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from '@mui/material'

import {
  getOverduePayment,
  getOverduePaymentApi,
  getTicketlist,
  getUserTicketlist,
  unReadandReadApi,
} from '../../../../services/configService'
import { SnackbarContext } from '../../../../context/Snackbar'

const ODD_OPACITY = 0.2

const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
  [`& .${gridClasses.row}.even`]: {
    backgroundColor: '#E3EFF7',
  },
  [`& .${gridClasses.row}.void`]: {
    backgroundColor: '#f123',
  },
  [`& .${gridClasses.row}.cancel`]: {
    backgroundColor: '#f123',
  },
  [`& .${gridClasses.row}.green`]: {
    animation: 'ripple 3s ease-in-out',
  },
  '@keyframes ripple': {
    '0%': {
      backgroundColor: 'lightgreen',
    },
    '100%': {
      backgroundColor: 'white',
    },
  },
  [`& .${gridClasses.row}`]: {
    '&:hover, &.Mui-hovered': {
      backgroundColor: alpha('#FFFF00', ODD_OPACITY),
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
  },
  [`& .${gridClasses.columnHeader}`]: {
    color: 'black',
    width: '100%',
    backgroundColor: '#D3D3D3',
  },
}))

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: '#61699f',
  // border: '2px solid #000',
  boxShadow: 24,
  p: 1,
  // color: 'skyblue',
}

export default function OverDueWidget({
  archived,
  setArchived,
  filterData,
  clearFilter,
}) {
  const navigate = useNavigate()
  const { state } = useLocation()
  const [data, setData] = React.useState([])

  // const handleOpen = () => setOpen(true);

  async function getData() {
    try {
      const res = await getOverduePaymentApi()
      if (res.status !== 204) {
        setData(res.data)
      }
    } catch (error) {
      console.log(error)
    }
  }

  React.useEffect(() => {
    getData()
  }, [])

  return (
    <Box
      sx={{ bgcolor: 'background.paper', borderRadius: 3, boxShadow: 2, p: 2 }}
    >
      <Typography
        variant='h6'
        sx={{ mb: 2, borderLeft: '4px solid rgb(255, 169, 41)', pl: 1 }}
      >
        Overdue Payments
      </Typography>
      <List>
        {data?.slice(0, 10)?.map((query, index) => (
          <ListItem
            key={index}
            sx={{
              border: '1px solid rgb(236, 236, 236)',
              alignItems: 'flex-start',
            }}
          >
            <ListItemText
              primary={
                <Typography
                  variant='subtitle1'
                  sx={{ fontWeight: 600, color: '#184E81' }}
                >
                  {query.query_code}
                </Typography>
              }
              secondary={
                <>
                  <Typography variant='body2' sx={{ color: 'text.primary' }}>
                    <span style={{ color: '#757575' }}>Agent: </span>
                    {query.agent_name}{' '}
                  </Typography>

                  <Grid container spacing={1} sx={{ mt: 1 }}>
                    <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
                      <Event sx={{ fontSize: 18, mr: 0.5, color: 'tomato' }} />
                      <Typography variant='body2' sx={{ color: 'tomato' }}>
                        Due on: {dayjs(query.due_date).format('MMM D, YYYY')}
                      </Typography>
                    </Grid>
                  </Grid>
                </>
              }
            />

            {/* Flight Route and Dates Section */}
            <Grid
              container
              alignItems='center'
              spacing={2}
              justifyContent='flex-end'
              sx={{ flex: 1 }}
            >
              {/* Seat Availability */}
              <Grid item>
                <Chip
                  label={
                    <Box sx={{ display: 'flex', alignItems: 'center', p: 1 }}>
                      {/* <WarningAmber sx={{ color: 'error.main', mr: 0.5 }} /> */}
                      <Typography variant='subtitle2'>
                        Overdue Payment
                      </Typography>
                    </Box>
                  }
                  variant='outlined'
                  size='small'
                  color='warning'
                  sx={{
                    // bgcolor: 'error.light',
                    borderRadius: 2,
                    px: 1,
                    mt: 1,
                  }}
                />
              </Grid>
              <Grid item sx={{ alignItems: 'center' }}>
                <Typography
                  variant='subtitle1'
                  sx={{
                    textDecoration: 'underline',
                    display: 'inline',
                    fontSize: '13.5px',
                  }}
                >
                  {query.amount_type === 1
                    ? 'Token Amount'
                    : query.amount_type === 2
                    ? 'Advance Amount'
                    : 'Final Amount'}
                  : ₹{query.receive_amount}
                </Typography>
              </Grid>

              {/* Flight Route */}
              <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
                <FlightTakeoff
                  sx={{ fontSize: 20, mr: 0.5, color: 'text.secondary' }}
                />
                <Typography variant='body2'>
                  {query.origin} ➔ {query.destination}
                </Typography>
              </Grid>
            </Grid>
          </ListItem>
        ))}
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
          <Button
            variant='outlined'
            color='primary'
            onClick={() => navigate('/Over-due-logs')}
          >
            View More
          </Button>
        </Box>
      </List>
    </Box>
    // <Box
    //   sx={{ bgcolor: 'background.paper', borderRadius: 3, boxShadow: 2, p: 2 }}
    // >
    //   <Typography
    //     variant='h6'
    //     sx={{ mb: 2, borderLeft: '4px solid #2979ff', pl: 1 }}
    //   >
    //     Flight Queries
    //   </Typography>
    //   <TableContainer component={Paper}>
    //     <Table>
    //       <TableHead>
    //         <TableRow>
    //           <TableCell>Agent</TableCell>
    //           <TableCell>Query Code</TableCell>
    //           {/* <TableCell>Route</TableCell> */}
    //           <TableCell>Due Date</TableCell>
    //           <TableCell>Amount</TableCell>

    //           <TableCell>Status</TableCell>
    //         </TableRow>
    //       </TableHead>
    //       <TableBody>
    //         {data.map((query, index) => (
    //           <TableRow key={index}>
    //             <TableCell sx={{ display: 'flex', alignItems: 'center' }}>
    //               {/* <Avatar sx={{ bgcolor: 'error.main', mr: 1 }}>
    //                 {query.agent_name
    //                   .split(' ')
    //                   .map((n) => n[0])
    //                   .join('')}
    //               </Avatar> */}
    //               <Typography variant='body2'>{query.agent_name}</Typography>
    //             </TableCell>
    //             <TableCell>{query.query_code}</TableCell>
    //             {/* <TableCell>
    //               <FlightTakeoff
    //                 sx={{ fontSize: 18, mr: 0.5, color: 'text.secondary' }}
    //               />
    //               {query.origin} ➔ {query.destination}
    //             </TableCell> */}
    //             <TableCell>

    //               {dayjs(query.due_date).format('MMM D, YYYY')}
    //             </TableCell>
    //             <TableCell>

    //               ₹{query.receive_amount.toLocaleString()}
    //             </TableCell>

    //             <TableCell>
    //               <Chip
    //                 label={
    //                   <Box sx={{ display: 'flex', alignItems: 'center' }}>
    //                     {/* <WarningAmber sx={{ color: 'error.main', mr: 0.5 }} /> */}
    //                     <Typography variant='subtitle2' color='error.main'>
    //                       Overdue
    //                     </Typography>
    //                   </Box>
    //                 }
    //                 variant='outlined'
    //                 size='small'
    //                 sx={{
    //                   borderColor: 'error.main',
    //                   color: 'error.main',
    //                   // bgcolor: 'error.light',
    //                   borderRadius: 2,
    //                   px: 1,
    //                 }}
    //               />
    //             </TableCell>
    //           </TableRow>
    //         ))}
    //       </TableBody>
    //     </Table>
    //   </TableContainer>
    // </Box>
  )
}
