import React from 'react'
import DashboardLayout from '../../../../components/dashboard_layout/DashboardLayout'
import Paylisting from './Paylisting'
import { Create, Height, Link } from '@mui/icons-material'
import { Card, CircularProgress, FormHelperText } from '@mui/material'
import CardHeader from '@mui/material/CardHeader'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Modal from '@mui/material/Modal'
import Grid from '@mui/material/Grid'
import {
  apiSearchAgent,
  createPaymentLinkApi,
  getAgent,
} from '../../../../services/configService'
import { heIL } from '@mui/x-data-grid'
import CustomTextArea from '../../../../components/common/CustomTextArea'
import { SnackbarContext } from '../../../../context/Snackbar'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,

  bgcolor: 'background.paper',
  // border: '2px solid #000',
  boxShadow: 24,
  p: 2,
}

const Paymentlinkgenerate = () => {
  const { setOn, setSeverity, setMes } = React.useContext(SnackbarContext)
  const [open, setOpen] = React.useState(false)
  const [render, setRender] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const [error, setError] = React.useState('')
  const [agent, setAgent] = React.useState([])
  const [options, setOptions] = React.useState([])
  const [agentId, setAgentId] = React.useState(null)
  const [fieldError, setFieldError] = React.useState({})
  const [defaultAgent, setDefaultAgent] = React.useState(null)
  const [createlinkdata, setCreatelinkdata] = React.useState({
    internal_note: '',
    amount: '',
  })

  async function getAgentlist() {
    const get_agentlist = await getAgent()
    setAgent(get_agentlist?.data?.filter((item) => item.is_active === 1))

  }


  // const handleChangeAgent = (e, v) => {
  //   setAgentId(v?.id)
  //   const x = agent.find((a) => a.id === v?.id)
  //   setDefaultAgent(x)
  //   setFieldError((prev) => ({ ...prev, agent: '' }))
  // }

   const searchQuerycode = async (change) => {
      const data = {
        search_text: change,
      }
  
      try {
        const res = await apiSearchAgent(data)
  
        if (res !== 204 && res !== 406) {
          console.log('res', res.data)
  
          setOptions(res.data)
        } else {
          setOptions([])
        }
      } catch (error) {
        setSeverity('warning')
        setMes('')
        setOn(true)
        // console.log('error in generate quote ', error)
      } finally {
      }
    }
    const handleInputChange = async (_, value) => {
      // setInputValue(value)
  
      const checkedemail = value?.match(
        /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b/
      )?.[0]
  
      if (value.length > 2) {
        // Fetch suggestions when input has 3+ chars
        setLoading(true)
        const results = await searchQuerycode(value)
        // setOptions(results || []) // Update options with fetched data
        setLoading(false)
      } else {
        setOptions([]) // Clear options for shorter input
      }
      const x = options.find((a) => a.email_id === checkedemail)
  
      setAgentId(x?.auth_id)
    }

  const handleChange = (e) => {
    const { name, value } = e.target
    setCreatelinkdata((prev) => ({
      ...prev,
      [name]: value,
    }))
  }
  async function handleCreatelink() {
    const data = {
      customer_id: agentId,
      query_id: 333,
      amount: createlinkdata.amount,
      remarks: createlinkdata.internal_note,
    }
    try {
      const res = await createPaymentLinkApi(data, setError)
    
      if (res !== 204 && res !== 406) {
        getAgentlist()
        setCreatelinkdata({
          internal_note: '',
          amount: '',
        })
        setRender(true)
        setOpen(false)
        setDefaultAgent(null)
        setSeverity('success')
        setMes('Payment link created successfully')
        setOn(true)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  React.useEffect(() => {
    getAgentlist()
  }, [])

  return (
    <DashboardLayout
      heading='Payment Process'
      pathName='Payment Process'
      button={true}
      btnIcon={<Create />}
      onClick={handleOpen}
      btnText='Payment link'
    >
      <Paylisting render={render} setRender={setRender} />

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Card sx={style}>
          <Typography
            id='modal-modal-title'
            variant='h6'
            component='h2'
            color={'#184E81'}
          >
            Generate Payment Link
          </Typography>
          <Typography
            id='modal-modal-description'
            sx={{ mt: 0.1, mb: 2, fontWeight: 500 }}
          >
            Enter customer details to create a payment link
          </Typography>
          <Grid container spacing={2}>
            <Grid item md={12}>
              {/* <Autocomplete
                fullWidth
                className='form_style'
                disablePortal
                options={agent}
                value={defaultAgent}
                getOptionLabel={(option) => `${option.name} ${option.email_id}`}
                onChange={(e, v) => handleChangeAgent(e, v)}
                renderOption={(props, option) => (
                  <Box
                    component='li'
                    sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                    {...props}
                  >
                    <Typography variant='subtitle2' color='initial'>
                      {option.name}
                    </Typography>{' '}
                    &nbsp;
                    <Typography variant='subtitle2' color='#3a87ad'>
                      ({option.email_id})
                    </Typography>
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label='Agent list'
                    error={fieldError?.agent}
                    helperText={fieldError?.agent && 'Please select Agent'}
                  />
                )}
                size='small'
              /> */}
              <Autocomplete
              size='small'
              // disabled={queryId ? tru : true}
              options={options}
              getOptionLabel={(option) =>
                `${option.first_name + ' ' + option.last_name} (${
                  option.email_id
                })`
              }
              onInputChange={handleInputChange}
              renderOption={(props, option) => (
                <Box
                  component='li'
                  sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                  {...props}
                >
                  <Typography variant='subtitle2' color='initial'>
                    {option.first_name + ' ' + option.last_name}
                  </Typography>{' '}
                  &nbsp;
                  <Typography variant='subtitle2' color='#3a87ad'>
                    ({option.email_id})
                  </Typography>
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label='Search by Email or Name'
                  variant='outlined'
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {loading ? (
                          <CircularProgress color='inherit' size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                />
              )}
            />
              <FormHelperText style={{ color: 'red' }}>
                {error && error['customer_id']}
              </FormHelperText>
            </Grid>
            <Grid item md={12}>
              <TextField
                type='number'
                className='form_style'
                label='Amount'
                size='small'
                fullWidth
                name='amount'
                value={createlinkdata.amount}
                // error={error && error['amount']}
                onChange={(e) => handleChange(e, 'amount')}
              />
              <FormHelperText style={{ color: 'red' }}>
                {error && error['amount']}
              </FormHelperText>
            </Grid>

            <Grid item md={12}>
              <div style={{ padding: '0px' }}>
                <CustomTextArea
                  placeholder={'Description...'}
                  name={'internal_note'}
                  value={createlinkdata.internal_note}
                  onChange={(e) => handleChange(e, 'internal_note')}
                />
                <FormHelperText style={{ color: 'red' }}>
                  {error && error['remarks']}
                </FormHelperText>
              </div>
            </Grid>
            <Grid item md={12}>
              <Button variant='contained' onClick={handleCreatelink}>
                save
              </Button>
            </Grid>
          </Grid>
        </Card>
      </Modal>
    </DashboardLayout>
  )
}

export default Paymentlinkgenerate
