import {
  Button,
  Card,
  CardContent,
  FormHelperText,
  Grid,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import './form.css'
import { MuiOtpInput } from 'mui-one-time-password-input'
import Loginform from './Loginform'
import { useLocation, useNavigate } from 'react-router-dom'
import { handleChangeGlobal, setRole } from '../../utils/common'
import { getEmailOtp, loginOtpValidation } from '../../services/configService'
import useAuth from '../../hooks/useAuth'
import { SnackbarContext } from '../../context/Snackbar'
import LoadingBtn from '../common/LoadingBtn'

const Loginotp = ({ val, setval }) => {
  const theme = useTheme()
  const navigate = useNavigate()
  const location = useLocation()
  const from = location.state?.from?.pathname || '/dashboard'
  const { setAuth } = useAuth()
  const [otp, setOtp] = useState('')
  const [countdown, setCountdown] = useState(120)
  const [disabled, setDisabled] = useState(false)
  const [pas, setPas] = useState(true)
  const [showotp, setShowotp] = useState(false)
  const { setOn, setSeverity, setMes } = useContext(SnackbarContext)
  const [submitLoading, setSubmitLoading] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [otpData, setOtpData] = useState({
    email: '',
  })
  const [error, setError] = useState('')
  const [otpError, setOtpError] = useState('')

  const handleSendOtp = async () => {
    try {
      setIsLoading(true)
      setOtp('')
      const sendEmail = { email_id: otpData.email }
      const res = await getEmailOtp(sendEmail, setError)
      if (res !== 204 && res !== 406) {
        setCountdown(120)
        setShowotp(true)
        setDisabled(true)
        setSeverity('success')
        setMes('OTP Sent successfully')
        setOn(true)
      }
    } catch (err) {
      setIsLoading(false)
      setSeverity('error')
      setMes(err.message)
      setOn(true)
    } finally {
      setIsLoading(false)
    }
  }

  const handleVerifyOtp = async () => {
    if (true) {
      try {
        setSubmitLoading(true)
        const sendOtp = { email_id: otpData.email, otp }
        const res = await loginOtpValidation(sendOtp, setError)
        if (error && error.otp) {
          setSeverity('warning')
          setMes('Please enter valid OTP !')
          setOn(true)
          return 0
        }
        if (res === 410) {
          setSeverity('warning')
          setMes('OTP expired, Please resend !')
          setOn(true)
        }
        if (res !== 406 && res !== 204) {
          setAuth({
            id: res?.data?.auth_id,
            role: setRole(res?.data?.role, res?.data?.sub_role),
            isAuthenticated: true,
            details: res?.data?.details,
            menu_list: res?.data?.menu_list,
          })
          setSeverity('success')
          setMes('Login Successfully !')
          setOn(true)
          navigate(from, { replace: true })
        }
      } catch (err) {
        const c = err.message
        setSeverity('error')
        setMes(c)
        setOn(true)
        console.log('error in handle varify otp ', err.message)
      } finally {
        setSubmitLoading(false)
      }
    }
  }

  const removeError = (v1) => {
    if (error && error[v1]) {
      setError((prevError) => ({
        ...prevError,
        [v1]: null,
      }))
    }
  }

  useEffect(() => {
    if (otp.length === 6) {
      setOtpError(false)
    } else {
      setOtpError(true)
    }
  }, [otp])

  useEffect(() => {
    if (disabled) {
      const timer = setInterval(() => {
        if (countdown === 0) {
          setDisabled(false)
        } else {
          setCountdown(countdown - 1)
        }
      }, 1000)
      return () => clearInterval(timer)
    }
  }, [disabled, countdown])

  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'))
  return pas ? (
    <>
      {isSmallScreen ? (
        <div className='form-container'>
          <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',

                textAlign: 'center',
              }}
            >
              <img
                src={'../images/zealazerbaijanlogo10.png'}
                alt='logo'
                height={70}
              />
              <div>{/* Your card content here */}</div>
            </div>
          <Typography
            className='header'
            sx={{ fontWeight: 'bold', textAlign: 'center', padding: '5px' }}
          >
            Welcome to Zeal Azerbaijan Airlines
          </Typography>

          <div className='textfield'>
            <Typography
              variant='h5'
              sx={{ marginBottom: '5px', fontWeight: 'bold' }}
            >
              LOG IN
            </Typography>

            <Grid container sapcing={2}>
              <Grid xs={12}>
                <TextField
                  className='form_style'
                  size='medium'
                  label='Enter Email id or phone number'
                  sx={{ marginTop: '5%', width: '80%', marginLeft: '38px' }}
                  name='email'
                  value={otpData.email}
                  onChange={(e) =>
                    handleChangeGlobal(
                      e,
                      setOtpData,
                      setError,
                      error,
                      'email_id',
                      'email'
                    )
                  }
                  disabled={disabled}
                  error={(error && error['email_id']) || error['email']}
                  required
                />
                <FormHelperText style={{ color: 'red', marginLeft: '38px' }}>
                  {(error && error['email']) || error['email_id']}
                </FormHelperText>
              </Grid>
              <Grid xs={12}>
                <LoadingBtn
                  variant='contained'
                  size='small'
                  loading={isLoading}
                  // style={{
                  //   margin : "14px 0px 2% 24px",
                  //   fontWeight: "bold",
                  //   color: "black",
                  //   "&.Mui-disabled": {
                  //     color: "#5e2a3a !important"
                  //   },

                  className='loginbutton'
                  style={{
                    marginBottom: '2%',
                    fontWeight: 'bold',

                    marginTop: '14px',
                    marginLeft: '38px',
                    width: '80%',
                  }}
                  onClick={handleSendOtp}
                  disabled={disabled}
                  text={
                    disabled
                      ? `Resent OTP in ${Math.floor(countdown / 60)
                          .toString()
                          .padStart(2, '0')} : ${(countdown % 60)
                          .toString()
                          .padStart(2, 0)}`
                      : 'Send OTP'
                  }
                />
              </Grid>
            </Grid>
            <div className='links'>
              <Button
                size='small'
                variant='text'
                sx={{
                  color: 'black',
                  textDecoration: 'none',
                  fontSize: '11px',
                  fontWeight: 'bold',
                  float: 'left',
                }}
                onClick={() => setPas(!pas)}
              >
                Login with password
              </Button>
            </div>

            {showotp && (
              <>
                <Typography
                  variant='h7'
                  sx={{
                    fontWeight: '500',
                    marginX: '37px',
                    fontSize: '14px',
                    marginTop: '10px',
                  }}
                >
                  Enter OTP * <br />
                  <MuiOtpInput
                    length={6}
                    gap={1}
                    TextFieldsProps={{ size: 'small' }}
                    sx={{
                      marginBottom: '1rem',
                      width: '100%',
                      marginTop: '10px',
                    }}
                    value={otp}
                    onChange={(e) => {
                      setOtp(e)
                      removeError('otp')
                    }}
                  />
                  <helperText style={{ color: 'red' }}>
                    {error && error.otp && 'Please enter valid OTP'}
                  </helperText>
                </Typography>

                <LoadingBtn
                  variant='contained'
                  size='small'
                  onClick={handleVerifyOtp}
                  loading={submitLoading}
                  style={{
                    marginTop: '18px',
                    fontWeight: 'bold',
                    color: 'black',
                    fontSize: '12px',
                    width: '276px',
                    backgroundColor: '#f9da34',
                    alignItems: 'center',
                  }}
                  disabled={otpError}
                  text={'VERIFY'}
                />
              </>
            )}
            {/* <Button
              variant="text"
              size="small"
              sx={{ marginTop: "1rem", fontWeight: "bold", fontSize: "12px" }}
            >
              <NavLink to="/signup">signup</NavLink>
            </Button> */}
          </div>
        </div>
      ) : (
        <Card className='formcard' elevation={6} sx={{ borderRadius: 4, overflowY:'scroll' }}>
          <CardContent>
          <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',

                textAlign: 'center',
              }}
            >
              <img
                src={'../images/zealazerbaijanlogo10.png'}
                alt='logo'
                height={70}
              />
              <div>{/* Your card content here */}</div>
            </div>
            <Typography className='header' sx={{ fontWeight: '600' }}>
            Welcome to Zeal Azerbaijan Airlines
            </Typography>
            <div className='textfield'>
              <Typography
                variant='h5'
                sx={{ marginBottom: '5px', fontWeight: 'bold' }}
              >
                LOG IN
              </Typography>

              <Grid container sapcing={2}>
                <Grid xs={12}>
                  <TextField
                    className='form_style'
                    size='small'
                    label='Email id or Phone no.'
                    sx={{ width: '85%', marginTop: '5%', marginLeft: '24px' }}
                    name='email'
                    value={otpData.email}
                    onChange={(e) =>
                      handleChangeGlobal(
                        e,
                        setOtpData,
                        setError,
                        error,
                        'email_id',
                        'email'
                      )
                    }
                    disabled={disabled}
                    error={(error && error['email_id']) || error['email']}
                    required
                  />
                  <FormHelperText style={{ color: 'red', marginLeft: '26px' }}>
                    {(error && error['email']) || error['email_id']}
                  </FormHelperText>
                </Grid>
                <Grid xs={12}>
                  <LoadingBtn
                    className='loginbutton'
                    variant='contained'
                    size='small'
                    loading={isLoading}
                    color={'authBtn'}
                    style={{
                      margin: '14px 0px 2% 24px',
                      fontWeight: 'bold',
                      '&.Mui-disabled': {
                        color: '#5e2a3a !important',
                      },
                      width: '276px',
                      backgroundColor: 'gray !important',
                      alignItems: 'center',
                    }}
                    onClick={handleSendOtp}
                    disabled={disabled}
                    text={
                      disabled
                        ? `Resent OTP in ${Math.floor(countdown / 60)
                            .toString()
                            .padStart(2, '0')} : ${(countdown % 60)
                            .toString()
                            .padStart(2, 0)}`
                        : 'Send OTP'
                    }
                  />
                </Grid>
              </Grid>
              <div className='links'>
                <Button
                  size='small'
                  variant='text'
                  sx={{
                    textDecoration: 'none',
                    fontSize: '11px',
                    fontWeight: 'bold',
                    float: 'left',
                  }}
                  onClick={() => setPas(!pas)}
                >
                  Login with password
                </Button>
              </div>

              {showotp && (
                <>
                  <Typography
                    variant='h7'
                    sx={{
                      fontWeight: '500',
                      marginX: '25px',
                      fontSize: '14px',
                      marginTop: '10px',
                    }}
                  >
                    Enter OTP * <br />
                    <MuiOtpInput
                      length={6}
                      gap={1}
                      TextFieldsProps={{ size: 'small' }}
                      sx={{
                        marginBottom: '1rem',
                        width: '100%',
                        marginTop: '10px',
                      }}
                      value={otp}
                      onChange={(e) => {
                        setOtp(e)
                        removeError('otp')
                      }}
                    />
                    <helperText style={{ color: 'red' }}>
                      {error && error.otp && 'Please enter valid OTP'}
                    </helperText>
                  </Typography>

                  <LoadingBtn
                    variant='contained'
                    size='small'
                    onClick={handleVerifyOtp}
                    loading={submitLoading}
                    color={'authBtn'}
                    style={{
                      marginTop: '18px',
                      fontWeight: 'bold',
                      fontSize: '12px',
                      width: '276px',
                      alignItems: 'center',
                    }}
                    disabled={otpError}
                    text={'VERIFY'}
                  />
                </>
              )}
            </div>
          </CardContent>
        </Card>
      )}
    </>
  ) : (
    <Loginform />
  )
}

export default Loginotp
