import React, { useEffect, useState } from "react";
import { BarChart, Bar, XAxis, YAxis, Tooltip, Line, ResponsiveContainer, CartesianGrid, Legend, Cell, ComposedChart } from "recharts";


const formatCurrency = (value) => `₹${value.toLocaleString("en-IN")}`;
const customTooltip = ({ payload, label }) => {
    if (payload && payload.length) {
      const { revenue = 0, d2c_revenue = 0, total_revenue = 0 } = payload[0]?.payload ?? {};
      return (
        <div style={{
          backgroundColor: " #f9f9f9", 
          border: "1px solid #007bff", 
          opacity: "0.8",
          padding: "15px", 
          borderRadius: "10px", 
          boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)", 
          fontSize: "14px", 
          color: "#333"
        }}>
          <p style={{ fontWeight: "bold", color: "#007bff", fontSize: "16px", marginBottom: "10px" }}>{label}</p>
          <p style={{ margin: "5px 0", fontSize: "14px", fontWeight:500 }}><strong>B2C Revenue:</strong> {formatCurrency(revenue)}</p>
          <p style={{ margin: "5px 0", fontSize: "14px", fontWeight:500 }}><strong>D2C Revenue:</strong> {formatCurrency(d2c_revenue)}</p>
          <p style={{ margin: "5px 0", fontSize: "14px", fontWeight:500 }}><strong>Total Revenue:</strong> {formatCurrency(total_revenue)}</p>
        </div>
      );
    }
    return null;
  };

function getFiscalYearMonths(monthNumber) {
  const today = new Date();
  let startYear, endYear;

  // Determine the fiscal year (April - March)
  if (today.getMonth() + 1 >= 4) { // April (4) or later
      startYear = today.getFullYear();
      endYear = startYear + 1;
  } else { // Before April
      startYear = today.getFullYear() - 1;
      endYear = today.getFullYear();
  }

  const months = [];
  const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

  for (let i = 3; i <= 14; i++) { // April (index 3) to March (index 14)
      const monthIndex = i % 12; // Get the correct month index (0-11)
      const year = i < 12 ? startYear : endYear; // Determine the correct year
      const shortYear = String(year).slice(-2); // Get last 2 digits of the year
      months.push(`${monthNames[monthIndex]}-${shortYear}`);
  }

  return months[monthNumber];
}

const renderLegend = (props) => {
  return (
    <div style={{ display: "flex", justifyContent: "center", marginBottom: "10px" }}>
        <div  style={{ margin: "0 10px", display: "flex", alignItems: "center" }}>
          <span
            style={{
              display: "inline-block",
              width: 12,
              height: 12,
              backgroundColor: "#007bff",
              marginRight: 5,
              borderRadius: "50%",
            }}
          ></span>
          <span style={{ fontSize: "14px", fontWeight: "bold", color: "#333" }}>{"B2C Revenue"}</span>
        </div>
        <div  style={{ margin: "0 10px", display: "flex", alignItems: "center" }}>
          <span
            style={{
              display: "inline-block",
              width: 12,
              height: 12,
              backgroundColor: "#fa78ff",
              marginRight: 5,
              borderRadius: "50%",
            }}
          ></span>
          <span style={{ fontSize: "14px", fontWeight: "bold", color: "#333" }}>{"D2C Revenue"}</span>
        </div>
    </div>
  );
};

const RevenueChart = ({graphData,data_d2c}) => {
  const [data, setData] = useState([]);
  const [hoveredBar, setHoveredBar] = useState(null);
  useEffect(() => {
    let defaultData = [];
    for (let i = 0; i < 12; i++) {
      const monthName = getFiscalYearMonths(i);
      const monthData = graphData?.find((item) => item?.month === 1 + (i + 3) % 12) ?? {};
  
      defaultData.push({
        month_name: monthName,
        revenue: Number(monthData.revenue) || 0,
        bookings: Number(monthData.bookings) || 0,
        cancellations: Number(monthData.cancellations) || 0,
        quotes: Number(monthData.quotes) || 0,
        cancelledAmount: Number(monthData.cancelledAmount) || 0,
        d2c_revenue: Number(data_d2c?.find((item) => item?.month === 1 + (i + 3) % 12)?.revenue) || 0
      });
  
      defaultData[i].total_revenue = defaultData[i].revenue + defaultData[i].d2c_revenue;
    }
    setData(defaultData);
  }, [graphData, data_d2c]);
  const renderCustomizedLabel = (props) => {
    const { x, y, width, height, value } = props;
  
    const isShortValue = value.toString().length < 5;
    const offset = -40 // Adjust position based on value length
  
    return (
      <text
        x={x + width / 2} // Center the text horizontally in the bar
        y={y + height + offset} // Adjust the position slightly below the top
        fill={isShortValue ? " #285A64" : " #000000"}
        textAnchor="center" // Center the text
        fontSize="12px"
        fontWeight="bold"
        transform={`rotate(-90, ${x + width / 2}, ${y + height + offset})`} // Rotate text -90 degrees
      >
        {formatCurrency(value)}
      </text>
    );
  };
  
  return (
    <div style={{ width: "100%", maxWidth: "900px", margin: "auto", padding: "20px", borderRadius: "10px", background: "#fff", boxShadow: "0 4px 8px #000000" }}>
      <h2 style={{ textAlign: "center", color: "#007bff", fontWeight: "bold" }}>Monthly Revenue Overview</h2>
      <ResponsiveContainer width="100%" height={450}>
        <ComposedChart data={data} margin={{ top: 40, right: 60, left: 60, bottom: 80 }}>
          <CartesianGrid strokeDasharray="3 3" stroke="#ccc" />
          <XAxis 
            dataKey="month_name" 
            stroke=" #555" 
            angle={-30} 
            textAnchor="end" 
            tickMargin={10} 
            label={{ 
              value: "Month", 
              position: "bottom", 
              offset: 35, 
              style: { textAnchor: "middle", fontSize: 14, fontWeight: "bold", fill: "#555" }
            }} 
          />
          <YAxis 
            yAxisId="left" 
            stroke="#007bff" 
            tickFormatter={formatCurrency} 
            width={80} 
            label={{ 
              value: "Revenue (₹)", 
              angle: -90, 
              position: "left", 
              offset: 40, 
              textAnchor: "middle",
              style: { fontSize: 14, fontWeight: "bold", fill: "#007bff" }
            }} 
          />
          <Tooltip content={customTooltip} cursor={{ stroke: ' #ff6262'}} />
          {/* Bar for Revenue */}
          <Bar 
            yAxisId="left" 
            dataKey="total_revenue" 
            barSize={40} 
            radius={[8, 8, 0, 0]} 
            fill="url(#revenueGradient)"  
            onMouseEnter={(_, index) => setHoveredBar(index)}
            onMouseLeave={() => setHoveredBar(null)}
            name="Revenue (₹)" 
            label={renderCustomizedLabel} 
          >
            {data.map((entry, index) => (
              <Cell key={index} fill={index === hoveredBar ? " url(#hoverGradient)" : "url(#revenueGradient)"} 
              />
            ))}
          </Bar>
          {/* <Bar 
            yAxisId="left" 
            dataKey="d2c_revenue" 
            barSize={40} 
            radius={[8, 8, 0, 0]} 
            fill="url(#revenueGradient)"  
            onMouseEnter={(_, index) => setHoveredBar(index)}
            onMouseLeave={() => setHoveredBar(null)}
            name="Revenue (₹)" 
            // label={{ position: "top", fill: "#007bff", fontSize: 12, fontWeight: "600" }} 
          >
            {data.map((entry, index) => (
              <Cell key={index} fill={index === hoveredBar ? " url(#hoverGradient)" : "url(#d2cGradient)"}
              />
            ))}
          </Bar> */}
          <Line type="monotone" yAxisId="left" dataKey="total_revenue" stroke="#8884d8" strokeWidth={2} activeDot={{ r: 8 }} name="Revenue Trend" />
          {/* <Line type="monotone" yAxisId="left" dataKey="d2c_revenue" stroke="#d06aff" strokeWidth={2} activeDot={{ r: 8 }} name="Revenue Trend" /> */}

          {/* <Legend layout="horizontal" verticalAlign="top" align="center" content={renderLegend}>
            </Legend> */}
          {/* Gradient for Revenue */}
          <defs>
            <linearGradient id="revenueGradient" x1="0" y1="0" x2="0" y2="1">
              <stop offset="0%" stopColor="#007bff" stopOpacity={0.8} />
              <stop offset="100%" stopColor="#00c6ff" stopOpacity={0.6} />
            </linearGradient>
            <linearGradient id="hoverGradient" x1="0" y1="0" x2="0" y2="1">
              <stop offset="0%" stopColor="#ff4500" stopOpacity={0.8} />
              <stop offset="100%" stopColor="#ff4500" stopOpacity={0.6} />
            </linearGradient>
            <linearGradient id="d2cGradient" x1="0" y1="0" x2="0" y2="1">
              <stop offset="0%" stopColor="#fa78ff" stopOpacity={0.8} />
              <stop offset="100%" stopColor=" #fa78ff" stopOpacity={0.6} />
            </linearGradient>
          </defs>
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
};

export default RevenueChart;
