import * as React from 'react'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Checkbox from '@mui/material/Checkbox'
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'
import Chip from '@mui/material/Chip'
import { DataGrid, GridToolbar, gridClasses } from '@mui/x-data-grid'
import dayjs from 'dayjs'
import { alpha, styled } from '@mui/material/styles'

import {
  AccountCircleRounded,
  AttachMoney,
  CalendarMonth,
  CalendarToday,
  DraftsOutlined,
  DraftsTwoTone,
  Event,
  FiberManualRecord,
  FlightLand,
  FlightTakeoff,
  LocationOn,
  Mail,
  MailOutlineTwoTone,
  Payment,
  Person,
  Schedule,
  Send,
  SwitchAccessShortcutAdd,
  WarningAmber,
} from '@mui/icons-material'
import { useLocation, useNavigate } from 'react-router-dom'

import {
  Avatar,
  Badge,
  Card,
  CardContent,
  CardHeader,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Modal,
  Tooltip,
} from '@mui/material'

import {
  getOverduePayment,
  getOverduePaymentApi,
  getPendingPaymentApi,
  getTicketlist,
  getUserTicketlist,
  unReadandReadApi,
} from '../../../../services/configService'
import { SnackbarContext } from '../../../../context/Snackbar'

const ODD_OPACITY = 0.2

const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
  [`& .${gridClasses.row}.even`]: {
    backgroundColor: '#E3EFF7',
  },
  [`& .${gridClasses.row}.void`]: {
    backgroundColor: '#f123',
  },
  [`& .${gridClasses.row}.cancel`]: {
    backgroundColor: '#f123',
  },
  [`& .${gridClasses.row}.green`]: {
    animation: 'ripple 3s ease-in-out',
  },
  '@keyframes ripple': {
    '0%': {
      backgroundColor: 'lightgreen',
    },
    '100%': {
      backgroundColor: 'white',
    },
  },
  [`& .${gridClasses.row}`]: {
    '&:hover, &.Mui-hovered': {
      backgroundColor: alpha('#FFFF00', ODD_OPACITY),
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
  },
  [`& .${gridClasses.columnHeader}`]: {
    color: 'black',
    width: '100%',
    backgroundColor: '#D3D3D3',
  },
}))

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: '#61699f',
  // border: '2px solid #000',
  boxShadow: 24,
  p: 1,
  // color: 'skyblue',
}

export default function PaymentDueWidget({
  archived,
  setArchived,
  filterData,
  clearFilter,
}) {
  const navigate = useNavigate()
  const { state } = useLocation()
  const [data, setData] = React.useState([])

  // const handleOpen = () => setOpen(true);

  async function getData() {
    try {
      const res = await getPendingPaymentApi()
      if (res.status !== 204) {
        setData(res.data)
      }
    } catch (error) {
      console.log(error)
    }
  }

  React.useEffect(() => {
    console.log('filterData')

    getData()
  }, [])

  return (
    <Box
      sx={{ bgcolor: 'background.paper', borderRadius: 3, boxShadow: 2, p: 2 }}
    >
      <Typography
        variant='h6'
        sx={{ mb: 2, borderLeft: '4px solid #2979ff', pl: 1 }}
      >
        Pending payments for this weeks
      </Typography>
      <List>
        {data?.map((query, index) => (
          <ListItem
            key={index}
            sx={{
              border: '1px solid rgb(236, 236, 236)',
              alignItems: 'flex-start',
            }}
          >
            {/* Flight Information Section */}
            <ListItemText
              primary={
                <Typography
                  variant='subtitle1'
                  sx={{ fontWeight: 600, color: '#184E81' }}
                >
                  {query.query_code}
                </Typography>
              }
              secondary={
                <>
                  <Typography variant='body2' sx={{ color: 'text.primary' }}>
                    <span style={{ color: '#757575' }}>Agent: </span>
                    {query.agent_name}{' '}
                  </Typography>

                  <Grid container spacing={1} sx={{ mt: 1 }}>
                    <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
                      <Event sx={{ fontSize: 18, mr: 0.5, color: 'tomato' }} />
                      <Typography variant='body2' sx={{ color: 'tomato' }}>
                        Due Date: {dayjs(query.due_date).format('MMM D, YYYY')}
                      </Typography>
                    </Grid>
                  </Grid>
                </>
              }
            />

            {/* Flight Route and Dates Section */}
            <Grid
              container
              alignItems='center'
              spacing={2}
              justifyContent='flex-end'
              sx={{ flex: 1 }}
            >
              {/* Seat Availability */}
              <Grid item>
                <Chip
                  label={
                    <Box sx={{ display: 'flex', alignItems: 'center', p: 1 }}>
                      {/* <WarningAmber sx={{ color: 'error.main', mr: 0.5 }} /> */}
                      <Typography variant='subtitle2' color='info'>
                        Payment pending
                      </Typography>
                    </Box>
                  }
                  variant='outlined'
                  size='small'
                  color='info'
                  sx={{
                    // bgcolor: 'error.light',
                    borderRadius: 2,
                    px: 1,
                    mt: 1,
                  }}
                />
              </Grid>

              {/* Flight Route */}
              <Grid item sx={{ alignItems: 'center' }}>
                <Typography
                  variant='subtitle1'
                  sx={{
                    textDecoration: 'underline',
                    display: 'inline',
                    fontSize: '13.5px',
                  }}
                >
                  {query.amount_type === 1
                    ? 'Token Amount'
                    : query.amount_type === 2
                    ? 'Advance Amount'
                    : 'Final Amount'}
                  : ₹{query.receive_amount}
                </Typography>
              </Grid>

              <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
                <FlightTakeoff
                  sx={{ fontSize: 20, mr: 0.5, color: 'text.secondary' }}
                />
                <Typography variant='body2'>
                  {query.origin} ➔ {query.destination}
                </Typography>
              </Grid>
            </Grid>
          </ListItem>
        ))}
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
          <Button
            size='small'
            variant='outlined'
            color='primary'
            onClick={() => navigate('/pending-payment-logs', { state: data })}
          >
            View More
          </Button>
        </Box>
      </List>
    </Box>
  )
}
