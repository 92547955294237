import React, { useContext } from 'react'
import DashboardLayout from '../../../components/dashboard_layout/DashboardLayout'
import {
  Grid,
  InputLabel,
  FormControl,
  Button,
  Select,
  MenuItem,
  Autocomplete,
} from '@mui/material'
import {
  createOrganizationApi,
  deleteOrg,
  getReports,
  getToAndBccUser,
  updateCollaborator,
} from '../../../services/configService'
import dayjs from 'dayjs'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import {
  alpha,
  Box,
  gridClasses,
  Modal,
  styled,
  CircularProgress,
  TextField,
} from '@mui/material'
import Typography from '@mui/material/Typography'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import { min } from 'lodash'
import { ClearAllTwoTone, FilterAltTwoTone } from '@mui/icons-material'
// import Userticketlisting from './Userticketlisting'
const ODD_OPACITY = 0.2

const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
  [`& .${gridClasses.row}.even`]: {
    backgroundColor: '#E3EFF7',
  },
  [`& .${gridClasses.row}.void`]: {
    backgroundColor: '#f123',
  },
  [`& .${gridClasses.row}.cancel`]: {
    backgroundColor: '#f123',
  },
  [`& .${gridClasses.row}.green`]: {
    animation: 'ripple 3s ease-in-out',
  },
  '@keyframes ripple': {
    '0%': {
      backgroundColor: 'lightgreen',
    },
    '100%': {
      backgroundColor: 'white',
    },
  },
  [`& .${gridClasses.row}`]: {
    '&:hover, &.Mui-hovered': {
      backgroundColor: alpha('#FFFF00', ODD_OPACITY),
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
  },
  [`& .${gridClasses.columnHeader}`]: {
    color: 'black',
    width: '100%',
    backgroundColor: '#D3D3D3',
  },
}))

const ImapReports = () => {
  const [loader, setLoader] = React.useState(false)
  const [data, setData] = React.useState([])
  const [fromDate, setFromDate] = React.useState(
    dayjs(Date.now()).format('DD-MM-YYYY')
  )
  const [toDate, setToDate] = React.useState(
    dayjs(Date.now()).format('DD-MM-YYYY')
  )
  const [loading, setLoading] = React.useState(false)
  const [userEmailId, setUserEmailId] = React.useState('')
  const [filterData, setFilterData] = React.useState({
    from_date: dayjs(Date.now()).format('YYYY-MM-DD'),
    to_date: dayjs(Date.now()).format('YYYY-MM-DD'),
    email: '',
  })
  const [allemails, setAllEmails] = React.useState([])
  const [clearFilter, setClearFilter] = React.useState(false)
  const [inputEmail, setInputEmail] = React.useState('')

  const getReportsData = async (data) => {
    setLoader(true)
    try {
      const { from_date, to_date, email } = data
      const res = await getReports(from_date, to_date, email)
      if (
        res !== 204 &&
        res !== 406 &&
        Array.isArray(res.data) &&
        res.data.length > 0
      ) {
        setData(res.data)
        console.log('res.data', res.data)
        const emails = [
          ...new Set(res.data.map((item) => item.Email.toLowerCase())),
        ]
        setAllEmails(emails)
      } else {
        setData([])
        console.warn('No valid data received')
      }
    } catch (error) {
      console.error('Error in getReports:', error)
    } finally {
      setLoader(false)
    }
  }

  const handleFilterData = () => {
    setFilterData((prev) => {
      const updatedFilter = {
        ...prev,
        from_date: dayjs(fromDate, 'DD-MM-YYYY').format('YYYY-MM-DD'),
        to_date: dayjs(toDate, 'DD-MM-YYYY').format('YYYY-MM-DD'),
        email: userEmailId,
      }
      getReportsData(updatedFilter) // Pass updated filter data here to api function
      return updatedFilter
    })
    setClearFilter(false)
  }

  const handleClearFilterData = () => {
    const data = {
      from_date: dayjs(Date.now()).format('YYYY-MM-DD'),
      to_date: dayjs(Date.now()).format('YYYY-MM-DD'),
      email: '',
    }
    setFromDate(dayjs(Date.now()).format('DD-MM-YYYY'))
    setToDate(dayjs(Date.now()).format('DD-MM-YYYY'))
    setInputEmail('')
    setUserEmailId('')
    setAllEmails([])
    setFilterData(data)
    setClearFilter(true)
    getReportsData(data) // Call API function to get data
  }

  const handleUserInputChange = async (_, value) => {
    setInputEmail(value)
    if (!value || value.length < 3) {
      setUserEmailId('')
      return
    }
    if (
      allemails
        .map((email) => email.toLowerCase())
        .includes(value.toLowerCase())
    ) {
      setUserEmailId(value)
    }
  }

  //load data initially
  React.useEffect(() => {
    getReportsData(filterData)
  }, [])

  React.useEffect(() => {
    if (!fromDate || !toDate) {
      setFromDate(dayjs().format('DD-MM-YYYY'))
      setToDate(dayjs().format('DD-MM-YYYY'))
    } else if (
      dayjs(toDate, 'DD-MM-YYYY').isBefore(dayjs(fromDate, 'DD-MM-YYYY'))
    ) {
      setToDate(fromDate)
    }
  }, [fromDate, toDate]) // Only run when dates change

  const columns = [
    {
      field: 's_no',
      headerName: 'S no.',
      width: 50,
    },
    {
      field: 'Name',
      headerName: 'Name',
      flex: 1,
      minWidth: 100,
      renderCell: (params) => {
        return <p>{params.row.Name}</p>
      },
    },
    {
      field: 'Email',
      headerName: 'Recipient Email',
      flex: 1,
      minWidth: 250,
      renderCell: (params) => {
        return <p>{params.row.Email}</p>
      },
    },
    {
      field: 'Query_Code',
      headerName: 'Query Code',
      flex: 1,
      minWidth: 150,
      renderCell: (params) => {
        return <p>{params.row.Query_Code ? params.row.Query_Code : 'N.A.'}</p>
      },
    },
    {
      field: 'date_time',
      headerName: 'Mail Delivery Time',
      flex: 1,
      minWidth: 180,
      renderCell: (params) => {
        return (
          <p>
            {params.row.created
              ? dayjs(params.row.created).format('DD-MM-YYYY HH:mm:ss')
              : 'N.A.'}
          </p>
        )
      },
    },
    {
      field: 'log_status',
      headerName: 'Status',
      flex: 1,
      minWidth: 90,
      renderCell: (params) => {
        return <p>{params.row.status === 'M' ? 'Email' : 'CRM'}</p>
      },
    },
  ]

  return (
    <DashboardLayout heading='Mailing Report' pathName='Mailing Report'>
      <Box sx={{ height: '80vh', width: '100%' }}>
        <Modal
          open={loader}
          aria-labelledby='modal-modal-title'
          aria-describedby='modal-modal-description'
        >
          <div className='loader-container'>
            <div class='dot-spinner'>
              <div class='dot-spinner__dot'></div>
              <div class='dot-spinner__dot'></div>
              <div class='dot-spinner__dot'></div>
              <div class='dot-spinner__dot'></div>
              <div class='dot-spinner__dot'></div>
              <div class='dot-spinner__dot'></div>
              <div class='dot-spinner__dot'></div>
              <div class='dot-spinner__dot'></div>
            </div>
            <p
              style={{
                marginLeft: '10px',
                fontWeight: '500',
                marginTop: '10px',
              }}
            >
              Please wait!!
            </p>
          </div>
        </Modal>
        <div
          style={{ marginLeft: '15px', marginBottom: '10px', marginTop: '10px' }}
        >
          <Grid container spacing={2}>
            <Grid item md={2}>
              <div>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    slotProps={{
                      textField: { size: 'small', fullWidth: 'true' },
                    }}
                    format='DD-MM-YYYY'
                    label='From Date'
                    value={
                      fromDate
                        ? dayjs(fromDate, 'DD-MM-YYYY') // No need to format here
                        : null
                    }
                    onChange={(newDate) =>
                      setFromDate(
                        newDate
                          ? newDate.format('DD-MM-YYYY')
                          : dayjs(Date.now()).format('DD-MM-YYYY')
                      )
                    }
                    maxDate={toDate ? dayjs(toDate, 'DD-MM-YYYY') : null}
                  />
                </LocalizationProvider>
              </div>
            </Grid>
            <Grid item md={2}>
              <div>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    slotProps={{
                      textField: { size: 'small', fullWidth: 'true' },
                    }}
                    format='DD-MM-YYYY'
                    label='To Date'
                    value={
                      toDate
                        ? dayjs(toDate, 'DD-MM-YYYY') // No need to format here
                        : null
                    }
                    onChange={(newDate) =>
                      setToDate(
                        newDate
                          ? newDate.format('DD-MM-YYYY')
                          : dayjs(Date.now()).format('DD-MM-YYYY')
                      )
                    }
                    minDate={fromDate ? dayjs(fromDate, 'DD-MM-YYYY') : null}
                  />
                </LocalizationProvider>
              </div>
            </Grid>
            {/* <Grid item md={3}>
            <Autocomplete
              fullWidth
              size='small'
              // disabled={queryId ? tru : true}
              options={[...allemails]}
              inputValue={inputEmail}
              getOptionLabel={(option) => `${option}`}
              onInputChange={handleUserInputChange}
              clearOnEscape
              renderOption={(props, option) => (
                <Box
                  component='li'
                  sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                  {...props}
                >
                  <Typography variant='subtitle2' color='#3a87ad'>
                    {option?option:''}
                  </Typography>
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label='Search by Email'
                  variant='outlined'
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {loading ? (
                          <CircularProgress color='inherit' size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                />
              )}
            />
          </Grid> */}
            <Grid item md={3} sx={{ display: 'flex', gap: 1 }}>
              <Button
                variant='outlined'
                sx={{
                  fontWeight: '600',

                  // float: 'revert-layer',
                }}
                onClick={handleFilterData}
                startIcon={<FilterAltTwoTone />}
              >
                Filter
              </Button>

              <Button
                variant='outlined'
                color='error'
                sx={{
                  fontWeight: '600',

                  // float: 'revert-layer',
                }}
                onClick={handleClearFilterData}
                startIcon={<ClearAllTwoTone />}
              >
                Clear
              </Button>
            </Grid>
          </Grid>
        </div>
        <StripedDataGrid
          rows={data.map((item, index) => ({
            ...item,
            s_no: index + 1,
            log_status: item?.status === 'M' ? 'Email' : 'CRM',
          }))}
          getRowId={(row) => row.s_no}
          columns={columns}
          // initialState={{
          //   pagination: { paginationModel: { pageSize: 50, page: 0 } },
          // }}
          // rowCount={archived === true ? dataCloseCount : dataOpenCount} // Define the total number of rows
          // paginationMode='server' // Enables controlled pagination
          // onPageChange={(page) => {
          //   setPagination(page)
          //   // setFliterDataPagination(page)
          // }}
          // onPaginationModelChange={(params) => {
          //   setPagination(params.page)
          //   // setFliterDataPagination(params.page)
          // }}
          // pageSizeOptions={[10, 20, 50]} // Set the available page size options
          disableColumnFilter
          disableColumnSelector
          disableDensitySelector
          slots={{ toolbar: GridToolbar }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
            },
          }}
          getRowClassName={(params) =>
            params.row.is_mapped == 1
              ? 'void'
              : params.indexRelativeToCurrentPage % 2 === 0
              ? 'even'
              : 'odd'
          }
          disableRowSelectionOnClick
        />
        {/* <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={style}>
          <Makepaymentsuccess paymentdetails={paymentdetails} />
        </Box>
      </Modal> */}
      </Box>
    </DashboardLayout>
  )
}

export default ImapReports
