import * as React from 'react'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Checkbox from '@mui/material/Checkbox'
import Card from '@mui/material/Card'
import FormControlLabel from '@mui/material/FormControlLabel'
// import Checkbox from '@mui/material/Checkbox'
import Button from '@mui/material/Button'
import { DataGrid, GridToolbar, gridClasses } from '@mui/x-data-grid'
import dayjs from 'dayjs'
import { alpha, styled } from '@mui/material/styles'

import {
  Cancel,
  Close,
  ContentCopy,
  DeleteOutlineTwoTone,
  DeleteSweepTwoTone,
  Description,
  Edit,
  History,
  Payment,
  Send,
  Update,
} from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'
import { CardContent, Chip, Tooltip } from '@mui/material'
import { Modal } from '@mui/material'
import { SnackbarContext } from '../../../../context/Snackbar'
import {
  createTermandCondition,
  deletePaymentLinkApi,
  deleteTermandCondition,
  getAllPaymentLinks,
  getEmailTemplate,
  getEmployeeDetails,
  getTermsandcondition,
  updateEmailTemplate,
  updateTermandCondition,
} from '../../../../services/configService'
import TableMenu from '../../../../components/table_modal/TableMenu'
import Deletecard from '../../../../components/card/Deletecard'
import Reactquilleditor from '../../../../components/Text Editor/Reactquilleditor'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import Field from '../../../../components/molecules/field'
import FormLinker from 'form-linker'
import english from '../../../../components/utils/translations/en'

import Translator from 'simple-translator'
import {
  globalStyle,
  theme as theme2,
} from '../../../../components/utils/theme.config'

import { Global, ThemeProvider as Theme2 } from '@emotion/react'
import { config } from '@fortawesome/fontawesome-svg-core'
import '@fortawesome/fontawesome-svg-core/styles.css'
import iconLibrary from '../../../../components/utils/iconLIbrary'
import Ckeditor from '../../../../components/texteditor/MainTextEditor'
import useAuth from '../../../../hooks/useAuth'
// import iconLibrary from '../../../components/utils/iconLIbrary'
config.autoAddCss = false
const ODD_OPACITY = 0.2

const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
  [`& .${gridClasses.row}.even`]: {
    backgroundColor: '#E3EFF7',
  },
  [`& .${gridClasses.row}.void`]: {
    backgroundColor: '#f123',
  },
  [`& .${gridClasses.row}.cancel`]: {
    backgroundColor: '#f123',
  },
  [`& .${gridClasses.row}.green`]: {
    animation: 'ripple 3s ease-in-out',
  },
  '@keyframes ripple': {
    '0%': {
      backgroundColor: 'lightgreen',
    },
    '100%': {
      backgroundColor: 'white',
    },
  },
  [`& .${gridClasses.row}`]: {
    '&:hover, &.Mui-hovered': {
      backgroundColor: alpha('#FFFF00', ODD_OPACITY),
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
  },
  [`& .${gridClasses.columnHeader}`]: {
    color: 'black',
    width: '100%',
    backgroundColor: '#D3D3D3',
  },
}))

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 800,
  bgcolor: 'background.paper',
  // border: '2px solid #000',
  boxShadow: 24,
  p: 2,
  maxHeight: '70vh', // Set maximum height for the card
  overflowY: 'auto',
}

const MakeTemples = ({ render, setRender, open, setOpen, setCreatebtn }) => {
  iconLibrary()
  Translator.registerDefaultLanguage('en', english)
  const navigate = useNavigate()
  const {auth} = useAuth()
  const [data, setData] = React.useState([])
  const { setOn, setSeverity, setMes } = React.useContext(SnackbarContext)
  const [deleteRow, setDeleteRow] = React.useState(false)
  const [editRights, setEditRights] = React.useState(false)
  const [isChecked, setIsChecked] = React.useState([])
  const [selectedDltId, setSelectedDltId] = React.useState('')
  const [error, setError] = React.useState('')
  const [editable, SetEditable] = React.useState(false)
  const [mappedRowData, setMappedRowdata] = React.useState([])
  const [paymentdetails, setPaymentdetails] = React.useState([])
  const [loader, setLoader] = React.useState(false)
  const [copiedRow, setCopiedRow] = React.useState(null)
  //   const [open, setOpen] = React.useState(false)
  const [updatedRowData, setUpdatedRowData] = React.useState(null)
  const [editorHtml, setEditorHtml] = React.useState('')
  const [type, setType] = React.useState('')
  const [reset, setReset] = React.useState('')
  const [desopen, setDesopen] = React.useState(false)
  const [description, setDescription] = React.useState('')
  // const [editorHtml, setEditorHtml] = React.useState('')

  const [_, forceUpdate] = React.useState(0)

  const demoContent = ``

  const formLinker = React.useRef(
    new FormLinker({
      data: {
        editor: demoContent,
      },
      schema: {
        editor: 'string',
      },
    })
  )
  const handleOpenModal = (description) => {
    setDescription(description)
    setDesopen(true)
  }

  const handleCloseDesModal = () => {
    setDesopen(false)
    setDescription('')
  }

  const handleChange = (event) => {
    setType(event.target.value)
  }

  const handleOpen = (data) => {
    setOpen(true)
    setUpdatedRowData(data)
  }

  const handleTextChange = (html) => {
    setEditorHtml(html)
  }

  const handleCloseModal = () => {
    setUpdatedRowData(null)
    setOpen(false)
    setEditorHtml('')
    setType('')
  }

  async function getData() {
    try {
      const res = await getEmailTemplate(1, 1)
      if (res !== 406 && res !== 204) {
        setData(res?.data?.data)
        // console.log('res.data', res.data)
      } else {
        setData([])
      }
    } catch (error) {
      console.log(error)
    }
  }

  //   const handleCheckboxChange = (event, row) => {
  //     setIsChecked((prev) => {
  //       let newChecked = [...prev]

  //       if (event.target.checked) {
  //         const newObject = {
  //           ...row,
  //           ded_amt: row.ded_amt ? row.ded_amt : 0,
  //           amount: row.balance_amount,
  //           tds_amt: row.paid_tds_amt ? row.paid_tds_amt : row.tds_amt,
  //           payment_type: row.amount_type,
  //         }
  //         newChecked.push(newObject)
  //       } else {
  //         newChecked = newChecked.filter((item) => item.p_id !== row.p_id)
  //       }

  //       return newChecked
  //     })
  //     SetEditable(true)
  //   }
  const handleDeleteModal = (row) => {
    setSelectedDltId(row.id)
    setDeleteRow(true)
  }
  const handledeleteModalClose = () => setDeleteRow(false)

  const handleCreate = async (row) => {
    if (editorHtml === '') {
      setSeverity('warning')
      setMes('Please enter description')
      setOn(true)
      return
    } else if (type === '') {
      setSeverity('warning')
      setMes('Please select type')
      setOn(true)
      return
    }
    const Data = {
      description: editorHtml,
      type: type,
    }
    try {
      const res = await createTermandCondition(Data)
      if (res !== 204 && res !== 406) {
        setSeverity('success')
        setMes('Created Successfully')
        setOn(true)
        getData()
        setOpen(false)
        setEditorHtml('')
        setType('')
      }
    } catch (error) {
      setSeverity('warning')
      setMes('error')
      setOn(true)
    }
  }

  const handleUpdate = async (row) => {
    if (editorHtml === '') {
      setSeverity('warning')
      setMes('Please Create Template')
      setOn(true)
      return
    }

    const Data = {
      id: updatedRowData.id,
      category: updatedRowData.category,
      sub_category: updatedRowData.sub_category,
      //   reset_users_tnc: reset,
      html_format: editorHtml?.replaceAll('<table', '<table border="1" '),
      //   type: type,
    }
    try {
      const res = await updateEmailTemplate(updatedRowData.id, Data)
      if (res !== 204 && res !== 406) {
        setSeverity('success')
        setMes('Template Updated Successfully')
        setOn(true)
        getData()
        setOpen(false)
        setUpdatedRowData(null)
      }
    } catch (error) {
      setSeverity('warning')
      setMes('error')
      setOn(true)
    }
  }
  const handleDeleteRow = async (row) => {
    try {
      const res = await deleteTermandCondition(selectedDltId)
      if (res !== 406) {
        setSeverity('success')
        setMes('Deleted Successfully')
        setOn(true)
        getData()
        handledeleteModalClose()
      }
    } catch (error) {
      setSeverity('warning')
      setMes('error')
      setOn(true)
    }
  }
  const handleLinkClick = (data) => {
    console.log('hello', data)
    navigate('/imap-threads', { state: data })
  }

  React.useEffect(() => {
    getData()
  }, [])

  React.useEffect(() => {
    const fetchEditRights = async () => {
      if (auth && auth?.role === 1) {
        setEditRights(true);
      } else if (auth && auth?.role === 3) {
        // fetch data edit permission
        try {
          const res=await getEmployeeDetails(auth?.id, setError);
          if (res !== 406 && res !== 204) {
            setEditRights(res?.data?.template_edit_rights === 1 ? true : false);
          }
        } catch (err) {
          console.error(err);
        }
      }
    };
    fetchEditRights();
  },[auth])

  React.useEffect(() => {
    const disable = data.length
    setCreatebtn(disable)
  }, [data])

  React.useEffect(() => {
    if (render === true) {
      getData()
      setRender(false)
    }
  }, [render])

  React.useEffect(() => {
    if (updatedRowData) {
      setEditorHtml(updatedRowData.html_format)
    }
  }, [updatedRowData])

  const columns = [
    // {
    //   field: 'actions',
    //   type: 'actions',
    //   headerName: '',
    //   width: 75,
    //   flex: 0.5,
    //   cellClassName: 'actions',
    //   renderCell: (params) => {
    //     return (
    //       <Checkbox
    //         // checked={isChecked[params.row.p_id]}
    //         onChange={(event) => handleCheckboxChange(event, params.row)}
    //       />
    //     )
    //   },
    // },
    {
      field: 's_no',
      headerName: 'S.No',

      width: 60,
    },
    {
      field: 'template_name',
      headerName: 'Template Name',
      flex: 1,
      minWidth: 190,
      renderCell: (params) => {
        return (
          <p style={{ fontWeight: 500 }}>
            {params.row?.template_name ? params.row?.template_name : 'N.A'}
          </p>
        )
      },
    },
    {
      field: 'created_at',
      headerName: 'Created Date',
      flex: 1,
      minWidth: 190,
      renderCell: (params) => {
        return (
          <p style={{ fontWeight: 500 }}>
            {params.row?.created_at
              ? dayjs(params.row?.created_at).format('DD-MM-YYYY')
              : 'N.A'}
          </p>
        )
      },
    },

    {
      field: 'updated_at',
      headerName: 'Last Updated Date',
      flex: 1,
      minWidth: 190,
      renderCell: (params) => {
        return (
          <p style={{ fontWeight: 500 }}>
            {params.row?.updated_at
              ? dayjs(params.row?.updated_at).format('DD-MM-YYYY')
              : dayjs(params.row?.created_date).format('DD-MM-YYYY')}
          </p>
        )
      },
    },

    {
      field: 'type',
      headerName: 'Type',
      disableColumnMenu: 'true',
      minWidth: 125,
      flex: 1,
      renderCell: (params) => {
        const color =
          params.row?.type == 1
            ? '#90c9ee'
            : params.row.type == 2
            ? '#3CB371'
            : params.row.type == 'PAID'
            ? '#3CB371'
            : params.row.type == 'PENDING'
            ? '#FAFAD2'
            : params.row.type === 7
            ? 'secondary'
            : 'error'
        return params.row?.type !== 'undefined' ? (
          <Chip
            label='Email'
            sx={{ background: '#90c9ee', color: 'white', fontWeight: 500 }}
            variant='filled'
          />
        ) : (
          'N.A'
        )
      },
    },
    {
      field: 'email_template',
      headerName: 'Email Template',
      flex: 1,
      minWidth: 180,
      renderCell: (params) => {
        const maxWords = 12

        // Split the amount_type into an array of words
        const words = params.row?.description?.split(' ')

        // If the word count exceeds the limit, truncate the text and add ellipsis
        const truncatedText =
          words?.length > maxWords
            ? words?.slice(0, maxWords).join(' ') + '...'
            : params.row?.description
        return (
          <Button
            variant='text'
            onClick={() => handleOpenModal(params.row.html_format)}
            style={{ textDecoration: 'underline', color: '#007BFF' }}
            startIcon={<Description />}
          >
            View Template
          </Button>
        )
      },
    },

    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 140,
      cellClassName: 'actions',
      getActions: ({ row }) => {
        return [
          <>
            <TableMenu
              data={[
                {
                  id: 1,
                  title: 'Update',
                  icon: <Edit fontSize='small' color='info' />,
                  disable: !editRights ,
                  //   disable: row?.link_status !== 'ACTIVE' ? true : false,
                  fn: () => handleOpen(row),
                },
                // {
                //   id: 2,
                //   title: 'Delete',
                //   icon: <DeleteOutlineTwoTone fontSize='small' color='error' />,
                //   //   disable: row?.link_status !== 'ACTIVE' ? true : false,
                //   fn: () => handleDeleteModal(row),
                // },
                //view edit logs
                {
                  id: 3,
                  title: 'View Edit Logs',
                  icon: <History fontSize='small' color='info' />,
                  disable: !editRights ,
                  fn: () => navigate(`/email-template-logs`, { state: { template: row } }),
                },
              ]}
            />
          </>,
        ]
      },
    },
  ]

  const isDisabled = (menuItemValue) => {
    return data?.some((item) => item.type === menuItemValue)
  }

  React.useEffect(() => {
    // console.log('useEffect is running')
    if (updatedRowData === null) {
      setEditorHtml('') // Trigger re-render
    }
  }, [updatedRowData])

  return (
    <Theme2 theme={theme2}>
      <Global styles={globalStyle} />
      <Box sx={{ height: '80vh', width: '100%' }}>
        <StripedDataGrid
          rows={data?.map((item, index) => ({
            ...item,
            s_no: index + 1,
          }))}
          getRowId={(row) => row.id}
          columns={columns}
          disableColumnFilter
          disableColumnSelector
          disableDensitySelector
          slots={{ toolbar: GridToolbar }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
            },
          }}
          getRowClassName={(params) =>
            params.row.is_mapped == 1
              ? 'void'
              : params.indexRelativeToCurrentPage % 2 === 0
              ? 'even'
              : 'odd'
          }
          disableRowSelectionOnClick
        />
        <Modal
          open={deleteRow}
          onClose={handleDeleteModal}
          aria-labelledby='modal-modal-title'
          aria-describedby='modal-modal-description'
        >
          <Deletecard
            btnText={'Delete'}
            onClickBack={handledeleteModalClose}
            onClickSubmit={handleDeleteRow}
            subheading={'Delete this Term & Condition?'}
          />
        </Modal>
        <Modal
          open={open}
          onClose={handleCloseModal}
          aria-labelledby='modal-modal-title'
          aria-describedby='modal-modal-description'
        >
          <Card sx={style}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginBottom: 8,
              }}
            >
              <Typography
                id='modal-modal-title'
                variant='h6'
                // component='h2'
                color={'#184E81'}
                fontWeight={600}
                sx={{ textDecoration: 'underline' }}
              >
                {updatedRowData === null
                  ? 'Create Email Template'
                  : 'Update Email Template'}
              </Typography>
              {/* <FormControlLabel
              control={
                <Checkbox
                  color='success'
                  checked={reset === 1}
                  onChange={(e) => setReset(e.target.checked ? 1 : 0)}
                  disabled={updatedRowData === null}
                  size='small'
                />
              }
              label='Reset users tnc'
            /> */}
            </div>
            {/* <FormControl sx={{ width: '30%', mb: 1 }} size='small'>
            <InputLabel id='demo-simple-select-label'>Type</InputLabel>
            <Select
              labelId='demo-simple-select-label'
              id='demo-simple-select'
              value={type}
              size='small'
              label='Type'
              onChange={handleChange}
              disabled={updatedRowData !== null ? true : false}
            >
              <MenuItem value={1} disabled={isDisabled(1)}>
                FIT
              </MenuItem>
              <MenuItem value={2} disabled={isDisabled(2)}>
                GIT
              </MenuItem>
            </Select>
          </FormControl> */}

            <CardContent>
              {/* <Reactquilleditor
              theme='snow'
              placeholder='Write your reply here...'
              setEditorHtml={setEditorHtml}
              editorHtml={editorHtml}
              handleChange={handleTextChange}
            /> */}
              {/* <Field
                formLinker={formLinker.current}
                name='editor'
                type='editor'
                minHeight={150}
                height={620}
                maxHeight={800}
                placeholder='Enter your content here'
                toolbar={['withImages']}
                onChange={forceUpdate}
              /> */}
             
                <Ckeditor value={editorHtml} onChange={setEditorHtml} />
              
            </CardContent>
            <Button
              variant='outlined'
              //   disabled={type === '' ? true : false}
              onClick={updatedRowData === null ? handleCreate : handleUpdate}
              sx={{ mt: 2, float: 'right' }}
            >
              {updatedRowData === null ? 'Create' : 'Update'}
            </Button>
          </Card>
        </Modal>
        <Modal open={desopen} onClose={handleCloseDesModal}>
          <Card
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 750,
              backgroundColor: 'white',
              padding: '16px',
              boxShadow: 24,
              borderRadius: '4px',
              maxHeight: '68vh', // Set maximum height for the card
              overflowY: 'auto',
            }}
          >
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <h2 style={{ textDecoration: 'underline' }}>Email Template</h2>
              <Button
                onClick={handleCloseDesModal}
                variant='text'
                color='inherit'
                startIcon={<Cancel />}
              ></Button>
            </div>
            <CardContent sx={{ background: '#e8e8e8', marginTop: 1 }}>
              <p
                dangerouslySetInnerHTML={{
                  __html: description,
                }}
              />
            </CardContent>
          </Card>
        </Modal>
      </Box>
    </Theme2>
  )
}

export default MakeTemples
