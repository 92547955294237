import React, { useContext } from 'react'
import DashboardLayout from '../../../../components/dashboard_layout/DashboardLayout'
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { ClearAllTwoTone, FilterAltTwoTone } from '@mui/icons-material'
import dayjs from 'dayjs'
import {
  apiSearchAgent,
  appendSearchQueryCode,
  getToAndBccUser,
} from '../../../../services/configService'
import { SnackbarContext } from '../../../../context/Snackbar'
import ReadyForTicketingList from './ReadyForTicketingList'
import isoWeek from 'dayjs/plugin/isoWeek'

dayjs.extend(isoWeek)

const ReadyForTicketing = () => {
  const { setOn, setSeverity, setMes } = useContext(SnackbarContext)
  const [loading, setLoading] = React.useState(false)
  const [filterData, setFilterData] = React.useState({})
  const [fromDate, setFromDate] = React.useState('')
  const [toDate, setToDate] = React.useState('')
  const [mailType, setMailType] = React.useState('')
  const [userEmailId, setUserEmailId] = React.useState('')
  const [clearFilter, setClearFilter] = React.useState(false)
  const [error, setError] = React.useState('')
  const [options, setOptions] = React.useState([])
  const [stateData, setStateData] = React.useState([])
  const [inputValue, setInputValue] = React.useState('')
  const [queryId, setQueryId] = React.useState('')

  const searchQuerycode = async (change) => {
    const data = {
      search_text: change,
    }

    try {
      const res = await apiSearchAgent(data)

      if (res !== 204 && res !== 406) {
        console.log('res', res.data)

        setOptions(res.data)
      } else {
        setOptions([])
      }
    } catch (error) {
      setSeverity('warning')
      setMes('')
      setOn(true)
      // console.log('error in generate quote ', error)
    } finally {
    }
  }
  const handleInputChange = async (_, value) => {
    setInputValue(value)

    const checkedemail = value?.match(
      /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b/
    )?.[0]

    if (value.length > 2) {
      // Fetch suggestions when input has 3+ chars
      setLoading(true)
      const results = await searchQuerycode(value)
      // setOptions(results || []) // Update options with fetched data
      setLoading(false)
    } else {
      setOptions([]) // Clear options for shorter input
    }
    const x = options.find((a) => a.email_id === checkedemail)

    setQueryId(x?.auth_id)
  }

  const handleFilterData = () => {
    const data = {
      from_date: fromDate
        ? dayjs(fromDate, 'DD-MM-YYYY').format('YYYY-MM-DD')
        : '',
      to_date: toDate ? dayjs(toDate, 'DD-MM-YYYY').format('YYYY-MM-DD') : '',
      agent_id: queryId,
      sector: mailType,
    }

    setFilterData(data)
    setClearFilter(true)
  }
  const handleClearFilterData = () => {
    const data = {
      from_date: '',
      to_date: '',
      agent_id: '',
      sector: '',
    }

    setFilterData(data)
    setFromDate('')
    setToDate('')
    setQueryId('')
    setMailType('')
    setClearFilter(false)
  }

  console.log('stateData', stateData)
  React.useEffect(() => {
    if (stateData?.length > 0) {
      const currentDate = dayjs() // Current date
      const endOfWeek = dayjs().endOf('isoWeek') // End of the current week (Sunday)

      setFromDate(currentDate.format('DD-MM-YYYY'))
      setToDate(endOfWeek.format('DD-MM-YYYY'))

      const data = {
        from_date: currentDate.format('YYYY-MM-DD'),
        to_date: endOfWeek.format('YYYY-MM-DD'),
        agent_id: '',
        sector: '',
      }
      setFilterData(data)

      // Call handleFilterData after setting the dates
      // Use a timeout to ensure state updates complete before the function call
    }
  }, [stateData])

  return (
    <DashboardLayout
      heading="Bookings Ready For Ticketing"
      pathName="Bookings Ready For Ticketing"
    >
      <div
        style={{ marginLeft: '15px', marginBottom: '10px', marginTop: '5px' }}
      >
        <Grid container spacing={2}>
          <Grid item md={2}>
            <div>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  slotProps={{
                    textField: { size: 'small', fullWidth: 'true' },
                  }}
                  format='DD-MM-YYYY'
                  label='From Date'
                  value={
                    fromDate
                      ? dayjs(fromDate, 'DD-MM-YYYY') // No need to format here
                      : null
                  }
                  onChange={(newDate) =>
                    setFromDate(newDate ? newDate.format('DD-MM-YYYY') : null)
                  }
                  // disablePast
                  disableFuture
                />
              </LocalizationProvider>
            </div>
          </Grid>
          <Grid item md={2}>
            <div>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  slotProps={{
                    textField: { size: 'small', fullWidth: 'true' },
                  }}
                  format='DD-MM-YYYY'
                  label='To Date'
                  value={
                    toDate
                      ? dayjs(toDate, 'DD-MM-YYYY') // No need to format here
                      : null
                  }
                  onChange={(newDate) =>
                    setToDate(newDate ? newDate.format('DD-MM-YYYY') : null)
                  }
                  // disablePast
                  disableFuture
                  minDate={fromDate ? dayjs(fromDate, 'DD-MM-YYYY') : null}
                />
              </LocalizationProvider>
            </div>
          </Grid>
          {/* <Grid item md={2}>
            <FormControl size='small' fullWidth>
              <InputLabel id='demo-simple-select-label'>Sector</InputLabel>
              <Select
                size='small'
                labelId='demo-simple-select-label'
                id='demo-simple-select'
                value={mailType}
                label='Sector'
                onChange={(e) => setMailType(e.target.value)}
              >
                <MenuItem value={824}>DEL</MenuItem>
                <MenuItem value={418}>BOM</MenuItem>
                <MenuItem value={244}>GYD</MenuItem>
              </Select>
            </FormControl>
          </Grid> */}
          <Grid item md={3}>
            <Autocomplete
              size='small'
              // disabled={queryId ? tru : true}
              options={options}
              getOptionLabel={(option) =>
                `${option.first_name + ' ' + option.last_name} (${
                  option.email_id
                })`
              }
              onInputChange={handleInputChange}
              renderOption={(props, option) => (
                <Box
                  component='li'
                  sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                  {...props}
                >
                  <Typography variant='subtitle2' color='initial'>
                    {option.first_name + ' ' + option.last_name}
                  </Typography>{' '}
                  &nbsp;
                  <Typography variant='subtitle2' color='#3a87ad'>
                    ({option.email_id})
                  </Typography>
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label='Search by Email or Name'
                  variant='outlined'
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {loading ? (
                          <CircularProgress color='inherit' size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                />
              )}
            />
          </Grid>
          <Grid item md={3} sx={{ display: 'flex', gap: 1 }}>
            <Button
              variant='outlined'
              sx={{
                fontWeight: '600',

                // float: 'revert-layer',
              }}
              onClick={handleFilterData}
              startIcon={<FilterAltTwoTone />}
            >
              Filter
            </Button>

            <Button
              variant='outlined'
              color='error'
              sx={{
                fontWeight: '600',

                // float: 'revert-layer',
              }}
              onClick={handleClearFilterData}
              startIcon={<ClearAllTwoTone />}
            >
              Clear
            </Button>
          </Grid>
        </Grid>
      </div>
      <Box sx={{ width: '100%' }}>
        <ReadyForTicketingList
          filterData={filterData}
          // setFilterData={setFilterData}
          clearFilter={clearFilter}
          setStateData={setStateData}
        />
      </Box>
    </DashboardLayout>
  )
}

export default ReadyForTicketing;
