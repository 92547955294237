import * as React from 'react'
import dayjs from 'dayjs'
import Badge from '@mui/material/Badge'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { PickersDay } from '@mui/x-date-pickers/PickersDay'
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar'
import { DayCalendarSkeleton } from '@mui/x-date-pickers/DayCalendarSkeleton'
import {
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  FormGroup,
  Card,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  IconButton,
  Button,
  CircularProgress,
  Autocomplete,
  Box,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
  Divider,
} from '@mui/material'
import CardContent from '@mui/material/CardContent'
import { TextField } from '@mui/material'
import {
  Add,
  AddToPhotosTwoTone,
  AirplanemodeInactive,
  ArrowBack,
  ArrowCircleLeft,
  BackHand,
  CheckBox,
  CheckBoxOutlineBlank,
  CloudCircleTwoTone,
  Delete,
  DeleteSweepTwoTone,
  EditTwoTone,
  ViewAgenda,
  ViewHeadlineTwoTone,
} from '@mui/icons-material'
import Popover from '@mui/material/Popover'
import Typography from '@mui/material/Typography'
import {
  getEventListsApi,
  getFlightListApi,
} from '../../services/admin.configService'
import {
  appendSearchQueryCode,
  availableStatus,
  createSeatAvailability,
  getSeatRenderAvailability,
  getSeatcategorylist,
  postMailSchedular,
  postUpdateseatrateandavailability,
  schedularEventDelete,
} from '../../services/configService'
import DashboardLayout from '../dashboard_layout/DashboardLayout'
import { SnackbarContext } from '../../context/Snackbar'
import Modal from '@mui/material/Modal'
import { DatePicker, DateTimePicker, TimePicker } from '@mui/x-date-pickers'
import CustomTextArea from '../common/CustomTextArea'
import { set } from 'lodash'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'

dayjs.extend(isSameOrAfter)

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  // border: '2px solid #000',
  boxShadow: 24,
  p: 0.5,
}

function getRandomNumber(min, max) {
  return Math.round(Math.random() * (max - min) + min)
}

function fakeFetch(date, { signal }) {
  return new Promise((resolve, reject) => {
    const timeout = setTimeout(() => {
      const daysInMonth = date.daysInMonth()
      const daysToHighlight = [1, 2, 3].map(() =>
        getRandomNumber(1, daysInMonth)
      )

      resolve({ daysToHighlight })
    }, 500)

    signal.onabort = () => {
      clearTimeout(timeout)
      reject(new DOMException('aborted', 'AbortError'))
    }
  })
}
const date = new Date()
date.toLocaleDateString()
const initialValue = dayjs(date)

const flightless = ['Economy', 'Business']

export default function SchedularCalendarServerRequest() {
  const { setOn, setSeverity, setMes } = React.useContext(SnackbarContext)
  const requestAbortController = React.useRef(null)
  const [isLoading, setIsLoading] = React.useState(false)
  const [dataLoader, setDataLoader] = React.useState(false)
  const [highlightedDays, setHighlightedDays] = React.useState([1, 2, 15])

  const [error, setError] = React.useState({})

  const [anchorEl, setAnchorEl] = React.useState(null)
  const [isChecked, setIsChecked] = React.useState(false)
  const [editdate, setEditdate] = React.useState('')
  const [selectedDate, setSelectedDate] = React.useState('') // Default to current date
  const [value, setValue] = React.useState(dayjs())
  const [endDate, setEndDate] = React.useState(dayjs())
  const [filterDate, setFilterDate] = React.useState(dayjs())
  const [flight, setFlight] = React.useState({
    available: 1,
    price: 17000,
  })
  const [infantPrice, setInfantPrice] = React.useState('')
  const [remarks, setRemarks] = React.useState('')
  const [checked, setChecked] = React.useState(false)
  const [autoChecked, setAutoChecked] = React.useState(false)
  const [options, setOptions] = React.useState([])
  const [loading, setLoading] = React.useState(false)
  const [inputValue, setInputValue] = React.useState('')
  const [queryId, setQueryId] = React.useState('')
  const [title, setTitle] = React.useState('')
  const [description, setDescription] = React.useState('')
  const [queryCode, setQueryCode] = React.useState('')
  const [eventList, setEventList] = React.useState([])

  const open = Boolean(anchorEl)

  const id = open ? editdate : undefined

  const handleClose = () => {
    setAnchorEl(null)
    setValue(dayjs())
    setEndDate(dayjs())
    setTitle('')
    setDescription('')
    setQueryId('')
    setChecked(false)
    setQueryCode('')
    setAutoChecked(false)
  }
  const handleChange = (event) => {
    setChecked(event.target.checked)
  }
  const handleChangeAutochange = () => {
    setAutoChecked((pre) => !pre)
  }

  const getEvents = async () => {
    try {
      const res = await getEventListsApi()
      if (res !== 204 && res !== 401 && res !== 406) {
        setEventList(res?.data)
      }
    } catch (error) {
      // console.log('error in getEvent ', error)
    }
  }

  const editEvents = async (data) => {
    const date = dayjs(data.cron_date).format('YYYY-MM-DD')

    const datePart = data?.cron_date?.split('T')[0]
    const formattedCronDate = `${datePart}T${data?.cron_time}`
    console.log('formattedCronDate', formattedCronDate)
    const start = dayjs(formattedCronDate).format('YYYY-MM-DD hh:mm A')
    const end = dayjs(data?.cron_time_to).format('YYYY-MM-DD')
    setValue(dayjs(start))

    setEndDate(dayjs(end))
    setSelectedDate(date)
    setTitle(data?.subject)
    setDescription(data?.body_remark)
    setQueryId(data?.query_id)
    setQueryCode(data?.query_code)
    setChecked(data?.multiple_days === 1 ? true : false)
    setAutoChecked(true)
    setAnchorEl(true)
  }
  const deleteEvents = async (id) => {
    try {
      const res = await schedularEventDelete(id)
      if (res !== 204 && res !== 401 && res !== 406) {
        setSeverity('success')
        setMes('Event Deleted Successfully')
        setOn(true)
        getEvents()
      }
    } catch (error) {
      console.log('error in getEvent ', error)
    }
  }

  const handleInputChange = async (_, value) => {
    setInputValue(value)
    const query_code = value?.split(' ')[0]
    const regex = /\(([^)]+)\)/ // Matches content inside parentheses
    const match = value.match(regex)
    const email = match ? match[1] : ''

    if (value.length > 2) {
      // Fetch suggestions when input has 3+ chars
      setLoading(true)
      const results = await searchQuerycode(value)
      // setOptions(results || []) // Update options with fetched data
      setLoading(false)
    } else {
      setOptions([]) // Clear options for shorter input
    }
    const x = options.find(
      (a) => a.email === email || a.query_code === query_code
    )
    setQueryId(x?.query_id)
  }

  const searchQuerycode = async (change) => {
    const data = {
      search_text: change,
    }

    try {
      const res = await appendSearchQueryCode(data, setError)

      if (res !== 204 && res !== 406) {
        setOptions(res.data)
      } else {
        setOptions([])
      }
    } catch (error) {
      setSeverity('warning')
      setMes('')
      setOn(true)
      // console.log('error in generate quote ', error)
    } finally {
    }
  }

  const fetchHighlightedDays = (date) => {
    const controller = new AbortController()
    fakeFetch(date, {
      signal: controller.signal,
    })
      .then(({ daysToHighlight }) => {
        setHighlightedDays(daysToHighlight)
        setIsLoading(false)
      })
      .catch((error) => {
        if (error.name !== 'AbortError') {
          throw error
        }
      })

    requestAbortController.current = controller
  }

  function ServerDay(props) {
    const {
      highlightedDays = [],
      day,
      minDate,
      outsideCurrentMonth,
      ...other
    } = props

    const handleClick = async (event) => {
      setDataLoader(true)
      const date = dayjs(day).format('YYYY-MM-DD')
      setValue(dayjs(date))
      setEndDate(dayjs(date))
      setSelectedDate(date)

      setAnchorEl(event.currentTarget)
    }

    const selectmindate = dayjs() // Current date and time

    const isTodayOrFuture = dayjs(day).isSameOrAfter(selectmindate, 'day') // Check if today or in the future

    const selectOutsideCurrentMonth = outsideCurrentMonth ? true : false

    return (
      <Badge
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        key={props.day.toString()}
        overlap='circular'
        badgeContent={
          !selectOutsideCurrentMonth && isTodayOrFuture ? (
            <>
              <div
                style={{
                  display: 'flex',
                  marginLeft: '10px',
                  marginTop: '3px',
                }}
              >
                <IconButton
                  sx={{ paddingTop: 2 }}
                  color='success'
                  size='large'
                  aria-describedby={id}
                  onClick={handleClick}
                >
                  <AddToPhotosTwoTone />
                </IconButton>
              </div>
            </>
          ) : undefined
        }
      >
        <PickersDay
          {...other}
          outsideCurrentMonth={outsideCurrentMonth}
          day={day}
          onClick={(e, v) => console.log({ ...e })}
          className={`abc ${outsideCurrentMonth && 'disabled'}`}
          style={{
            padding: '35px', // Adjust day width as needed
            fontSize: '18px', // Adjust font size as needed
            borderRadius: 0,
            // border: outsideCurrentMonth &&  '1px solid #ccc', // Apply grey border for days outside current month
            background: `${isChecked ? 'rgb(255 172 176)' : ''}`,
          }}
        />
      </Badge>
    )
  }

  const handleSaveFormData = async () => {
    if (
      title === '' ||
      description === '' ||
      queryId === '' ||
      value === '' ||
      endDate === ''
    ) {
      setSeverity('warning')
      setMes('Please fill all the fields')
      setOn(true)
      return
    }

    const sendData = {
      subject: title,
      body_remark: description,
      query_id: queryId,
      cron_time_from: dayjs(value).format('YYYY-MM-DD HH:mm:ss'),
      cron_time_to:
        checked === true
          ? dayjs(endDate).format('YYYY-MM-DD')
          : dayjs(value).format('YYYY-MM-DD HH:mm:ss'),
      multiple_days: checked === true ? 1 : 0, // 0=>single day, 1=> multiple day
      cron_date: dayjs(selectedDate).format('YYYY-MM-DD'),
      cron_time: dayjs(value).format('HH:mm:ss'),
    }
    try {
      const response = await postMailSchedular(sendData, setError)

      if (response !== 204 && response !== 406) {
        console.log('response', response.data)

        setSeverity('success')
        setMes('Mail scheduled successfully')
        setOn(true)
        handleClose()
        getEvents()
      }
    } catch (error) {}
  }

  React.useEffect(() => {
    fetchHighlightedDays(initialValue)
  }, [])

  React.useEffect(() => {
    setTimeout(() => {
      setDataLoader(false)
    }, 3000)
  })

  React.useEffect(() => {
    if (filterDate) {
      const date = dayjs(filterDate).format('YYYY-MM-DD')
      const filterList = eventList.filter(
        (item) => dayjs(item.cron_date).format('YYYY-MM-DD') === date
      )
      setEventList(filterList)
    }
  }, [filterDate])

  const handleMonthChange = (date) => {
    if (requestAbortController.current) {
      requestAbortController.current.abort()
    }

    setIsLoading(true)
    setHighlightedDays([])
    fetchHighlightedDays(date)
  }

  React.useEffect(() => {
    getEvents()
  }, [])

  return (
    <DashboardLayout heading='Scheduler Calendar' pathName='Scheduler Calendar'>
      <Grid container spacing={2}>
        <Grid item md={3.5} xs={3.5} mt={4}>
          <div style={{ width: '100%', maxWidth: 360, marginLeft: '20px' }}>
            <Typography variant='subtitle2' sx={{ marginBottom: '5px' }}>
              Select Date to See Events
            </Typography>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label='Basic time picker'
                className='form_style'
                slotProps={{
                  textField: { size: 'small', fullWidth: 'true' },
                }}
                format='DD/MM/YYYY'
                value={filterDate}
                onChange={(newValue) => setFilterDate(newValue)}
                minDate={dayjs()}
              />
            </LocalizationProvider>
            <Typography
              variant='subtitle2'
              sx={{
                marginLeft: '3px',
                marginTop: '20px',
                textDecoration: 'underline',
                color: '#184E81',
              }}
            >
              Events List
            </Typography>
            <List
              sx={{
                width: '100%',
                maxWidth: 360,
                height: '55vh',
                overflowY: 'auto',
                bgcolor: 'background.paper',
                mt: 2,
              }}
            >
              {eventList?.map((item) => (
                <React.Fragment key={item.id}>
                  <ListItem
                    alignItems='flex-start'
                    
                  >
                    {/* Date Box */}
                    <Box
                      sx={{
                        minWidth: 60,
                        textAlign: 'center',
                        bgcolor: '#e3eafc',
                        borderRadius: 1,
                        p: 1,
                        mr: 2,
                        border: '1px solid rgba(25, 118, 210, 0.47)',
                      }}
                    >
                      <Typography
                        variant='caption'
                        sx={{ color: '#1976d2', fontWeight: 'bold' }}
                      >
                        {dayjs(item.cron_date).format('MMM')}
                      </Typography>
                      <Typography variant='h5' sx={{ fontWeight: 'bold' }}>
                        {dayjs(item.cron_date).format('DD')}
                      </Typography>
                      <Typography
                        variant='caption'
                        sx={{ color: 'text.secondary' }}
                      >
                        {dayjs(item.cron_date).format('ddd')}
                      </Typography>
                    </Box>

                    {/* Event Details */}
                    <ListItemText
                      primary={
                        <Typography
                          variant='subtitle1'
                          sx={{ fontWeight: 'bold' }}
                        >
                          {item.subject.charAt(0).toUpperCase() +
                            item.subject.slice(1)}
                        </Typography>
                      }
                      secondary={
                        <>
                          <Typography
                            variant='body2'
                            sx={{ color: 'text.primary', display: 'block' }}
                          >
                            {item.body_remark}
                          </Typography>
                          <Typography
                            variant='caption'
                            sx={{
                              color: 'text.secondary',
                              display: 'block',
                              mt: 1,
                            }}
                          >
                            Scheduled for:{' '}
                            {dayjs(item.cron_date).format('DD/MM/YYYY')}
                          </Typography>
                          <Typography
                            variant='caption'
                            sx={{ color: 'text.secondary', display: 'block' }}
                          >
                            Timing: {item.cron_time}
                          </Typography>

                          {/* Canceled Event Notice */}
                          {item.status === 'canceled' && (
                            <Typography
                              variant='body2'
                              sx={{
                                color: 'error.main',
                                fontWeight: 'bold',
                                mt: 1,
                              }}
                            >
                              This event has been canceled.
                            </Typography>
                          )}
                        </>
                      }
                    />

                    {/* Action Buttons */}
                    <IconButton
                      edge='end'
                      aria-label='delete'
                      color='error'
                      onClick={() => deleteEvents(item.id)}
                    >
                      <DeleteSweepTwoTone />
                    </IconButton>
                  </ListItem>

                  <Divider variant='inset' component='li' />
                </React.Fragment>
              ))}
            </List>
          </div>
        </Grid>
        <Grid item md={0.5} xs={0.5}></Grid>
        <Grid item md={8} xs={8} sx={{ background: 'white' }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateCalendar
              showDaysOutsideCurrentMonth
              defaultValue={initialValue}
              loading={isLoading}
              onMonthChange={handleMonthChange}
              renderLoading={() => <DayCalendarSkeleton />}
              slots={{
                day: ServerDay,
              }}
              slotProps={{
                day: {
                  highlightedDays,

                  style: {
                    width: '90px', // Adjust day label width as needed
                    fontSize: '14px', // Adjust day label font size as needed
                  },
                },
              }}
              //   shouldDisableDate={(date) => disableDateFrom(date)}
              minDate={dayjs(date)}
              className='customday'
              sx={{
                width: '100%',
                height: '100%',
                maxHeight: '100%',
                paddingBottom: '30px',
                '& .MuiDayCalendar-header': {
                  gap: '37px', // Add a gap of 60px to MuiDayCalendar-header
                },
              }}
            />
          </LocalizationProvider>
        </Grid>
      </Grid>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Card sx={style}>
          <CardContent
            style={{
              padding: '12px',
              background: '#dce3ea',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <h3 style={{ textDecoration: 'underline' }}>Add New Event</h3>
            <Typography
              variant='subtitle1'
              color={'#1b3e5a'}
              sx={{ fontWeight: 510 }}
            >
              {dayjs(selectedDate, 'YYYY-MM-DD').format('DD/MMM/YYYY')}
            </Typography>
          </CardContent>

          <CardContent style={{ padding: '12px' }}>
            <Grid container spacing={2}>
              <Grid item md={6}>
                <Typography variant='subtitle2' sx={{ marginBottom: '5px' }}>
                  Start*
                </Typography>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateTimePicker
                    size='small'
                    label='Basic time picker'
                    className='form_style'
                    format='DD/MM/YYYY hh:mm A'
                    disablePast
                    slotProps={{
                      textField: { size: 'small', fullWidth: true },
                    }}
                    value={value}
                    onChange={(newValue) => setValue(newValue)}
                    minutesStep={30}
                    minDate={dayjs(selectedDate)}
                    
                    
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item md={6}>
                <Typography variant='subtitle2' sx={{ marginBottom: '5px' }}>
                  End*
                </Typography>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label='Basic time picker'
                    className='form_style'
                    slotProps={{
                      textField: { size: 'small', fullWidth: 'true' },
                    }}
                    format='DD/MM/YYYY'
                    value={endDate}
                    onChange={(newValue) => setEndDate(newValue)}
                    minDate={dayjs(selectedDate)}
                    disabled={!checked}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item md={12} sx={{ marginBottom: '0px' }}>
                <FormGroup
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    marginBottom: '2px',
                  }}
                >
                  <Typography variant='subtitle2'>{` ${
                    queryCode
                      ? 'Previous Query Id* : ' + queryCode
                      : 'Query Id*'
                  }`}</Typography>

                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={checked}
                        onChange={handleChange}
                        color='info'
                      />
                    }
                    label='Multiple Days'
                  />
                </FormGroup>
                <Autocomplete
                  sx={{ width: '100%' }}
                  className='form_style'
                  size='small'
                  disabled={queryCode?.length > 0 ? autoChecked : false}
                  options={options}
                  getOptionLabel={(option) =>
                    `${option.query_code} (${option.email})`
                  }
                  onInputChange={handleInputChange}
                  renderOption={(props, option) => (
                    <Box
                      component='li'
                      sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                      {...props}
                    >
                      <Typography variant='subtitle2' color='initial'>
                        {option.query_code}
                      </Typography>{' '}
                      &nbsp;
                      <Typography variant='subtitle2' color='#3a87ad'>
                        ({option.email})
                      </Typography>
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label='Search by Email or Query Code'
                      variant='outlined'
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {loading ? (
                              <CircularProgress color='inherit' size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </>
                        ),
                      }}
                    />
                  )}
                />

                {queryCode && (
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={!autoChecked}
                          onChange={handleChangeAutochange}
                          color='info'
                        />
                      }
                    />
                    <Typography variant='subtitle2'>
                      Enable to change Query Id
                    </Typography>
                  </div>
                )}
              </Grid>

              <Grid item md={12}>
                <Typography variant='subtitle2'>Title*</Typography>
                <CustomTextArea
                  placeholder={'Message...'}
                  name={'remark'}
                  value={title}
                  row={1}
                  onChange={(e) => setTitle(e.target.value)}
                />
              </Grid>
              <Grid item md={12}>
                <Typography variant='subtitle2'>Description*</Typography>
                <CustomTextArea
                  placeholder={'Message...'}
                  name={'remark'}
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </Grid>
            </Grid>
          </CardContent>
          <CardContent sx={{ padding: '12px', display: 'flex', gap: '10px' }}>
            <Button
              variant='text'
              color='error'
              onClick={handleClose}
              size='small'
            >
              cancel
            </Button>
            <Button
              variant='outlined'
              color='info'
              size='small'
              onClick={handleSaveFormData}
            >
              Save Event
            </Button>
          </CardContent>
        </Card>
      </Modal>
    </DashboardLayout>
  )
}
