import {
  Autocomplete,
  Card,
  CardContent,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from '@mui/material'
import React, { useState, useEffect } from 'react'
import './details.css'
import useAuth from '../../../../hooks/useAuth'
import Loader from '../../../../components/loader/Loader'
import CustomMobileStepper from '../../../../components/mobile_stepper/CustomMobileStepper'
import {
  addPassengersApi,
  getCity,
  getCountry,
  getPassengerListApi,
  getState,
} from '../../../../services/configService'
import { useLocation, useNavigate } from 'react-router-dom'
import PassengerHeader from './PassengerHeader'
import { globalHandlePhone, handleChangeGlobal } from '../../../../utils/common'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'
import MuiPhoneNumber from 'mui-phone-number'

const PassengerDetails = () => {
  const { state } = useLocation()
  const { auth } = useAuth()
  const navigate = useNavigate()

  const [stateData, setStateData] = useState(
    JSON.parse(sessionStorage.getItem('quoteData')) || state
  )



  console.log("state data",state , " session data ",stateData)
  const [activeStep, setActiveStep] = useState(0)
  const [passengerData, setPassengerData] = useState({
    first_name: '',
    middle_name: '',
    last_name: '',
    title: '',
    mobile_no: '',
    mobile_code: '',
    email_id: '',
    passport_no: '',
    pan_no: '',
    passenger_id: null,
    address: '',
    country: '',
    state: '',
    city: '',
    zipcode: '',
  })
  const [dob, setDob] = useState(null)
  const [passengerType, setPassengerType] = useState(1)
  const [passport_expiry_date, setPassport_expiry_date] = useState(null)
  const [error, setError] = useState()
  const [preData, setPreData] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [grouphead, setGrouphead] = useState(false)
  const [statee, setStatee] = useState([])
  const [city, setCity] = useState([])
  const [isRender, setIsRender] = useState(false)
  const [country, setCountry] = useState([])


  async function getCountries() {
    try {
      const get_country = await getCountry()
      if(get_country !== 204 && get_country !== 401){
        setCountry(get_country.data)
      }  
    } catch (error) {
      console.log(error.message)
    }
  }

  async function getStates(id) {
    try {
      const res = await getState(id);
      if(res !== 204 && res !== 401){
        setStatee(res.data)
      }
    } catch (error) {
      console.log(error.message)
    }
  }

  async function getCities(id) {
    try {
      const get_city = await getCity(id)
      if (get_city !== 204 && get_city !== 401) {
        setCity(get_city.data)
      }
    } catch (error) {
      console.log(error)
    } 
  }

  const handleNext = async () => {
    try {
      setIsLoading(true)
      const sendData = {
        ...passengerData,
        dob: dayjs(dob).format('YYYY-MM-DD'),
        passport_expiry_date: dayjs(passport_expiry_date).format('YYYY-MM-DD'),
        type: passengerType,
        query_id: stateData?.query_id,
        is_group_leader: grouphead ? 1 : 0,
      }
      const res = await addPassengersApi(sendData, setError)
      // console.log("test", activeStep, stateData?.passenger);
      if (res !== 204 && res !== 406) {
        setPassengerData({
          first_name: '',
          middle_name: '',
          last_name: '',
          title: '',
          mobile_no: '',
          mobile_code: '',
          email_id: '',
          passport_no: '',
          pan_no: '',
          is_group_leader: '',
          address: '',
          country: '',
          state: '',
          city: '',
          zipcode: '',
        })
        setDob(null)
        setPassport_expiry_date(null)
        setPassengerType(1)
        setGrouphead(false)
        getPassengerList()
        setActiveStep((prevActiveStep) =>
          stateData?.passenger - 1 > prevActiveStep
            ? prevActiveStep + 1
            : prevActiveStep
        )
        if (activeStep + 1 === stateData?.passenger) {
          navigate('/booking')
        }
      }
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }

  const handleGroupleader = () => {
    setGrouphead((pre) => !pre)
  }

  async function getPassengerList() {
    try {
      const res = await getPassengerListApi(stateData?.query_id)
      await getCountries()
      if (res !== 204) {
        setPreData(res?.data)
        res.data[activeStep]?.country &&
          (await getStates(res.data[activeStep].country))
        res.data[activeStep]?.state &&
          (await getCities(res.data[activeStep].state))
      }
    } catch (error) {
      console.log(error)
    }
  }
  const getCityNameById = async(id) => {
    const foundCity  = city.find((item) => item.cityID == id);
    return foundCity  ? foundCity.cityName : "";
  };

  console.log("@@@@@@@",stateData)

  useEffect(() => {
    if (preData.length > 0) {
      setPassengerData({
        first_name: preData[activeStep]?.first_name ?? '',
        middle_name: preData[activeStep]?.middle_name ?? '',
        last_name: preData[activeStep]?.last_name ?? '',
        title: preData[activeStep]?.title ?? '',
        mobile_no: preData[activeStep]?.mobile_no ?? '',
        mobile_code: preData[activeStep]?.mobile_code ?? '',
        email_id: preData[activeStep]?.email_id ?? '',
        passport_no: preData[activeStep]?.passport_no ?? '',
        pan_no: preData[activeStep]?.pan_no ?? '',
        passenger_id: preData[activeStep]?.passenger_id ?? null,
        country: preData[activeStep]?.country ?? '',
        state: preData[activeStep]?.state ?? '',
        city: preData[activeStep]?.city ?? '',
        zipcode: preData[activeStep]?.zipcode ?? '',
        address: preData[activeStep]?.address ?? '',
      })
      setGrouphead(preData[activeStep]?.is_group_leader ?? '')
      setDob(dayjs(preData[activeStep]?.dob))
      setPassport_expiry_date(dayjs(preData[activeStep]?.passport_expiry_date))
      setPassengerType(preData[activeStep]?.type ?? 1)
    }
  }, [activeStep, preData,isRender])

  useEffect(() => {
    getPassengerList()
  }, [activeStep, isRender])

  return (
    <>
      {auth.isLoading ? (
        <Loader />
      ) : (
        (auth.role == 2 || true) && (
          <Card
            className='glasss'
            sx={{ minHeight: '100%', borderRadius: '6px' }}
            elevation={2}
            key={activeStep}
          >
            {/* header */}
            <PassengerHeader
              query_id={stateData?.query_id}
              setError={setError}
              setIsRender={setIsRender}
            />

            {/* main content  */}
            <CardContent sx={{ overflow: 'auto' }}>
                <Card
                  sx={{ backgroundColor: 'white' }}
                  className=''
                  elevation={3}
                >
                  {/* component */}
                  <CardContent style={{ paddingTop: '10px' }}>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        border: '1px solid #C9F0DD',
                        marginBottom: '10px',
                        background: '#F1FFF7',
                        borderRadius: 4,
                      }}
                    >
                      <FormControl>
                        <RadioGroup
                          aria-labelledby='demo-radio-buttons-group-label'
                          default='roundtrip'
                          name='radio-buttons-group'
                          value={passengerType}
                          onChange={(e) => {
                            setPassengerType(e.target.value)
                          }}
                          row
                          sx={{ fontWeight: '600', marginLeft: '10px' }}
                        >
                          <FormControlLabel
                            value='1'
                            control={<Radio color='success' />}
                            label='Adult'
                          />
                          <FormControlLabel
                            value='2'
                            control={<Radio color='success' />}
                            label='Child'
                          />
                          <FormControlLabel
                            value='3'
                            control={<Radio color='success' />}
                            label='Infant'
                          />
                        </RadioGroup>
                      </FormControl>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={grouphead}
                            onClick={handleGroupleader}
                            color='success'
                          />
                        }
                        label='Group Leader'
                      />
                    </div>
                    <Typography
                      sx={{
                        marginBottom: '5px',
                        fontSize: '17px',
                        fontWeight: 'bold',
                      }}
                    >
                      Details
                    </Typography>
                    <CardContent sx={{ bgcolor: '#F0F0F0', borderRadius: 1 }}>
                      <Grid container spacing={3} mb={2.5}>
                        <Grid item md={1.6}>
                          <FormControl
                            fullWidth={true}
                            size='small'
                            error={error && error.title}
                          >
                            <InputLabel id='demo-simple-select-label'>
                              Title
                            </InputLabel>
                            <Select
                              labelId='demo-simple-select-label'
                              id='demo-simple-select'
                              label='Age'
                              fullWidth={true}
                              size='small'
                              className='form_style'
                              name='title'
                              value={passengerData.title}
                              onChange={(e) =>
                                handleChangeGlobal(
                                  e,
                                  setPassengerData,
                                  setError,
                                  error,
                                  'title'
                                )
                              }
                            >
                              <MenuItem value={"Mr"}>Mr.</MenuItem>
                              <MenuItem value={"Ms"}>Ms.</MenuItem>
                              <MenuItem value={"Mrs"}>Mrs.</MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item md={3}>
                          <TextField
                            variant='outlined'
                            className='form_style'
                            label='First Name'
                            size='small'
                            name='first_name'
                            value={passengerData?.first_name}
                            error={error && error['first_name']}
                            onChange={(e) =>
                              handleChangeGlobal(
                                e,
                                setPassengerData,
                                setError,
                                error,
                                'first_name'
                              )
                            }
                            fullWidth={true}
                          />
                          <FormHelperText
                            style={{ color: 'red', marginLeft: '4px' }}
                          >
                            {error && error['first_name']}
                          </FormHelperText>
                        </Grid>
                        <Grid item md={3}>
                          <TextField
                            variant='outlined'
                            className='form_style'
                            label='Middle Name'
                            size='small'
                            name='middle_name'
                            value={passengerData?.middle_name}
                            error={error && error['middle_name']}
                            onChange={(e) =>
                              handleChangeGlobal(
                                e,
                                setPassengerData,
                                setError,
                                error,
                                'middle_name'
                              )
                            }
                            fullWidth={true}
                          />
                          <FormHelperText
                            style={{ color: 'red', marginLeft: '4px' }}
                          >
                            {error && error['middle_name']}
                          </FormHelperText>
                        </Grid>
                        <Grid item md={3}>
                          <TextField
                            variant='outlined'
                            className='form_style'
                            label='Last Name'
                            size='small'
                            name='last_name'
                            value={passengerData.last_name}
                            error={error && error['last_name']}
                            onChange={(e) =>
                              handleChangeGlobal(
                                e,
                                setPassengerData,
                                setError,
                                error,
                                'last_name'
                              )
                            }
                            fullWidth={true}
                          />
                          <FormHelperText
                            style={{ color: 'red', marginLeft: '4px' }}
                          >
                            {error && error['last_name']}
                          </FormHelperText>
                        </Grid>
                      </Grid>
                      <Grid container spacing={2} mb={3}>
                        <Grid item md={4}>
                          <TextField
                            variant='outlined'
                            className='form_style'
                            label='Email address'
                            size='small'
                            name='email_id'
                            value={passengerData.email_id}
                            error={error && error['email_id']}
                            onChange={(e) =>
                              handleChangeGlobal(
                                e,
                                setPassengerData,
                                setError,
                                error,
                                'email_id'
                              )
                            }
                            fullWidth={true}
                          />
                          <FormHelperText
                            style={{ color: 'red', marginLeft: '4px' }}
                          >
                            {error && error['email_id']}
                          </FormHelperText>
                        </Grid>
                        <Grid item md={3.6}>
                          <MuiPhoneNumber
                            className='form_style'
                            variant='outlined'
                            name='mobile_no'
                            value={passengerData.mobile_no}
                            defaultCountry={'in'}
                            onChange={(e, v) =>
                              globalHandlePhone(
                                e,
                                v,
                                setPassengerData,
                                setError,
                                error,
                                'mobile_no'
                              )
                            }
                            error={error && error['mobile_no']}
                            size='small'
                            fullWidth={true}
                          />
                          <FormHelperText
                            style={{ color: 'red', marginLeft: '4px' }}
                          >
                            {error && error['mobile_no']}
                          </FormHelperText>
                        </Grid>
                        <Grid item md={3}>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              name='dob'
                              format='DD-MM-YYYY'
                              slotProps={{
                                textField: { size: 'small', fullWidth: true },
                              }}
                              label='Date of birth'
                              className='form_style'
                              disableFuture={true}
                              value={dob}
                              error={error && error['dob']}
                              onChange={(e) => {
                                setDob(e)
                                setError((pre) => ({ ...pre, ['dob']: '' }))
                              }}
                            />
                          </LocalizationProvider>
                          <FormHelperText
                            style={{ color: 'red', marginLeft: '4px' }}
                          >
                            {error && error['dob']}
                          </FormHelperText>
                        </Grid>
                      </Grid>
                      <Grid container gap='16px'>
                        <Grid item md={3.85}>
                          <TextField
                            variant='outlined'
                            className='form_style'
                            label='Pan No.'
                            size='small'
                            name='pan_no'
                            error={error && error['pan_no']}
                            InputProps={{ inputProps: { maxLength: 10 } }}
                            value={passengerData.pan_no}
                            onChange={(e) =>
                              handleChangeGlobal(
                                e,
                                setPassengerData,
                                setError,
                                error,
                                'pan_no',
                                '',
                                true
                              )
                            }
                            fullWidth={true}
                          />
                          <FormHelperText
                            style={{ color: 'red', marginLeft: '4px' }}
                          >
                            {error && error['pan_no']}
                          </FormHelperText>
                        </Grid>
                        <Grid item md={3.5}>
                          <TextField
                            variant='outlined'
                            className='form_style'
                            label='Passport No.'
                            size='small'
                            error={error && error['passport_no']}
                            name='passport_no'
                            value={passengerData.passport_no}
                            onChange={(e) =>
                              handleChangeGlobal(
                                e,
                                setPassengerData,
                                setError,
                                error,
                                'passport_no'
                              )
                            }
                            fullWidth={true}
                          />
                          <FormHelperText
                            style={{ color: 'red', marginLeft: '4px' }}
                          >
                            {error && error['passport_no']}
                          </FormHelperText>
                        </Grid>
                        <Grid item md={2.9}>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              name='passport_expiry'
                              format='DD-MM-YYYY'
                              slotProps={{
                                textField: { size: 'small', fullWidth: true },
                              }}
                              label='Passport Expiry'
                              className='form_style'
                              // setValue={setPassport_expiry_date}
                              value={passport_expiry_date}
                              disablePast={true}
                              error={error && error['passport_expiry_date']}
                              onChange={(e) => {
                                setPassport_expiry_date(e)
                                setError((pre) => ({
                                  ...pre,
                                  ['passport_expiry_date']: '',
                                }))
                              }}
                            />
                          </LocalizationProvider>
                          <FormHelperText
                            style={{ color: 'red', marginLeft: '4px' }}
                          >
                            {error && error['passport_expiry_date']}
                          </FormHelperText>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </CardContent>
                  <Typography
                    sx={{
                      marginBottom: '5px',
                      fontSize: '17px',
                      fontWeight: 'bold',
                      marginLeft: '20px',
                    }}
                  >
                    Address
                  </Typography>
                  <CardContent
                    sx={{
                      bgcolor: '#F0F0F0',
                      marginLeft: '16px',
                      marginRight: '16px',
                      borderRadius: 1,
                    }}
                  >
                    <Grid container spacing={2} marginBottom={2}>
                      {country && country.length > 0 && (
                        <Grid item md={4} xs={12}>
                          <Autocomplete
                            disablePortal
                            options={country ?? [{ name: 'no data' }]}
                            getOptionLabel={(option) => option?.name}
                            size='small'
                            fullWidth
                            // readOnly={show}
                            onChange={(e, v) => {
                              setPassengerData({
                                ...passengerData,
                                country: v?.id,
                                state: null,
                                city: null,
                              })
                              getStates(v?.id, 'country_id')
                            }}
                            defaultValue={country?.find(
                              (item) => item.id == preData[activeStep]?.country
                            )}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label='Country'
                                className='form_style'
                                error={error && error['country']}
                              />
                            )}
                          />
                          <FormHelperText
                            sx={{ color: 'red', marginLeft: '15px' }}
                          >
                            {error && error['country']}
                          </FormHelperText>
                        </Grid>
                      )}
                      <Grid item md={4} xs={12}>
                        <FormControl fullWidth>
                          <InputLabel id='state-label' size='small'>
                            State
                          </InputLabel>
                          <Select
                            labelId='state-label'
                            id='demo-simple-select'
                            label='State'
                            fullWidth={true}
                            size='small'
                            className='form_style'
                            name='state'
                            value={passengerData.state}
                            onChange={(e) => {
                              handleChangeGlobal(
                                e,
                                setPassengerData,
                                setError,
                                error,
                                'state'
                              )
                              getCities(e.target.value)
                            }}
                            defaultValue={
                              statee.find(
                                (item) =>
                                  item?.state_id == preData[activeStep]?.state
                              )?.state_name
                            }
                          >
                            {statee.map((item) => (
                              <MenuItem value={item.state_id}>
                                {item.state_name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        <FormHelperText
                          sx={{ color: 'red', marginLeft: '15px' }}
                        >
                          {error && error['state']}
                        </FormHelperText>
                      </Grid>
                      <Grid item md={4} xs={12}>
                        <FormControl fullWidth>
                          <InputLabel id='city-label' size='small'>
                            City
                          </InputLabel>
                          <Select
                            className='form_style'
                            labelId='city-label'
                            id='demo-simple-select'
                            size='small'
                            label='City'
                            name='city'
                            value={passengerData.city}
                            onChange={(e) =>
                              handleChangeGlobal(
                                e,
                                setPassengerData,
                                setError,
                                error,
                                'city'
                              )
                            }
                            defaultValue={city?.find(
                              (item) => item.cityID == preData[activeStep]?.city
                            )}
                            fullWidth={true}
                          >
                            {city.map((item) => (
                              <MenuItem value={item?.cityID}>
                                {item?.cityName}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        <FormHelperText
                          sx={{ color: 'red', marginLeft: '15px' }}
                        >
                          {error && error['city']}
                        </FormHelperText>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2} marginBottom={1}>
                      <Grid item md={4} xs={12}>
                        <TextField
                          variant='outlined'
                          className='form_style'
                          label='Pincode'
                          size='small'
                          sx={{
                            marginTop: '5px',
                            // input: { cursor: show ? "not-allowed" : "auto" },
                          }}
                          fullWidth
                          name='zipcode'
                          value={passengerData.zipcode}
                          defaultValue={passengerData?.zipcode}
                          onChange={(e) =>
                            handleChangeGlobal(
                              e,
                              setPassengerData,
                              setError,
                              error,
                              'zipcode'
                            )
                          }
                          error={error && error['zipcode']}
                        />
                        <FormHelperText
                          style={{ color: 'red', marginLeft: '2px' }}
                        >
                          {error && error['pincode']}
                        </FormHelperText>
                      </Grid>
                      <Grid item md={4} xs={12}>
                        <TextField
                          variant='outlined'
                          className='form_style'
                          label='Address'
                          size='small'
                          name='address'
                          value={passengerData.address}
                          sx={{
                            marginTop: '5px',
                            // input: { cursor: show ? "not-allowed" : "auto" },
                          }}
                          InputProps={
                            {
                              // readOnly: show,
                            }
                          }
                          onChange={(e) =>
                            handleChangeGlobal(
                              e,
                              setPassengerData,
                              setError,
                              error,
                              'address'
                            )
                          }
                          error={error && error['address_line_1']}
                          defaultValue={passengerData?.address_line_1}
                          fullWidth
                        />
                        <FormHelperText
                          style={{ color: 'red', marginLeft: '2px' }}
                        >
                          {error && error['address_line_1']}
                        </FormHelperText>
                      </Grid>
                    
                    </Grid>
                  </CardContent>
                  {/* slider */}
                  <CustomMobileStepper
                    onclick={onclick}
                    handleNext={handleNext}
                    activeStep={activeStep}
                    setActiveStep={setActiveStep}
                    totalSteps={stateData?.passenger}
                    isLoading={isLoading}
                  />
                </Card>
            </CardContent>
          </Card>
        )
      )}
    </>
  )
}
export default PassengerDetails
