import * as React from 'react'
import Box from '@mui/material/Box'
import Checkbox from '@mui/material/Checkbox'
import { DataGrid, GridToolbar, gridClasses } from '@mui/x-data-grid'
import dayjs from 'dayjs'
import { alpha, styled } from '@mui/material/styles'

import IconButton from '@mui/material/IconButton'

import { UploadFileTwoTone, Visibility } from '@mui/icons-material'
import { Button, TextField, Tooltip, Typography } from '@mui/material'
import { SnackbarContext } from '../../../../context/Snackbar'
import {
  getPreBookingPassengers,
  passengerticketupl,
  preloginticketupl,
} from '../../../../services/configService'
import DashboardLayout from '../../../../components/dashboard_layout/DashboardLayout'

const ODD_OPACITY = 0.2

const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
  [`& .${gridClasses.row}.odd`]: {
    backgroundColor: theme.palette.grey[300],
  },
  [`& .${gridClasses.row}.void`]: {
    backgroundColor: '#f123',
  },
  [`& .${gridClasses.row}.cancel`]: {
    backgroundColor: '#f123',
  },
  [`& .${gridClasses.row}.green`]: {
    animation: 'ripple 3s ease-in-out',
  },
  '@keyframes ripple': {
    '0%': {
      backgroundColor: 'lightgreen',
    },
    '100%': {
      backgroundColor: 'white',
    },
  },
  [`& .${gridClasses.row}`]: {
    '&:hover, &.Mui-hovered': {
      backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
  },
  [`& .${gridClasses.columnHeader}`]: {
    color: 'white',
    width: '100%',
    backgroundColor: 'lightslategrey',
  },
}))

export default function UploadPnr({
  handleClosePassengerModal,
  booking_id,
  getBookingList,
}) {
  const [data, setData] = React.useState([])
  const { setOn, setSeverity, setMes } = React.useContext(SnackbarContext)
  //   const [selectedFile, setSelectedFile] = React.useState(null)
  const [isChecked, setIsChecked] = React.useState([])
  const [editable, SetEditable] = React.useState(false)
  const [sendLoader, setSendLoader] = React.useState(false)
  const [disabled, setDisabled] = React.useState(true)
  const [error, setError] = React.useState(null)
  const [showWarningModal, setShowWarningModal] = React.useState(false)
  const [isLoading, setIsLoading] = React.useState(false)
  const [uploadedFiles, setUploadedFiles] = React.useState({})
  const [pnr, setPnr] = React.useState({})

  const handleFileSelect = (rowId, file) => {
    setUploadedFiles((prev) => ({
      ...prev,
      [rowId]: file,
    }))
  }
  const handlePNRUpdate = (rowId, updatedPNR) => {
    setPnr((prev) => ({
      ...prev,
      [rowId]: updatedPNR, // Update the PNR for the specific row
    }))
  }

 

 
  const handleSendFiles = async () => {
    setSendLoader(true)
    const passengerList = getBookingList
      .map((row) => ({
        id: row.passenger_id, // Row ID
        ticket: uploadedFiles[row.passenger_id] ? uploadedFiles[row.passenger_id] : null, // Replace with dynamic ticket value if needed
        pnr_no: pnr[row.passenger_id] ? pnr[row.passenger_id] : null,
      }))
      .filter((data) => data.ticket !== null) // Only include rows with uploaded files

    // console.log('passengerList', passengerList)

    const sendData = new FormData()
    sendData.append('query_id', booking_id)
    sendData.append('pnr_no', JSON.stringify(pnr))
    const linkData = {}

    passengerList.forEach((row) => {
      const fileOrLink = uploadedFiles[row.id]

      if (typeof fileOrLink === 'string' && fileOrLink.startsWith('http')) {
        // If it's a string (and starts with 'http', indicating a URL)
        linkData[row.id] = fileOrLink
      } else {
        console.warn('')
      }
    })
    passengerList.forEach((row) => {
      sendData.append(`${row.id}`, uploadedFiles[row.id]) // Attach file
    })
    sendData.append('link', JSON.stringify(linkData))

    try {
      const res = await passengerticketupl(sendData, setError)
      if (res !== 204 && res !== 406 && res !== 401 && res !== 400) {
        // setData(res.data)
        setSeverity('success')
        setMes('Passengers ticket uploaded successfully')
        setOn(true)
        handleClosePassengerModal()
        setPnr({})
        setSendLoader(false)
      }
    } catch (error) {
      console.log(error)
      setSendLoader(false)
      // setSendLoader(setSendLoader)
    }
  }

  // React.useEffect(() => {
  //   getPassengersList()
  // }, [booking_id])

  const columns = [
    {
      field: 'Name',
      headerName: 'Name',
      flex: 1,
      minWidth: 150,
      renderCell: (params) => {
        return params.row.first_name + ' ' + params.row.last_name
      },
    },
    {
      field: 'dob',
      headerName: 'DOB',
      minWidth: 120,
      renderCell: (params) => {
        return params.row.dob
          ? dayjs(params.row.dob).format('DD-MM-YYYY')
          : 'N.A'
      },
    },
    {
      field: 'mobile_no',
      headerName: 'Mobile No.',
      flex: 1,
      minWidth: 120,
    },
    {
      field: 'email_id',
      headerName: 'Email Id',
      flex: 1,
      minWidth: 150,
    },
    {
      field: 'pan_no',
      headerName: 'Pan No.',
      flex: 1,
      minWidth: 140,
    },
    {
      field: 'passport_no',
      headerName: 'Passport No.',
      flex: 1,
      minWidth: 130,
      renderCell: (params) => {
        return params.row.passport_no != 'undefined'
          ? params.row.passport_no
          : 'N.A'
      },
    },

    {
      field: 'pnr',
      type: 'actions',
      headerName: 'PNR',
      width: 300,
      renderCell: (params) => {
        const rowId = params.row.passenger_id

        const handlePNRChange = (event) => {
          const updatedPNR = event.target.value
          handlePNRUpdate(rowId, updatedPNR) // Custom function to update the PNR
        }

        return (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              gap: 1,
            }}
          >
            <TextField
              size='small'
              // label='PNR'
              variant='outlined'
              defaultValue={params.row.pnr_no} // Assuming PNR is part of row data
              onChange={handlePNRChange}
            />
          </Box>
        )
      },
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Upload Ticket',
      width: 200,

      renderCell: (params) => {
        const rowId = params.row.passenger_id
        const link = params.row.eticket_doc

        const handleUploadClick = () => {
          const fileInput = document.createElement('input')
          fileInput.type = 'file'
          fileInput.accept = 'application/pdf'
          fileInput.onchange = (e) => {
            const file = e.target.files?.[0]
            if (file) {
              handleFileSelect(rowId, file)
            }
          }
          fileInput.click()
        }

        const handleViewDocClick = () => {
          if (link) {
            window.open(link, '_blank')
          }
        }

        const file = uploadedFiles[rowId]

        return (
          <Box
            sx={{
              display: 'flex',
              gap: 1,
            }}
          >
            <Button
              variant='contained'
              size='small'
              color={file ? 'success' : 'primary'}
              onClick={handleUploadClick}
            >
              {file?.name ? `${file.name.slice(0, 9)}...` : 'Upload'}
            </Button>
            {link && (
              <Tooltip title='View Previous Document'>
                <IconButton color='default' onClick={handleViewDocClick}>
                  <Visibility />
                </IconButton>
              </Tooltip>
            )}
          </Box>
        )
      },
    },
  ]

  React.useEffect(() => {
    const updatedPNR = {}
    Object.entries(getBookingList).forEach(([rowId, rowData]) => {
      if (rowData.pnr_no) {
        updatedPNR[rowData.passenger_id] = rowData.pnr_no
      }
    })
    console.log('Updated PNR:', updatedPNR) // Add this for debugging
    setPnr(updatedPNR)
  }, [getBookingList])

  React.useEffect(() => {
    const updatedFile = {}
    Object.entries(getBookingList).forEach(([rowId, rowData]) => {
      if (rowData.eticket_doc) {
        updatedFile[rowData.passenger_id] = rowData.eticket_doc
      }
    })
    setUploadedFiles(updatedFile)
  }, [getBookingList])

  const allPNRsAndFilesUploaded = () => {
    // Loop through all values in the pnr object
    if (
      Object.values(pnr).length === 0 ||
      Object.values(uploadedFiles).length === 0
    ) {
      return true
    }
    for (const pnr2 of Object.values(pnr)) {
      // If any value is an empty string, return false (not all are uploaded)
      if (pnr2 === '') {
        return true
      }
    }
    for (const upf of Object.values(uploadedFiles)) {
      // If any value is an empty string, return false (not all are uploaded)
      if (upf === '') {
        return true
      }
    }

    // If no empty string was found, return true (all are uploaded)
    return false
  }

  // console.log('disabled', allPNRsAndFilesUploaded(), 'dis', disabled)

  React.useEffect(() => {
    if (allPNRsAndFilesUploaded()) {
      setDisabled(true)
    } else {
    

      setDisabled(false)
    }
  }, [pnr, uploadedFiles])

  // console.log('disabled', !allPNRsAndFilesUploaded)

  return (
    <Box
      sx={{
        height: '80vh',
        width: '80%',
        backgroundColor: 'white',
        borderRadius: '10px',
      }}
    >
      <DashboardLayout
        text={'Booking Summary'}
        button={true}
        btnText={"Send Ticket's on Email"}
        btnColor={'submitRed'}
        btnBackText={'close'}
        heading={'My Bookings'}
        backBtn={true}
        mainBtnDisabled={disabled}
        onClick={handleSendFiles}
        onClickBack={handleClosePassengerModal}
        pathName={'Upload Passenger Ticket'}
        isLoading={sendLoader}
      >
        <StripedDataGrid
          rows={getBookingList?.map((item, index) => ({
            ...item,
          }))}
          getRowId={(row) => row.passenger_id}
          columns={columns}
          disableColumnFilter
          disableColumnSelector
          sx={{ height: 'calc(100vh - 13.6rem)' }}
          disableDensitySelector
          slots={{ toolbar: GridToolbar }}
          slotProps={{
            toolbar: {
              showQuickFilter: false,
            },
          }}
          disableRowSelectionOnClick
        />
      </DashboardLayout>
      {/* <TableModal
        handleShowModal={showWarningModal}
        handleCloseModal={handleCloseWarningModal}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            width: '100vw',
          }}
        >
          <Deletecard
            onClickBack={handleCloseWarningModal}
            onClickSubmit={handleSubmit}
            btnText={'submit'}
            subheading={'Cancelled Ticket'}
            isLoading={isLoading}
          />
        </div>
      </TableModal> */}
    </Box>
  )
}
