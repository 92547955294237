import * as React from 'react'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Checkbox from '@mui/material/Checkbox'
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'
import Chip from '@mui/material/Chip'
import { DataGrid, GridToolbar, gridClasses } from '@mui/x-data-grid'
import dayjs from 'dayjs'
import { alpha, styled } from '@mui/material/styles'
import { getAddOnServicesList } from '../../../services/configService'

import {
  Download,
  DraftsOutlined,
  DraftsTwoTone,
  Mail,
  MailOutlineTwoTone,
  Payment,
  Send,
  SwitchAccessShortcutAdd,
} from '@mui/icons-material'
import { useLocation, useNavigate } from 'react-router-dom'

import { Badge, FormControl, IconButton, InputLabel, ListItemText, MenuItem, Modal, OutlinedInput, Select, Tooltip } from '@mui/material'
import TableMenu from '../../../components/table_modal/TableMenu'
import { downloadAttachment } from '../../../utils/common'
import { a } from 'react-spring'

const ODD_OPACITY = 0.2

const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
  [`& .${gridClasses.row}.even`]: {
    backgroundColor: '#E3EFF7',
  },
  [`& .${gridClasses.row}.void`]: {
    backgroundColor: '#f123',
  },
  [`& .${gridClasses.row}.cancel`]: {
    backgroundColor: '#f123',
  },
  [`& .${gridClasses.row}.green`]: {
    animation: 'ripple 3s ease-in-out',
  },
  '@keyframes ripple': {
    '0%': {
      backgroundColor: 'lightgreen',
    },
    '100%': {
      backgroundColor: 'white',
    },
  },
  [`& .${gridClasses.row}`]: {
    '&:hover, &.Mui-hovered': {
      backgroundColor: alpha('#FFFF00', ODD_OPACITY),
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
  },
  [`& .${gridClasses.columnHeader}`]: {
    color: 'black',
    width: '100%',
    backgroundColor: '#D3D3D3',
  },
}))

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: '#61699f',
  // border: '2px solid #000',
  boxShadow: 24,
  p: 1,
  // color: 'skyblue',
}

export default function PrePassengerReqAddOn({ passengers, selectedServices, setSelectedServices,servicesList }) {
    const navigate = useNavigate()
    const { state } = useLocation()
    const [data, setData] = React.useState([])
    const [loader, setLoader] = React.useState(false)
    const [pagination, setPagination] = React.useState(0)

    const [rowCounts, setRowCounts] = React.useState(0)

    React.useEffect(() => {
        if (passengers && passengers?.length > 0) {
        setRowCounts(passengers.length)
        setData(passengers)
        }
    }, [passengers])
    
    const handleServiceChange = (event, rowId) => {
        const { value } = event.target;
        console.log('value', value);
        setSelectedServices((prev) => ({
          ...prev,
          [rowId]: value,
        }));
      };

  const columns = [
    {
      field: 'name',
      headerName: 'Name',
      width: 130,
      renderCell: (params) => {
        return params.row?.name ? (
          <p style={{ fontWeight: 500 }}>{params.row.name}</p>
        ) : (
          <p>N.A</p>
        )
      },
    },
    {
        field: 'pan_no',
        headerName: 'PAN No.',
        minWidth: 120,
        renderCell: (params) => {
          return params.row?.pan_no ? (
            <p style={{ fontWeight: 500 }}>{params.row.pan_no}</p>
          ) : (
            <p>N.A</p>
          )
        },
      },
      {
        field: 'passport_no',
        headerName: 'Passport No.',
        minWidth: 110,
        renderCell: (params) => {
          return params.row?.passport_no ? (
            <p style={{ fontWeight: 500 }}>{params.row.passport_no}</p>
          ) : (
            <p>N.A</p>
          )
        },
      },
    {
      field: 'email_id',
      headerName: 'Email ID',
      minWidth: 180,
      flex: 0, // Prevents auto-expanding
  renderCell: (params) => (
        <Tooltip title={params.row.email_id} arrow>
            <p
                style={{
                color: '#184E81',
                fontWeight: 500,
                textDecoration: 'underline',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                maxWidth: '100%',
                }}
            >
                {params.row.email_id}
            </p>
        </Tooltip>
        ),
    },
    {
      field: 'mobile_no',
      headerName: 'Mobile No.',
      minWidth: 100,
      flex: 'auto',
      renderCell: (params) => {
        return (
          <p style={{ color: '#184E81', fontWeight: 500 }}>
            {params.row.mobile_no}
          </p>
        )
      },
    },
    {
        field: "services",
        headerName: "Additional Services",
        minWidth: 250,
        flex: 1,
        renderCell: (params) => {
          const { row } = params;
          return (
           <FormControl fullWidth sx={{ minWidth: 250 }}>
            
            <Select
              labelId={`services-label-${row.id}`}
              multiple
              value={selectedServices[row.id] || []}
              onChange={(event) => handleServiceChange(event, row.id)}
              input={<OutlinedInput label="" />}
              displayEmpty
              renderValue={(selected) => {
                if (selected.length === 0) return <>Select Services</>;
                return (
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.3 }}>
                    {selected.map((serviceId) => {
                      const service = servicesList.find((s) => s.id === serviceId);
                      return service ? (
                        <Tooltip key={serviceId} title={service.charge_name} arrow>
                          <Chip
                            label={service.charge_name}
                            sx={{
                              backgroundColor: "#e3f2fd",
                              color: "#1976d2",
                              fontSize: "0.8rem",
                              height: 26,
                              borderRadius: "10px",
                              fontWeight: 500,
                              padding: "0 8px",
                              "& .MuiChip-label": { padding: "0 8px" },
                            }}
                          />
                        </Tooltip>
                      ) : null;
                    })}
                  </Box>
                );
              }}
              sx={{
                width: "100%",
                backgroundColor: "#fff",
                borderRadius: "14px",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.08)",
                transition: "all 0.2s ease-in-out",
                "&:hover": { boxShadow: "0 6px 12px rgba(0, 0, 0, 0.12)" },
                "&.Mui-focused": {
                  borderColor: "#1976d2",
                  borderWidth: 2,
                  boxShadow: "0 0 8px rgba(25, 118, 210, 0.25)",
                },
              }}
            >
              {servicesList.map((service) => (
                <MenuItem
                  key={service.id}
                  value={service.id}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 1.5,
                    padding: "10px 12px",
                    borderRadius: "8px",
                    transition: "background 0.2s ease-in-out",
                    "&:hover": { backgroundColor: "#e3f2fd" },
                  }}
                >
                  <Checkbox
                    checked={(selectedServices[row.id] || []).includes(service.id)}
                    sx={{ padding: "6px", color: "#1976d2" }}
                  />
                  <ListItemText
                    primary={service.charge_name}
                    primaryTypographyProps={{
                      fontSize: "0.9rem",
                      fontWeight: 500,
                      color: "#333",
                    }}
                  />
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          );
        },
      },
      
  ]
  return (
    <Box sx={{  width: '100%' ,height: '100%', display: 'flex', flexDirection: 'column' }}>
      <Modal
        open={loader}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <div className='loader-container'>
          <div class='dot-spinner'>
            <div class='dot-spinner__dot'></div>
            <div class='dot-spinner__dot'></div>
            <div class='dot-spinner__dot'></div>
            <div class='dot-spinner__dot'></div>
            <div class='dot-spinner__dot'></div>
            <div class='dot-spinner__dot'></div>
            <div class='dot-spinner__dot'></div>
            <div class='dot-spinner__dot'></div>
          </div>
          <p
            style={{
              marginLeft: '10px',
              fontWeight: '500',
              marginTop: '10px',
            }}
          >
            Please wait!!
          </p>
        </div>
      </Modal>

      <StripedDataGrid
        rows={passengers?.map((item, index) => ({
          ...item,
          s_no: index + 1,
          name:
            item?.first_name + ' ' + item?.middle_name + ' ' + item?.last_name,
        }))}
        getRowId={(row) => row?.id}
        columns={columns}
        rowCount={rowCounts} // Define the total number of rows
        pagination={false}
        pageSizeOptions={[10, 20, 50]} // Set the available page size options
        disableColumnFilter
        disableColumnSelector
        disableDensitySelector
        slots={{ toolbar: GridToolbar }}
        // slotProps={{
        //   toolbar: {
        //     showQuickFilter: true,
        //   },
        // }}
        getRowClassName={(params) =>
          params.row.is_mapped == 1
            ? 'void'
            : params.indexRelativeToCurrentPage % 2 === 0
            ? 'even'
            : 'odd'
        }
        disableRowSelectionOnClick
        density='compact' // Makes it more compact
        rowHeight={60} // Manually set row height for more control
        sx={{
          '& .MuiDataGrid-cell': {
            padding: '4px', // Reduce padding inside cells
          },
          '& .MuiDataGrid-columnHeaders': {
            minHeight: '32px', // Reduce column header height
          },
          '& .MuiDataGrid-columnHeader': {
            padding: '5px', // Reduce header padding
          },
          '& .MuiDataGrid-row': {
            height: '60px', // Reduce row height
          },
          flex: 1,
        }}
      />
      {/* <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={style}>
          <Makepaymentsuccess paymentdetails={paymentdetails} />
        </Box>
      </Modal> */}
    </Box>
  )
}
