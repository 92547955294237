import * as React from 'react'
import dayjs from "dayjs";
import {
  DataGrid,
  GridActionsCellItem,
  gridClasses,
  GridToolbar,
  GridPagination,
  GridToolbarQuickFilter,
} from '@mui/x-data-grid'
import {
  getAgentList,
  getSalesOrderAgentList,
  getBulkSalesOrder,
} from '../../../../services/admin.configService'

import CustomNoRowsOverlay from '../../../../components/common/NoRowsIcon'
import { Button, styled, Card, Checkbox, FormControl, InputLabel, Select, MenuItem} from "@mui/material";
import { Grid } from '@mui/material'
import { toCapitialize } from '../../../../utils/common'
import DashboardLayout from '../../../../components/dashboard_layout/DashboardLayout'
import { SnackbarContext } from '../../../../context/Snackbar';
import { set } from 'lodash';

const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
  [`& .${gridClasses.row}.deactivated`]: {
    backgroundColor: '#f123',
  },
  [`& .${gridClasses.row}.odd`]: {
    backgroundColor: theme.palette.grey[200],
  },
  [`& .${gridClasses.columnHeader}`]: {
    color: 'white',
    // position: "sticky",
    backgroundColor: 'lightslategrey',
  },
}))

const CustomFooter = () => {
  return (
    <Grid
      container
      paddingLeft={2}
      // height={"50px"}
      alignItems={'center'}
      justifyContent={'space-between'}
      borderTop={'1px solid #e5e5e5'}
    >
      <Grid item display={'flex'} gap={1}>
        {/* <Typography variant='subtitle1' color='initial'>
          Tags
        </Typography>
        <Stack direction={'row'} spacing={1}>
          <Chip
            label='Deactivated'
            sx={{ backgroundColor: '#f123', width: '120px' }}
          />
        </Stack> */}
      </Grid>
      <GridPagination />
    </Grid>
  )
}
const getLastThreeFinancialYears = () => {
    const currentYear = dayjs().year();
    const currentMonth = dayjs().month() + 1; // Months are 0-based in dayjs
  
    // If it's April or later, include the next financial year
    const startYearOffset = currentMonth >= 4 ? 0 : 1;
  
    return Array.from({ length: 3 }, (_, i) => {
      const startYear = currentYear - i - startYearOffset;
      const endYear = startYear + 1;
  
      return {
        year: `FY ${startYear}-${endYear.toString().slice(-2)}`, // e.g., FY 2025-26
        from_date: dayjs(`${startYear}-04-01`).format("YYYY-MM-DD"),
        to_date: dayjs(`${endYear}-03-31`).format("YYYY-MM-DD"),
      };
    });
  };
  
export default function SalesOrder() {
  const [rows, setRows] = React.useState([])
  const [render, setRender] = React.useState(false)
  const [selectedRows, setSelectedRows] = React.useState([]);
  const [error, setError] = React.useState('')
  const { setOn, setSeverity, setMes } = React.useContext(SnackbarContext)
  const [downloading, setDownloading] = React.useState(false);
  const financialYears = getLastThreeFinancialYears();
  
  const [financialYear, setFinancialYear] = React.useState(financialYears[0]);
  
  const handleFinancialYearChange = (event) => {
    const selectedYear = financialYears.find((fy) => fy.year === event.target.value);
    setFinancialYear(selectedYear);
  };
  const handleDownloadZip = async () => {
    try {
      setDownloading(true);
      setSeverity('success')
      setMes('Downloading sales orders for selected agents. Please wait...')
      setOn(true)
      const response = await getBulkSalesOrder({
        agents_ids: selectedRows,
        from_date: financialYear.from_date,
        to_date: financialYear.to_date,
      });
      if (!response || !response.pdfBuffer) {
        console.error("No data received from API.");
        return;
      }
      if (response.pdfBuffer && response.pdfBuffer.data) {
        const byteArray = new Uint8Array(response.pdfBuffer.data);
        const blob = new Blob([byteArray], { type: "application/zip" });
        const url = URL.createObjectURL(blob);
  
        // Create a download link
        const a = document.createElement("a");
        a.href = url;
        a.download = "SalesOrders_Agents.zip"; // Set the filename
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        // Cleanup URL object
        URL.revokeObjectURL(url);
        setSeverity('success')
        setMes('Sales orders downloaded successfully.')
        setOn(true)
      } else {
        console.error("Invalid ZIP buffer data");
        setSeverity('error')
        setMes('Error downloading ZIP. Please try again.')
        setOn(true)
        return;
      }
    } catch (error) {
      console.error("Error downloading ZIP:", error);
        setSeverity('error')
        setMes('Failed to download ZIP. Please try again.')
        setOn(true)
    }
    finally {
      setDownloading(false);
    }
  };
  
  const handleDownloadSalesOrders = async() => {
    if (selectedRows.length === 0) {
      setSeverity('error')
      setMes('Please select at least one agent to download sales orders.')
      setOn(true)
      return
    }
    // Download sales orders for selected agents getBulkSalesOrder
    handleDownloadZip();
}
  
  const CustomToolbar = () => {
    return (
        <Grid container justifyContent="space-between" alignItems="center" sx={{ p: 0 }}>
        {/* Quick Filter on the Left */}
        <Grid item sx={{ flexGrow: 1, ml:2 }}>
          <GridToolbarQuickFilter />
        </Grid>
        
        {/* Dropdown & Button on the Right */}
        <Grid item>
          <Grid container alignItems="center" spacing={2}>
            {/* Financial Year Dropdown */}
            <Grid item>
                <FormControl size="small" sx={{ minWidth: 150 }}>
                    <InputLabel id="financial-year-label">Financial Year</InputLabel>
                    <Select
                    labelId="financial-year-label"
                    id="financial-year-select"
                    label="Financial Year"
                    value={financialYear.year || ""}
                    onChange={handleFinancialYearChange}
                    >
                    {financialYears.map((fy) => (
                        <MenuItem key={fy.year} value={fy.year}>
                        {fy.year}
                        </MenuItem>
                    ))}
                    </Select>
                </FormControl>
            </Grid>
      
            {/* Download Button */}
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                disabled={rows.length === 0 || downloading}
                onClick={handleDownloadSalesOrders}
                sx={{ px: 2, py: 1, m:1, mr:2 ,
                    backgroundColor: selectedRows.length === 0 ? "grey" : "primary",
                    "&:hover": {
                      backgroundColor: selectedRows.length === 0 ? "grey" : "primary",
                    },
                    "&.Mui-disabled": {
                      backgroundColor: "grey",
                      color: "white",
                    },
                }}
              >
                {downloading ? "Downloading..." : selectedRows.length === rows.length ? "Download All" : "Download"}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      
    );
  };

  async function getAgentListApi() {
    try {
      const res = await getSalesOrderAgentList(financialYear)
      if (res !== 204 && res !== 401) {
        setRows(res?.data)
      }
      else {
        setRows([])
      }
    } catch (error) {
        setError(error)
        setSeverity('error')
        setMes('Something went wrong. Please try again.')
        setOn(true)
        setRows([])
      console.log(error)
    }
  }

  React.useEffect(() => {
    getAgentListApi()
  }, [render, financialYear])
  
  const handleSelectAll = (event) => {
    if (event.target.checked) {
      setSelectedRows(rows.map((row) => row.customer_id)); // Select all rows
    } else {
      setSelectedRows([]); // Deselect all
    }
  };

  const handleRowSelection = (id) => (event) => {
    if (event.target.checked) {
      setSelectedRows((prev) => [...prev, id]);
    } else {
      setSelectedRows((prev) => prev.filter((rowId) => rowId !== id));
    }
  };
  const columns = [
    //checkbox selection
    {
        field: 'select',
        headerName: '',
        width: 60,
        sortable: false,
        renderHeader: () => (
          <Checkbox
            checked={selectedRows.length === rows.length && rows.length > 0}
            indeterminate={selectedRows.length > 0 && selectedRows.length < rows.length}
            onChange={handleSelectAll}
          />
        ),
        renderCell: (params) => (
          <Checkbox
            checked={selectedRows.includes(params.row.customer_id)}
            onChange={handleRowSelection(params.row.customer_id)}
          />
        ),
      },
    {
      field: 'sno',
      headerName: 'S.no',
      type: 'text',
      width: 60,
      disableColumnMenu: 'true',
    },
    { field: 'formatted_name', headerName: 'Name', flex: 0, minWidth: 200 },
    {
      field: 'contact_number',
      headerName: 'Contact',
      width: 140,
      disableColumnMenu: 'true',
    },
    { field: 'email_id', headerName: 'Email', flex: 1, minWidth: 130 },
  ].filter(Boolean)

  return (
    <Card sx={{ height: "100%" }} elevation={0}>
      <DashboardLayout 
        heading={"Sales Order Download"}
        pathName={"Sales Order Download"}
      >
  
        <div
          style={{
            height: 'calc(100vh - 9rem)',
            width: '100%',
            backgroundColor: 'white',
          }}
        >
          <StripedDataGrid
            getRowId={(row) => row.customer_id}
            rows={rows.map((item, index) => ({
              ...item,
              sno: index + 1,
              formatted_name: toCapitialize(item.agent_name),
            }))}
            columns={columns}
            initialState={{
              pagination: { paginationModel: { pageSize: 10, page: 0 } },
            }}
            pageSizeOptions={[10]}
            slots={{
              noRowsOverlay: CustomNoRowsOverlay,
              footer: CustomFooter,
              toolbar: CustomToolbar,
            }}
            sx={{ '--DataGrid-overlayHeight': '300px' }}
            rowSelection={false}
            getRowClassName={(params) =>
              params.row.is_active === 0
                ? 'deactivated'
                : params.indexRelativeToCurrentPage % 2 === 0
                ? 'even'
                : 'odd'
            }
            disableColumnFilter
            disableColumnSelector
            disableDensitySelector
            disableColumnMenu
            slotProps={{
              toolbar: {
                showQuickFilter: true,
                printOptions: { disableToolbarButton: true },
                csvOptions: { disableToolbarButton: false },
              },
            }}
          />
        </div>
      </DashboardLayout>
    </Card>
  );
  
}
