// import Link from 'next/link'
import * as React from 'react'
// import { Plane, Briefcase } from 'lucide-react';
import {
  Card,
  CardContent,
  CardActions,
  CardHeader,
  Typography,
  Grid,
  IconButton,
} from '@mui/material'
import { AirplanemodeActiveOutlined, CasesTwoTone } from '@mui/icons-material'
import Link from '@mui/material/Link'
import DashboardLayout from '../dashboard_layout/DashboardLayout'
import { alpha, styled } from '@mui/material/styles'
const ODD_OPACITY = 0.2
export default function Settingspage() {
  return (
    <DashboardLayout heading='Settings' pathName='Settings' button={false}>
      <div
        style={{
          padding: '24px',
          maxWidth: '1200px',
          margin: '0 auto',
          background: '#f6f9fc',
        }}
      >
        <Typography variant='h5' component='h1' gutterBottom></Typography>
        <Grid container spacing={4}>
          <Grid item xs={12} md={3}>
            <Link href='/emp-management' passHref>
              <Card
                className='plan'
                sx={{
                  '&:hover': {
                    boxShadow: 10,
                    backgroundColor: alpha('#FFFF00', ODD_OPACITY),
                    '@media (hover: none)': {
                      backgroundColor: 'transparent',
                    },
                  },
                  boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',

                  transition: 'box-shadow 0.3s',
                }}
              >
                <CardHeader
                  className='inner'
                  avatar={
                    <IconButton>
                      <AirplanemodeActiveOutlined className='h-6 w-6' />
                    </IconButton>
                  }
                  title={
                    <Typography variant='h6' sx={{ color: '#184E81' }}>
                      Manage Team
                    </Typography>
                  }
                  subheader='Manage and view all Employees'
                  subheaderTypographyProps={{ fontWeight: 500 }}
                />
                <CardContent>
                  <Typography sx={{ fontWeight: 500 }}>
                    Access detailed information on your team members
                  </Typography>
                </CardContent>
              </Card>
            </Link>
          </Grid>
          <Grid item xs={12} md={3}>
            <Link href='/add-designation' passHref>
              <Card
                className='plan'
                sx={{
                  '&:hover': {
                    boxShadow: 10,
                    backgroundColor: alpha('#FFFF00', ODD_OPACITY),
                    '@media (hover: none)': {
                      backgroundColor: 'transparent',
                    },
                  },
                  boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',

                  transition: 'box-shadow 0.3s',
                }}
              >
                <CardHeader
                  className='inner'
                  avatar={
                    <IconButton>
                      <CasesTwoTone className='h-6 w-6' />
                    </IconButton>
                  }
                  title={
                    <Typography variant='h6' sx={{ color: '#184E81' }}>
                      Manage Designation
                    </Typography>
                  }
                  subheader='Manage Designation of Employees'
                  subheaderTypographyProps={{ fontWeight: 500 }}
                />
                <CardContent>
                  <Typography sx={{ fontWeight: 500 }}>
                    Create new designation and manage employee designation
                  </Typography>
                </CardContent>
              </Card>
            </Link>
          </Grid>
          <Grid item xs={12} md={3}>
            <Link href='/SMTP-link' passHref>
              <Card
                className='plan'
                sx={{
                  '&:hover': {
                    boxShadow: 10,
                    backgroundColor: alpha('#FFFF00', ODD_OPACITY),
                    '@media (hover: none)': {
                      backgroundColor: 'transparent',
                    },
                  },
                  boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',

                  transition: 'box-shadow 0.3s',
                }}
              >
                <CardHeader
                  className='inner'
                  avatar={
                    <IconButton>
                      <CasesTwoTone className='h-6 w-6' />
                    </IconButton>
                  }
                  title={
                    <Typography variant='h6' sx={{ color: '#184E81' }}>
                      Manage SMTP Settings
                    </Typography>
                  }
                  subheader='Manage SMTP configurations'
                  subheaderTypographyProps={{ fontWeight: 500 }}
                />
                <CardContent>
                  <Typography sx={{ fontWeight: 500 }}>
                    Create, update and delete SMTP configurations
                  </Typography>
                </CardContent>
              </Card>
            </Link>
          </Grid>

          <Grid item xs={12} md={3}>
            <Link href='/Organization' passHref>
              <Card
                className='plan'
                sx={{
                  '&:hover': {
                    boxShadow: 10,
                    backgroundColor: alpha('#FFFF00', ODD_OPACITY),
                    '@media (hover: none)': {
                      backgroundColor: 'transparent',
                    },
                  },
                  boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',

                  transition: 'box-shadow 0.3s',
                }}
              >
                <CardHeader
                  className='inner'
                  avatar={
                    <IconButton>
                      <CasesTwoTone className='h-6 w-6' />
                    </IconButton>
                  }
                  title={
                    <Typography variant='h6' sx={{ color: '#184E81' }}>
                      Manage Organization
                    </Typography>
                  }
                  subheader='Manage Organization and view all organizations'
                  subheaderTypographyProps={{ fontWeight: 500 }}
                />
                <CardContent>
                  <Typography sx={{ fontWeight: 500 }}>
                    Control Create, update and delete organization 
                  </Typography>
                </CardContent>
              </Card>
            </Link>
          </Grid>
          <Grid item xs={12} md={3}>
            <Link href='/Emails-user' passHref>
              <Card
                className='plan'
                sx={{
                  '&:hover': {
                    boxShadow: 10,
                    backgroundColor: alpha('#FFFF00', ODD_OPACITY),
                    '@media (hover: none)': {
                      backgroundColor: 'transparent',
                    },
                  },
                  boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',

                  transition: 'box-shadow 0.3s',
                }}
              >
                <CardHeader
                  className='inner'
                  avatar={
                    <IconButton>
                      <CasesTwoTone className='h-6 w-6' />
                    </IconButton>
                  }
                  title={
                    <Typography variant='h6' sx={{ color: '#184E81' }}>
                      Manage Email Users
                    </Typography>
                  }
                  subheader='Manage Email Users and view all email users'
                  subheaderTypographyProps={{ fontWeight: 500 }}
                />
                <CardContent>
                  <Typography sx={{ fontWeight: 500 }}>
                    Control Create, update and delete email users also view ticket of users
                  </Typography>
                </CardContent>
              </Card>
            </Link>
          </Grid>
          <Grid item xs={12} md={3}>
            <Link href='/term-condition' passHref>
              <Card
                className='plan'
                sx={{
                  '&:hover': {
                    boxShadow: 10,
                    backgroundColor: alpha('#FFFF00', ODD_OPACITY),
                    '@media (hover: none)': {
                      backgroundColor: 'transparent',
                    },
                  },
                  boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',

                  transition: 'box-shadow 0.3s',
                }}
              >
                <CardHeader
                  className='inner'
                  avatar={
                    <IconButton>
                      <CasesTwoTone className='h-6 w-6' />
                    </IconButton>
                  }
                  title={
                    <Typography variant='h6' sx={{ color: '#184E81' }}>
                      Manage Terms and Conditions
                    </Typography>
                  }
                  subheader='Manage Email Users and view all email users'
                  subheaderTypographyProps={{ fontWeight: 500 }}
                />
                <CardContent>
                  <Typography sx={{ fontWeight: 500 }}>
                    Control Create, update and delete terms and conditions also view description
                  </Typography>
                </CardContent>
              </Card>
            </Link>
          </Grid>
          <Grid item xs={12} md={3}>
            <Link href='/Emails-temp' passHref>
              <Card
                className='plan'
                sx={{
                  '&:hover': {
                    boxShadow: 10,
                    backgroundColor: alpha('#FFFF00', ODD_OPACITY),
                    '@media (hover: none)': {
                      backgroundColor: 'transparent',
                    },
                  },
                  boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',

                  transition: 'box-shadow 0.3s',
                }}
              >
                <CardHeader
                  className='inner'
                  avatar={
                    <IconButton>
                      <CasesTwoTone className='h-6 w-6' />
                    </IconButton>
                  }
                  title={
                    <Typography variant='h6' sx={{ color: '#184E81' }}>
                      Manage Email Template
                    </Typography>
                  }
                  subheader='Manage Email and view all email templates'
                  subheaderTypographyProps={{ fontWeight: 500 }}
                />
                <CardContent>
                  <Typography sx={{ fontWeight: 500 }}>
                    Control Create and update email templates also view templates
                  </Typography>
                </CardContent>
              </Card>
            </Link>
          </Grid>
          <Grid item xs={12} md={3}>
            <Link href='/Scheduler' passHref>
              <Card
                className='plan'
                sx={{
                  '&:hover': {
                    boxShadow: 10,
                    backgroundColor: alpha('#FFFF00', ODD_OPACITY),
                    '@media (hover: none)': {
                      backgroundColor: 'transparent',
                    },
                  },
                  boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',

                  transition: 'box-shadow 0.3s',
                }}
              >
                <CardHeader
                  className='inner'
                  avatar={
                    <IconButton>
                      <CasesTwoTone className='h-6 w-6' />
                    </IconButton>
                  }
                  title={
                    <Typography variant='h6' sx={{ color: '#184E81' }}>
                      Manage Scheduler Events
                    </Typography>
                  }
                  subheader='Manage Events and view all events'
                  subheaderTypographyProps={{ fontWeight: 500 }}
                />
                <CardContent>
                  <Typography sx={{ fontWeight: 500 }}>
                    Control Create, update and delete events also view events
                  </Typography>
                </CardContent>
              </Card>
            </Link>
          </Grid> 
        </Grid>
      </div>
    </DashboardLayout>
  )
}
