import React from "react";
import {
  ScheduleComponent,
  Day,
  Week,
  WorkWeek,
  Month,
  Agenda,
  Inject,
} from "@syncfusion/ej2-react-schedule";

// Default event settings example
const defaultEventSettings = {
  dataSource: [
    {
      Id: 1,
      Subject: "Team Meeting",
      StartTime: new Date(2025, 0, 28, 10, 0),
      EndTime: new Date(2025, 0, 28, 12, 0),
    },
    {
      Id: 2,
      Subject: "Project Discussion",
      StartTime: new Date(2025, 0, 29, 9, 0),
      EndTime: new Date(2025, 0, 29, 11, 0),
    },
  ],
};

export const Scheduler = ({
  height = "650px",
  selectedDate = new Date(),
  eventSettings = defaultEventSettings,
  views = ["Day", "Week", "WorkWeek", "Month", "Agenda"],
  timeScale = { interval: 60, slotCount: 2 },
  group = null,
}) => {
  return (
    <ScheduleComponent
      height={height}
      selectedDate={selectedDate}
      eventSettings={eventSettings}
      views={views}
      timeScale={timeScale}
      group={group}
    >
      <Inject services={[Day, Week, WorkWeek, Month, Agenda]} />
    </ScheduleComponent>
  );
};