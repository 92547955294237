import { Grid } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import SegmentIcon from '@mui/icons-material/Segment'
import {
  AirplaneTicket,
  Checklist,
  Close,
  CurrencyRupee,
  Done,
  Sell,
  Storefront,
} from '@mui/icons-material'
import { BarChart } from '@mui/x-charts/BarChart'
import { LineChart } from '@mui/x-charts'
import DataCard from '../../../components/dashboard_layout/dash_component/DataCard'
import { getDashboardApi } from '../../../services/admin.configService'
import { useEffect, useState } from 'react'
import { months } from '../../../utils/constant'
import Chart from 'react-apexcharts'
import RevenueChart from './RevenueChart'
import OverDueWidget from './payment_due/OverDueWidget'
import PaymentDueWidget from './payment_due/PaymentDueWidget'

const iconsColor = {
  Enquiries: {
    color: '#6366f1',
    icon: Sell,
  },
  Quoted: {
    color: '#5193b4',
    icon: Checklist,
  },
  Accepted: {
    color: '#ffa500',
    icon: Done,
  },
  'Rejected/Cancel': {
    color: '#f04438',
    icon: Close,
  },
  Bookings: {
    color: '#10b981',
    icon: AirplaneTicket,
  },
}

const Statics = () => {
  const theme = useTheme()
  const orangeDark = theme.palette.secondary[800]
  const [cardData, setCardData] = useState([])
  const [graphData, setGraphData] = useState([])
  const [d2c_graphData, setD2c_graphData] = useState([])
  // console.log('card data ', graphData)

  const chartSetting = {
    yAxis: [
      {
        label: 'No. of quotes',
      },
    ],

    height: 400,
  }

  async function dash() {
    try {
      const res = await getDashboardApi()
      if (res !== 204 && res !== 401) {
        setCardData(res.data.card_data)
        setGraphData(res.data.graph_data)
        setD2c_graphData(res.data.b2c_graph_data)
      }
    } catch (error) {
      console.log('error ', error.res)
    }
  }

  const options = {
    chart: {
      toolbar: {
        show: false,
      },
    },
    stroke: {
      curve: 'smooth',
    },
    xaxis: {
      categories: graphData.map((item) => months[item.month - 1]), // x-axis categories
    },
    series: [
      {
        name: 'Revenue',
        data: graphData.map((item) => Number(item.revenue)),
      },
    ],
    fill: {
      type: 'gradient',
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 0.9,
        stops: [0, 90.1],
      },
    },
    colors: ['#10b981'], // Base color for the area fill
    legend: {
      show: true, // Enable the legend
      position: 'top', // Options: 'top', 'bottom', 'left', 'right'
    },
  }

  useEffect(() => {
    dash()
  }, [])

  return (
    <>
      <Grid
        p={2}
        container
        spacing={1}
        bgcolor={'aliceblue'}
        style={{ minHeight: 'calc(100vh - 4rem)' }}
        alignContent={'flex-start'}
      >
        <Grid item sm={12}>
          <Grid container spacing={1}>
            {cardData.map((item) => {
              return (
                <Grid item md={4} xs={12} sm={6} lg={3} xl={2.4}>
                  <DataCard
                    value={item.today}
                    icon={iconsColor[item.title]?.icon}
                    heading={item.title}
                    this_week={item.this_week}
                    this_month={item.this_month}
                    this_year={item.this_year}
                    color={iconsColor[item.title]?.color}
                  />
                </Grid>
              )
            })}
          </Grid>
        </Grid>
        {/* <Grid item md={8}>
          <Chart
            options={options}
            series={options.series}
            type="area"
            width="100%"
            height={400}
          />
        </Grid> */}
        <Grid item xs={6} md={6} marginTop={2}>
          <PaymentDueWidget />
        </Grid>
        <Grid item xs={6} md={6} marginTop={2}>
          <OverDueWidget />
        </Grid>

        <Grid item xs={12} marginTop={2}>
          {<RevenueChart graphData={graphData} data_d2c={d2c_graphData} />}
        </Grid>
      </Grid>
    </>
  )
}

export default Statics
