import {
  Paper,
  Typography,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Chip,
  Divider,
} from '@mui/material'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import PersonIcon from '@mui/icons-material/Person'
import {
  AirlineSeatReclineExtraTwoTone,
  CurrencyRupeeTwoTone,
} from '@mui/icons-material'
import ImapLisiting from '../admin/Imap/ImapLisiting'
import PrePassengerList from '../common/customer_flightbooking/PrePassengerList'
import OverDueListing from '../admin/dashboard/payment_due/OverDueListing'
import PrePassengerlisting from './PrePassengerlisting'
import dayjs from 'dayjs'

export default function FlightDetails({ rowData }) {
  // console.log('rowData', rowData)

  return (
    <Paper sx={{ p: 3, maxHeight: 500, mx: 'auto', my: 1 }}>
      {/* <Typography variant='h6' gutterBottom>
        Delhi – Kolkata
      </Typography> */}

      <Grid
        container
        spacing={2}
        sx={{ mb: 1, borderBottom: '1px solid #e5e5e5' }}
      >
        <Grid item xs={12} md={4}>
          <Box alignItems='center' gap={1}>
            {/* <img
              src='https://hebbkx1anhila5yf.public.blob.vercel-storage.com/image-2yFeICGlE8tdHnbgYwWqQB1AJLsfG8.png'
              alt='Spice Jet'
              style={{ width: 40, height: 40 }}
            /> */}
            <img
              src='../images/Azerbaijan_Airlines.png'
              alt='logo'
              style={{ width: '80%' }}
            />
            <Box>
              <Typography variant='body2'>
                ✈️ Flight Code: <strong> {rowData?.flight_code}</strong>
              </Typography>
              <Box display='flex' gap={1} mb={3} mt={1}>
                <Chip
                  label='Regular Fare'
                  variant='filled'
                  size='small'
                  color='info'
                  icon={<CurrencyRupeeTwoTone />}
                />
                {/* <Chip label='SPICESAVER' variant='outlined' size='small' /> */}
                <Chip
                  label='Economy'
                  variant='filled'
                  size='small'
                  icon={<AirlineSeatReclineExtraTwoTone />}
                />
              </Box>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12} md={8}>
          <Box display='flex' alignItems='center' gap={4}>
            <Box>
              <Typography
                variant='subtitle2'
                sx={{ fontWeight: '600', color: '#1e497e' }}
              >
                {rowData?.origin_code == 'DEL'
                  ? 'DEL - New Delhi'
                  : rowData.origin_code == 'GYD'
                  ? 'GYD - Baku'
                  : 'BOM - Mumbai'}
              </Typography>
              <Typography variant='subtitle2' sx={{ fontWeight: '600' }}>
                {dayjs(rowData.departure_date).format("DD MMM'YY ")}{' '}
                {dayjs(rowData.departure_time, 'hh:mm:ss').format('hh:mm A')}
              </Typography>
              <Divider sx={{ mt: 1, mb: '2px', color: 'darkgrey' }} />
              <Typography variant='subtitle2' color='text.secondary'>
                <LocationOnIcon sx={{ fontSize: 13, mr: 0.5 }} />
                Indira Gandhi International Airport
              </Typography>
            </Box>

            <Box textAlign='center' sx={{  }}>
              ✈️
              {/* <Box
                sx={{ width: 100, height: 2, bgcolor: 'divider', my: 0.5 }}
              /> */}
              {/* <Typography variant='subtitle2' display='block'>
                2h 25m
              </Typography> */}
            </Box>

            <Box>
              <Typography
                variant='subtitle2'
                sx={{ fontWeight: '600', color: '#1e497e' }}
              >
                {rowData?.destination_code == 'DEL'
                  ? 'DEL - New Delhi'
                  : rowData.destination_code == 'GYD'
                  ? 'GYD - Baku'
                  : 'BOM - Mumbai'}
              </Typography>
              <Typography variant='subtitle2' sx={{ fontWeight: '600' }}>
                {dayjs(rowData.departure_date).format("DD MMM'YY ")}{' '}
                {dayjs(rowData.arrival_time, 'hh:mm:ss').format('hh:mm A')}
              </Typography>
              <Divider sx={{ mt: 1, mb: '2px', color: 'darkgrey' }} />
              <Typography variant='subtitle2' color='text.secondary'>
                <LocationOnIcon sx={{ fontSize: 14, mr: 0.5 }} />
                Heydar Aliyev International Airport
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>

      <Box display='flex' gap={2} mb={1}>
        <Typography
          variant='subtitle2'
          // color='text.secondary'
          style={{ textDecoration: 'underline' }}
        >
          Passengers Details
        </Typography>
      </Box>

      {/* <TableContainer>
        <Table>
          <TableHead>
            <TableRow sx={{ bgcolor: 'grey.100' }}>
              <TableCell>TRAVELLER</TableCell>
              <TableCell>PNR/E-TICKET NUMBER</TableCell>
              <TableCell>SEAT</TableCell>
              <TableCell>MEAL</TableCell>
              <TableCell>EXCESS BAGGAGE</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>
                <Box display='flex' alignItems='center' gap={1}>
                  <PersonIcon color='action' />
                  <Box>
                    <Typography>Hirendra Das</Typography>
                    <Typography variant='caption' color='text.secondary'>
                      Adult, Male
                    </Typography>
                  </Box>
                </Box>
              </TableCell>
              <TableCell>ACQPQJ</TableCell>
              <TableCell>-</TableCell>
              <TableCell>-</TableCell>
              <TableCell>-</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Box display='flex' alignItems='center' gap={1}>
                  <PersonIcon color='action' />
                  <Box>
                    <Typography>Aparna Das</Typography>
                    <Typography variant='caption' color='text.secondary'>
                      Adult, Female
                    </Typography>
                  </Box>
                </Box>
              </TableCell>
              <TableCell>ACQPQJ</TableCell>
              <TableCell>-</TableCell>
              <TableCell>-</TableCell>
              <TableCell>-</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer> */}
      <PrePassengerlisting passengers={rowData?.passengers} />
    </Paper>
  )
}
